export default '''
	type Query {
		accounts: [Account!]
		document(id: ID!): Document
		documents(filters: [DocumentsFilters!], pagination: PaginationInput): [Document]
		documentsTopic(id: ID!): DocumentTopic
		documentsTopics: [DocumentTopic!]
		# Statistics about available documents (global)
		documentsStats: DocumentsStats!
		offer(id: ID!): LeasingOffer
		opportunities: [Opportunity!]
		opportunitiesStates: [OpportunityStage!]
		opportunity(id: ID!): Opportunity
		project(slug: String!): Project
		projects(skip: Int = 0, limit: Int = 10): [Project!]
		users: [Person!]
	}
'''
