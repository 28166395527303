import _ from 'lodash'
import { resolveMetaInfo } from '../../helpers'

export default (collections) ->
	{
		scenarios
		projects
		users
		contexts
	} = collections

	{
		...resolveMetaInfo collections
		isBaseline: (obj, args, context, info) ->
			obj._isBaseline is true
		closingDate: (obj) ->
			if obj._closingDate?
				obj._closingDate
			else
				project = projects.findOne id: obj.projectID
				{timeline} = contexts.findOne id: project.contextID
				timeline.closing
	}
