"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

/*
 Mapping between legal document access levels and scopes
 Serves as single point of truth for scopes attached to levels
*/
var _default = {
  Read: ['LegalDocumentRead'],
  Write: ['LegalDocumentRead', 'LegalDocumentUpdateScan', 'LegalDocumentUpdateDetails']
};
exports.default = _default;