export Observation = '''
	id
	state
	type
	code
	status
	classification
	description
	isSynced
	dueDate
	createdAt
	createdBy {
		...on Person {
			id
			name
		}
	}
	modifiedAt
	modifiedBy {
		...on Person {
			id
			name
			picture {
				mini

			}
		}
	}
	comments {
		id
	}
	photos {
		original
	}
	project {
		id
		name
	}
	categories {
		name
		type
	}
	bimIssue {
		id
		status
	}
	location {
			description
			level {
				id
				path
				level
				description
			}
		}
'''
