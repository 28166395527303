export default '''

	# InfoReceived - The carrier received a request from the shipper and wants to start shipping.
	# Pending - New pending shipment to track or a new shipment without tracking information added.
	# InTransit - The carrier has received or received the carrier. Shipment is in progress.
	# Delivered - The shipment was successfully delivered.
	# Exception - Custom hold, undeliverable, shipper has shipped or shipped an exception.
	# FailAttempt - The courier tried to send but failed, but usually reminds and tries again.
	enum DeliveryStatus {
		InfoReceived
		Pending
		InTransit
		Delivered
		Exception
		FailAttempt
	}

	type CourierService {
		# Unique code of courier service
		code: String!
		# Courier service name
		name: String!
		# Link to courier website
		website: String
	}

	type CourierDelivery {
		# Courier information
		courier: CourierService!
		# Tracking number
		number: String!
		# Delivery status
		status: DeliveryStatus!
		# Checkpoints information
		checkpoints: [CourierDeliveryCheckpoint!]
	}

	type CourierDeliveryCheckpoint {
		# Courier Object	courier information
		courier: CourierService!
		# Location info of the checkpoint provided by the courier
		location: String!
		# Checkpoint message
		message: String!
		# The date and time of the checkpoint provided by the courier
		time: Int
	}

'''
