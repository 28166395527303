import _ from 'lodash'

import { calculateSpaceResult } from 'data/helpers'

export default (collections) ->
	{
		projects
		tenantMixes
		scenarios
		divestments
		contexts
	} = collections

	result: (obj, args, context, info) ->
		tenantMix = null
		divestmentData = null
		if args?.tenantMixID?
			tenantMix = tenantMixes.findOne id: args.tenantMixID
		if args?.scenarioID?
			scenario = scenarios.find id: args.scenarioID
			if scenario?
				tenantMix = tenantMixes.findOne id: scenario.tenantMixID
				divestmentData = divestments.findOne id: scenario.divestmentID

		if tenantMix? and !divestmentData?
			divestmentData = _.first(
				collections.divestments.chain().
				find({projectID: tenantMix.projectID, _isBaseline: true})
				.simplesort('metaInfo.modifiedAt', true)
				.data()
			)



		if !tenantMix?
			throw new Error 'Tenant Mix or Scenario must be supplied to calculate proper space result'
		if !divestmentData?
			throw new Error 'Divestment option not found'

		project = projects.findOne id: tenantMix.projectID
		{building, timeline, finance} = contexts.findOne id: project.contextID


		timeline = {
			...timeline
		}
		if divestmentData._closingDate?
			timeline = {
				...timeline
				closing: divestmentData._closingDate
			}

		result = calculateSpaceResult obj, building, {
			...finance
			closingDate: timeline.closing
			yield: divestmentData.yield
		}
		# console.log result
		{
			...result
			__typename: 'LeasingOfferSpaceResult'
			grossValue: {
				...result.grossValue
				__typename: 'CommercialSpaceGrossValue'
			}
			shortfall: {
				...result.shortfall
				__typename: 'CommercialSpaceShortfall'
			}
		}
		# console.log obj, args
		# throw new Error 'Not implemented'
