###
Toolbox with all utilities neccessary for business logic
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import DataAttribute from '@bevy/data-attribute'
# Renderable
import { div, img, p } from 'react-dom-factories'

import _Button from '@bevy/button'
Button = React.createFactory _Button

import { ButtonGroup as _ButtonGroup } from '@atlaskit/button'
ButtonGroup = React.createFactory _ButtonGroup
# Styles
import styles from './index.styl'



export default class Toolbox extends React.Component
	@propTypes:
		actions: PropTypes.arrayOf PropTypes.node
		custom: PropTypes.arrayOf PropTypes.node
		external: PropTypes.arrayOf PropTypes.node
		borderless: PropTypes.bool
	@defaultProps:
		actions: []
		custom: []
		external: []
		borderless: false
	constructor: (props) ->
		super props
	render: ->
		div {
			className: cnames [
				styles.actionsButtons
				@props.className
				if @props.borderless then styles.borderless
			]
		},
			do =>
				_.map @props.actions, (group, index) =>
					isLast = index is _.size(@props.actions) - 1 and _.isEmpty @props.custom
					div {
						className: cnames [
							styles.actionButton
							styles['actions-buttons']
							if isLast then styles.isLast
						]
						key: index
					},
						group
			do =>
				_.map @props.custom, (group, index) =>
					isLast = index is _.size(@props.custom) - 1
					div {
						className: cnames [
							styles.actionButton
							styles['custom-buttons']
							'bevy-toolbox-custom-buttons'
							if isLast then styles.isLast
						]
						key: index
					},
						group
			do =>
				_.map @props.external, (group, index) ->
					div {
						className: cnames [
							styles.actionButton
							styles['external-buttons']
						]
						key: index
					},
						group
