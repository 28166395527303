exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._21eUxeUVCCtfkaJYm2SaxZ {\n  color: inherit;\n  text-decoration: inherit;\n}\n._21eUxeUVCCtfkaJYm2SaxZ:hover {\n  color: inherit;\n  text-decoration: inherit;\n}\n._3nEKK1WeXqyxVpO-n-zHH8 {\n  pointer-events: none;\n}\n.CDBq1Bbhsvb4K0hL4wKNw.CDBq1Bbhsvb4K0hL4wKNw.CDBq1Bbhsvb4K0hL4wKNw[type=\"button\"] {\n  color: #f00 !important;\n}\n.CDBq1Bbhsvb4K0hL4wKNw.CDBq1Bbhsvb4K0hL4wKNw.CDBq1Bbhsvb4K0hL4wKNw[type=\"button\"]:hover {\n  color: #f00 !important;\n}\n.CDBq1Bbhsvb4K0hL4wKNw.CDBq1Bbhsvb4K0hL4wKNw.CDBq1Bbhsvb4K0hL4wKNw svg {\n  color: #f00 !important;\n}\n._2y4RfjpefcadXNiThT-Yoz a {\n  text-decoration: inherit;\n  color: inherit;\n}\n._2y4RfjpefcadXNiThT-Yoz[type=\"button\"] {\n  color: #5e6c84 !important;\n  text-decoration: none;\n  padding: 0 4px;\n  height: 2.28rem;\n}\n._2y4RfjpefcadXNiThT-Yoz[type=\"button\"]:hover {\n  color: #091e42 !important;\n  background: #ebecf0;\n}\n._2y4RfjpefcadXNiThT-Yoz[disabled] {\n  color: #8993a4 !important;\n}\n._2y4RfjpefcadXNiThT-Yoz[disabled]:hover {\n  color: #8993a4 !important;\n  background: transparent;\n}\n._2y4RfjpefcadXNiThT-Yoz svg {\n  color: #0052cc !important;\n}\n.za7_eXbwW0zUW3SkbvUHa {\n  display: flex;\n  transition: background 0.2s;\n  border-radius: 5px;\n}\n.za7_eXbwW0zUW3SkbvUHa:hover {\n  background: #ebecf0;\n}\n.za7_eXbwW0zUW3SkbvUHa ._1wHOc_rquDv3LGqXgtEyXZ {\n  border-top-right-radius: 0;\n  border-bottom-right-radius: 0;\n}\n.za7_eXbwW0zUW3SkbvUHa ._2IxGVr6POEaYFeWDKD1dWx {\n  border-top-left-radius: 0;\n  border-bottom-left-radius: 0;\n}\n._2VGYxBkCWnqcBQYvnCj_x4._2VGYxBkCWnqcBQYvnCj_x4 {\n  color: #0052cc !important;\n}\n._2VGYxBkCWnqcBQYvnCj_x4._2VGYxBkCWnqcBQYvnCj_x4 > span > span > i {\n  color: #0052cc !important;\n}\n._2VGYxBkCWnqcBQYvnCj_x4._2VGYxBkCWnqcBQYvnCj_x4:hover {\n  color: #0747a6 !important;\n}\n._2VGYxBkCWnqcBQYvnCj_x4._2VGYxBkCWnqcBQYvnCj_x4:hover > span > span > i {\n  color: #0747a6 !important;\n}\n", ""]);

// Exports
exports.locals = {
	"internalLink": "_21eUxeUVCCtfkaJYm2SaxZ",
	"disabled": "_3nEKK1WeXqyxVpO-n-zHH8",
	"destructive": "CDBq1Bbhsvb4K0hL4wKNw",
	"toolbarAction": "_2y4RfjpefcadXNiThT-Yoz",
	"ButtonSplit": "za7_eXbwW0zUW3SkbvUHa",
	"main": "_1wHOc_rquDv3LGqXgtEyXZ",
	"addon": "_2IxGVr6POEaYFeWDKD1dWx",
	"primaryAction": "_2VGYxBkCWnqcBQYvnCj_x4"
};