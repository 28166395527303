export default '''

type Organization {
	id: ID!
	name: String!
	slug: String!
	hedquarters: Address!
	partnership: OrganizationPartnership
	inLiquidation: Boolean!
	identifiers: [OrganizationIdentifier!]
	abbreviation: String
	departments: [Department!]
	website: String
	domain: String
	logo: Image
}

type OrganizationIdentifier {
	number: String!
	type: OrganizationIdentifierEnum
}

enum OrganizationPartnership {
	LimitedLiability
	LimitedPartnership
	LimitedJointStockPartnership
	JointStockPartnership
	InvestmentFund
	InLiquidation
}

# KRS = PolishNationalCourtRegister ?= DutchChamberOfCommerce ?= InvestmentFundsRegister
# EnterpriseNumber ?= REGON
# TaxpayerIdentificationNumber = NIP = RSIN
enum OrganizationIdentifierEnum {
	InvestmentFundsRegister
	PolishNationalCourtRegister
	DutchChamberOfCommerce
	REGON
	TaxpayerIdentificationNumber
	RSIN
	EnterpriseNumber
}

type Address {
	street: String
	buildingNumber: String
	floorNumber: Int
	flatNumber: Int
	district: String
	postalCode: String
	city: String
}

type Department {
	id: ID!
	name: String!
	members: [Actor!]!
}

type Account implements Establishment {
	id: ID!
	name: String!
	slug: String!
	website: String
	domain: String
	logo: Image
	numberOfOpportunities: Int!
}


union Company = Contractor | Account
'''
