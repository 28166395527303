import _ from 'lodash'

export default (collections) ->
	{
		users
		companies
	} = collections

	entity: (obj, args, context, info) ->
		companies.findOne id: obj.entityID
