exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IUsZZxeDJkUDpLl6eQFlV {\n  display: flex;\n  overflow: hidden;\n  max-width: 100%;\n  position: relative;\n  background-color: #f4f5f7;\n  padding: 0 48px;\n}\n._1m7iKDBrZneYNvD_zFbRau {\n  background-color: #6554c0;\n}\n._2uKHYT2yXivpWW4qB5-PW5 {\n  color: #6554c0;\n}\n._1baQfx78Xs15_AfDbcVTUy {\n  text-decoration: none;\n  color: #091e42;\n  display: block;\n  padding: 8px 16px;\n  background-color: #c1c7d0;\n}\n", ""]);

// Exports
exports.locals = {
	"page": "IUsZZxeDJkUDpLl6eQFlV",
	"color": "_1m7iKDBrZneYNvD_zFbRau",
	"textColor": "_2uKHYT2yXivpWW4qB5-PW5",
	"sidebarFooter": "_1baQfx78Xs15_AfDbcVTUy"
};