export ProjectsList = '''
	name
	id
	slug
	city
	country
	photo {
		original
	}
'''
