import _ from 'lodash'
import objectid from 'bson-objectid'
import moment from 'moment-mini'
import semver from 'semver'

import {
	addTypenamesToSpace
	getSignedSpaces
	listAllMissingVacantSpaces
	metaInfoMutation
	createDocumentMetaInfo
	createEmptyContext
} from 'data/helpers'

export default (collections) ->
	{
		projects
		offers
		opportunities
		tenantMixes
		opportunitiesStages
		costsVariants
		costsLines
		costsItems
		users
		scenarios
		divestments
		documents
		contexts
	} = collections


	updateOpportunity: (obj, args, context, info) ->
		opportunity = opportunities.findOne id: args.id
		opportunity = {
			...opportunity
			...args.input
		}
		opportunities.update opportunity
		opportunity
	createOpportunity: (obj, args, context, info) ->
		valeues = _.defaultsDeep args,
			description: ''
			name: ''
		data = {
			...args
			id: objectid().toHexString()
			offers: []
			__typename: 'Opportunity'
			metaInfo:
				__typename: null
				createdBy:
					__typename: null
					collection: 'users'
					type: 'Person'
					id: 'random_guy'
				createdAt: moment().unix()
				modifiedBy:
					__typename: null
					collection: 'users'
					type: 'person'
					id: 'random_guy'
				modifiedAt: moment().unix()
		}
		opportunities.insert data
		data
	createTenantMix: (obj, args, context, info) ->
		project = projects.findOne id: args.projectID
		{allWonSpaces, allWonLeasingOffers, allWonOpportunities} = getSignedSpaces args.projectID, collections
		data = {
			__typename: 'TenantMix'
			...createDocumentMetaInfo users.findOne(name: 'Grzegorz Bliżycki').id, true
			state: 'Draft'
			id: objectid().toHexString()
			name: if args.input.name? then args.input.name else "Unnamed tenant mix #{moment().format 'HH:mm DD/MM/YYYY'}"
			projectID: args.projectID
			offersIDS: _.concat args.input.offers, _.map allWonLeasingOffers, 'id'
			attachedOffersIDS: []
			baseline: null
			# If stacking plan contains vacants it is classified as rent roll
			definedVacants: []
			overrides: []
		}
		baseContext = collections.contexts.findOne id: project.contextID
		# TODO!: Do not clone all propeties from base context!
		context = {
			...createEmptyContext project.id
			..._.cloneDeep _.pick baseContext, [
				'tenantMixID'
				'costsVariantID'
				'divestmentID'
				'timeline'
				'finance'
				'freeSpaceDefinitions'
				'building'
			]
		}
		context.tenantMixID = data.id
		data.contextID = context.id

		tenantMixes.insert data
		contexts.insert context
		data
	createTenantMixVariant: (obj, args, context, info) ->
		tenantMix = tenantMixes.findOne id: args.tenantMixID
		if !tenantMix?
			throw new Error 'Cannot create variant of undefined TenantMix'
		else if tenantMix.state isnt 'Commited'
			throw new Error 'Variants of uncommited tenant mixes are forbidden'
		else
			# TODO: Find all variants of this document and set minor version according to last created variant
			project = projects.findOne id: tenantMix.projectID
			tenantMix = {
				..._.cloneDeep _.omit tenantMix, 'meta', '$loki'
				...createDocumentMetaInfo users.findOne(name: 'Grzegorz Bliżycki').id, true
				id: objectid().toHexString()
				_isBaseline: false
				state: 'Draft'
				name: "Unnamed tenant mix - #{moment().format 'HH:mm DD/MM/YYYY'}"
			}

			baseContext = collections.contexts.findOne id: project.contextID
			context = {
				...createEmptyContext project.id
				..._.cloneDeep _.pick baseContext, [
					'tenantMixID'
					'costsVariantID'
					'divestmentID'
					'timeline'
					'finance'
					'freeSpaceDefinitions'
					'building'
				]
			}
			context.tenantMixID = tenantMix.id
			tenantMix.contextID = context.id

			tenantMixes.insert tenantMix
			contexts.insert context
			tenantMix
	createTenantMixSnapshot: (obj, args, context, info) ->

		# Make a copy of tenant mix
		originalTenantMix = tenantMixes.findOne id: args.tenantMixID
		if !originalTenantMix?
			throw new Error 'Cannot create variant of undefined TenantMix'
		else if originalTenantMix.state isnt 'Draft'
			throw new Error 'Variants of commited tenant mixes are forbidden'
		else
			tenantMix = {
				..._.cloneDeep _.omit originalTenantMix, 'meta', '$loki'
				...createDocumentMetaInfo users.findOne(name: 'Grzegorz Bliżycki').id, true
				id: objectid().toHexString()
				state: 'Draft'
				_isBaseline: false
				name: if !_.isEmpty(args.name) then args.name else "Snapshot - #{moment().format 'HH:mm DD/MM/YYYY'}"
				description:
					if !_.isEmpty(args.description)
						args.description
					else
						"Snapshot of #{originalTenantMix.name} – Snapshot - #{moment().format 'HH:mm DD/MM/YYYY'}"
				root: originalTenantMix.id
			}
		# Find all local leasing offers drafts and make a copy
		for type in ['offersIDS', 'attachedOffersIDS']
			offersMapping = _.map offers.find(id: {$in: originalTenantMix[type]}, state: 'Draft'), (originalOfferDraft) ->
				original: originalOfferDraft
				clone: {
					..._.omit originalOfferDraft, 'meta', '$loki'
					...createDocumentMetaInfo users.findOne(name: 'Grzegorz Bliżycki').id, true
					id: objectid().toHexString()
					state: 'Draft'
					name: originalOfferDraft.name
					description: originalOfferDraft.description
					tenantMixID: tenantMix.id
					spaces: _.map originalOfferDraft.spaces, (space) ->
						{
							...space
							id: objectid().toHexString()
						}
				}
			# Replace previous local drafts ids for new ones
			tenantMix[type] = _.map tenantMix[type], (offerID) ->
				offerMapping = _.find offersMapping, (mapping) -> mapping.original.id is offerID
				if !originalOffer?
					offerID
				else
					console.log 'We found a bugger'
					offerMapping.clone.id

			# Save leasing offers
			offers.insert _.map offersMapping, 'clone'


		baseContext = collections.contexts.findOne id: originalTenantMix.contextID
		# TODO!: Do not clone all propeties from base context!
		context = {
			...createEmptyContext originalTenantMix.projectID
			..._.cloneDeep _.pick baseContext, [
				'tenantMixID'
				'costsVariantID'
				'divestmentID'
				'timeline'
				'finance'
				'freeSpaceDefinitions'
				'building'
			]
		}
		context.tenantMixID = tenantMix.id
		tenantMix.contextID = context.id

		# Save tenant mix
		tenantMixes.insert tenantMix
		contexts.insert context
		tenantMix
	restoreSnapshot: (obj, args, context, info) ->
		# Replace current state with snapshot state
		tenantMix = tenantMixes.findOne id: args.tenantMixID
		snapshot = tenantMixes.findOne id: args.snapshotID
		# Restore context info to snapshot state
		currentContext = contexts.findOne id: tenantMix.contextID
		snapshotContext = contexts.findOne id: snapshot.contextID

		currentContextModified = {
			..._.cloneDeep currentContext
			..._.cloneDeep _.omit snapshotContext, ['meta', '$loki', 'id', 'tenantMixID']
		}

		contexts.update currentContextModified


		# TODO!: Remove all linked offers to previous state of tenant mix before killing this one
		tenantMix = {
			..._.cloneDeep _.omit snapshot, 'meta', '$loki', 'contextID'
			...createDocumentMetaInfo users.findOne(name: 'Grzegorz Bliżycki').id, true
			..._.pick tenantMix, ['id', 'meta', '$loki', 'name', 'contextID']
			root: null
		}

		tenantMixes.update tenantMix

		tenantMix

	deleteSnapshot: (obj, args, context, info) ->
		# Clean up all offers linked to this snapshot
		offers.findAndRemove tenantMixID: args.snapshotID
		tenantMixes.findAndRemove id: args.snapshotID

	promoteSnapshot: (obj, args, context, info) ->
		snapshot = tenantMixes.findOne id: args.snapshotID
		tenantMix = {
			..._.cloneDeep _.omit snapshot, 'meta', '$loki'
			...createDocumentMetaInfo users.findOne(name: 'Grzegorz Bliżycki').id, true
			_isBaseline: false
			root: null
			id: objectid().toHexString()
			state: 'Draft'
			name: args.name
			description: args.description || null
		}
		tenantMixes.insert tenantMix

		tenantMix

	createLeasingOfferVariant: (obj, args, context, info) ->
		tenantMixID = null
		tenantMix = null
		if args.tenantMixID?
			tenantMix = tenantMixes.findOne id: args.tenantMixID
			if tenantMix?
				tenantMixID = tenantMix.id
			else
				throw new Error "Cannot create leasing offer variant attached to undefined TenantMix #{args.tenantMixID}"

		offer = offers.findOne id: args.offerID
		opportunity = if offer? then opportunities.findOne(id: offer.opportunityID) else null
		if !offer?
			throw new Error 'Cannot create variant of undefined LeasingOffer'
		else if offer.state isnt 'Commited'
			throw new Error 'Variants of uncommited leasing offer are forbidden'
		else if !opportunity?
			throw new Error 'Cannot create variant of undefined Opportunity'
		else if opportunity.stageID in ['ClosedWon', 'ClosedLost', 'OnHold']
			throw new Error "Cannot create variant of closed Opportunity #{opportunity.stageID}"
		else
			originalOfferID = offer.id
			offer = {
				..._.omit offer, 'meta', '$loki'
				...createDocumentMetaInfo users.findOne(name: 'Grzegorz Bliżycki').id, true
				id: objectid().toHexString()
				state: 'Draft'
				name: "Unnamed leasing offer - #{moment().format 'HH:mm DD/MM/YYYY'}"
				tenantMixID: tenantMixID
				spaces: _.map offer.spaces, (space) ->
					{
						...space
						id: objectid().toHexString()
					}
			}
			offers.insert offer
			# TODO!: If this offer belongs to tenant mix we should add it there
			if tenantMix?
				tenantMix.offersIDS = _.reject tenantMix.offersIDS, (id) -> id is originalOfferID
				tenantMix.offersIDS.push offer.id
				tenantMix.attachedOffersIDS.push originalOfferID
				tenantMixes.update tenantMix


			offer
	createNewLeasingOffer: (obj, args, context, info) ->

		opportunity = opportunities.findOne id: args.opportunityID
		if !opportunity?
			throw new Error 'Cannot create new LeasingOffer without existing opportunity'
		tenantMix = null
		if args.tenantMixID?
			tenantMix = tenantMixes.findOne id: args.tenantMixID
			if tenantMix?
				tenantMixID = tenantMix.id
				if tenantMix.state is 'Commited'
					throw new Error "Cannot add new Leasing Offer to commited TenantMix #{args.tenantMixID}"
			else
				throw new Error "Cannot create leasing offer variant attached to undefined TenantMix #{args.tenantMixID}"

		offer = {
			...createDocumentMetaInfo users.findOne(name: 'Grzegorz Bliżycki').id, true
			accountID: opportunity.accountID
			annotations: []
			comments: []
			description: ''
			id: objectid().toHexString()
			name: "Unnamed leasing offer - #{moment().format 'HH:mm DD/MM/YYYY'}"
			opportunityID: opportunity.id
			planID: null
			projectID: opportunity.projectID
			spaces: []
			state: 'Draft'
			tenantMixID: tenantMixID
			__typename: 'LeasingOffer'
		}
		offers.insert offer

		console.log '/?????/', tenantMixID, tenantMix, offer
		if tenantMix?
			tenantMix.offersIDS.push offer.id
			tenantMixes.update tenantMix

		offer

	updateLeasingOfferActiveStatus: (obj, args, context, info) ->
		# TODO: Check if leasing offer exists in DB
		# TODO: Avoid saving unmodified tenant mix

		# Arguments
		# offerID – subject of operation leasing offer
		# tenantMixID – subject of operation tenant mix
		# activate – control value
		tenantMix = tenantMixes.findOne id: args.tenantMixID
		if !tenantMix?
			throw new Error 'Cannot perform offer activation on undefined TenantMix'
		else if tenantMix.state is 'Commited'
			throw new Error 'Cannot modify commited TenantMix'

		# Make sure we have valid data structure & properties in the TenantMix
		tenantMix.attachedOffersIDS = [] if !tenantMix.attachedOffersIDS?
		tenantMix.offersIDS = [] if !tenantMix.offersIDS?

		# Activate leasing offer
		if args.activate is true
			tenantMix.offersIDS.push args.offerID
			tenantMix.offersIDS = _.uniq tenantMix.offersIDS

			tenantMix.attachedOffersIDS = _.without tenantMix.attachedOffersIDS, args.offerID
		else
			tenantMix.attachedOffersIDS.push args.offerID
			tenantMix.attachedOffersIDS = _.uniq tenantMix.attachedOffersIDS

			tenantMix.offersIDS = _.without tenantMix.offersIDS, args.offerID

		# tenantMix.metaInfo.modifiedBy =
		# 	__typename: null
		# 	collection: 'users'
		# 	type: 'Person'
		# 	id: users.findOne(name: 'Grzegorz Bliżycki').id
		# tenantMix.metaInfo.modifiedAt = moment().unix()
		tenantMix.metaInfo = {
			...tenantMix.metaInfo
			...metaInfoMutation collections, 'Grzegorz Bliżycki'
		}
		tenantMixes.update tenantMix
		tenantMix

	updateCostVariantSplit: (obj, args, context, info) ->
		if args.input.itemID?
			item = costsItems.findOne
				variantID: args.variantID
				lineID: args.input.lineID
				id: args.input.itemID
			if args.input.month?
				item.cashflow[args.input.month] = args.input.value
			else
				item.value = args.input.value
			costsItems.update item
		else
			line = costsLines.findOne
				variantID: args.variantID
				id: args.input.lineID
			if args.input.month?
				line.cashflow[args.input.month] = args.input.value
			else
				line.value = args.input.value
			costsLines.update line
		# PERF: After update prepare new cost variant
		costsVariants.findOne id: args.variantID


	updateTenantMixAttachedOffers: (obj, args, context, info) ->
		tenantMix = tenantMixes.findOne id: args.tenantMixID
		if !tenantMix?
			throw new Error 'Cannot perform offer attaching on undefined TenantMix'
		else if tenantMix.state is 'Commited'
			throw new Error 'Cannot modify commited TenantMix'

		previousState = offers.find id: $in: _.concat(tenantMix.offersIDS, tenantMix.attachedOffersIDS)
		[active, attached] = _.partition previousState, (offer) ->
			offer.id in tenantMix.offersIDS
		newState = offers.find id: $in: _.map args.offers, 'offerID'
		[newActive, newAttached] = _.partition newState, (offer) ->
			_.find(args.offers, offerID: offer.id).active

		# Check if every offer attached to tenant mix is not inline to other tenant mix
		if _.some(newState, (offer) -> offer.tenantMixID? and offer.tenantMixID isnt tenantMix.id)
			throw new Error 'Some offers are inline to another TenantMix'
		# Remove offer if it was inline to this tenant mix
		offersToBeRemoved = _.filter previousState, (offer) -> offer.tenantMixID? and offer.id not in newState

		tenantMix.attachedOffersIDS = _.map newAttached, 'id'
		tenantMix.offersIDS = _.map newActive, 'id'
		tenantMix.metaInfo = {
			...tenantMix.metaInfo
			...metaInfoMutation collections, 'Grzegorz Bliżycki'
		}

		if _.size(offersToBeRemoved) > 0
			console.log 'Removing inline offers'
			offers.findAndRemove id: $in: _.map offersToBeRemoved, 'id'


		tenantMixes.update tenantMix

		tenantMix
	updateLeasingOffer: (obj, args, context, info) ->
		offer = offers.findOne id: args.offerID

		if !offer?
			throw new Error 'Cannot perform updates on undefined Offer'
		else if offer.state is 'Commited'
			throw new Error 'Cannot modify commited Offer'

		offer.spaces = _.map args.spaces, (space) ->
			originalSpace = _.find offer.spaces, id: space.id
			{
				...originalSpace
				...addTypenamesToSpace
					type: originalSpace.type
					floor: originalSpace.floor
					volume:
						planned: _.round parseFloat(space.volumePlanned), 2
						measured: _.round parseFloat(space.volumeMeasured), 2
					rent:
						headline: _.round parseFloat(space.rent), 2
					incentives:
						total: _.round parseFloat(space.incentives), 2
					agentFee:
						months: _.round parseFloat(space.agentFee), 2
					rentFree:
						months: parseInt space.rentFree.months
						rate: _.round parseFloat(space.rentFree.rate), 2
					fitout:
						value: _.round parseFloat(space.fitout), 2
					dates:
						signing: space.signingDate
						commencement: space.commencementDate
						expiry: space.expiryDate
					expansion: space.expansion
					serviceChargeReconciled: space.serviceChargeReconciled
					yield: space.yield
			}
		offer.metaInfo = {
			...offer.metaInfo
			...metaInfoMutation collections, 'Grzegorz Bliżycki'
		}
		offers.update offer

		offer
	unassignSpace: (obj, args, context, info) ->
		offer = offers.findOne id: args.offerID

		if !offer?
			throw new Error 'Cannot perform updates on undefined Offer'
		else if offer.state is 'Commited'
			throw new Error 'Cannot modify commited Offer'

		offer.spaces = _.reject offer.spaces, id: args.spaceID
		offer.metaInfo = {
			...offer.metaInfo
			...metaInfoMutation collections, 'Grzegorz Bliżycki'
		}
		offers.update offer
		offer

	assignVacantSpace: (obj, args, context, info) ->
		offer = offers.findOne id: args.offerID

		if !offer?
			throw new Error 'Cannot perform updates on undefined Offer'
		else if offer.state is 'Commited'
			throw new Error 'Cannot modify commited Offer'

		project = projects.findOne id: offer.projectID
		{freeSpaceDefinitions} = contexts.findOne id: project.contextID
		definition = _.find freeSpaceDefinitions, {floor: args.space.floor, type: args.space.type}

		offer.spaces.push {
			...addTypenamesToSpace
				type: definition.type
				floor: definition.floor
				volume:
					planned: _.round args.space.volumePlanned, 2
					measured: _.round args.space.volumeMeasured, 2
				rent:
					headline: _.round definition.rent, 2
				incentives:
					total: _.round definition.incentives.total, 2
				agentFee:
					months: _.round definition.agentFee.months, 2
				rentFree:
					months: definition.rentFree.months
					rate: _.round definition.rentFree.rate, 2
				fitout:
					value: _.round definition.fitout, 2
				dates:
					signing: definition.dates.signing
					commencement: definition.dates.commencement
					expiry: definition.dates.expiry
				expansion: definition.expansion
				serviceChargeReconciled: definition.serviceChargeReconciled
				yield: definition.yield
			id: objectid().toHexString()
		}
		offer.metaInfo = {
			...offer.metaInfo
			...metaInfoMutation collections, 'Grzegorz Bliżycki'
		}
		offers.update offer
		offer
	createScenario: (obj, args, context, info) ->
		project = projects.findOne id: args.projectID
		baseContext = collections.contexts.findOne id: project.contextID
		context = {
			...createEmptyContext project.id
			..._.cloneDeep _.pick baseContext, [
				'tenantMixID'
				'costsVariantID'
				'divestmentID'
				'timeline'
				'finance'
				'freeSpaceDefinitions'
				'building'
			]
		}
		contexts.insert context

		scenario = {
			...createDocumentMetaInfo users.findOne(name: 'Grzegorz Bliżycki').id, true
			__typename: 'Scenario'
			id: objectid().toHexString()
			name: "Unamed scenario #{moment().format 'DD/MM/YYYY HH:mm'}"
			type: null
			projectID: project.id
			state: 'Draft'
			version: '1.0.0'
			touched: false
			contextID: context.id
		}
		scenarios.insert scenario
		scenario

	updateScenario: (obj, args, context, info) ->
		scenario = scenarios.findOne id: args.scenarioID
		context = contexts.findOne id: scenario.contextID
		if args.tenantMixID?
			context.tenantMixID = args.tenantMixID
			scenario.touched = true

		if args.costsVariantID?
			context.costsVariantID = args.costsVariantID
			scenario.touched = true

		if args.divestmentID?
			context.divestmentID = args.divestmentID
			scenario.touched = true

		scenarios.update scenario
		contexts.update context

		scenario
	moveSpace: (obj, args, context, info) ->
		offer = offers.findOne id: args.offerID

		if !offer?
			throw new Error 'Cannot perform updates on undefined Offer'
		else if offer.state is 'Commited'
			throw new Error 'Cannot modify commited Offer'
		offer.spaces = _.map offer.spaces, (space) ->
			if space.id isnt args.spaceID
				space
			else
				{
					...space
					type: args.section
					floor: args.floor
				}
		offers.update offer

		offer
	startRevenueAdjustments: (obj, args, context, info) ->
		scenario = scenarios.findOne id: args.scenarioID
		context = contexts.findOne id: scenario.contextID
		# Duplicate costs with draft state
		variant = tenantMixes.findOne id: context.tenantMixID
		oldVariantID = variant.id
		variant = {
			..._.cloneDeep _.omit variant, 'meta', '$loki'
			...createDocumentMetaInfo users.findOne(name: 'Grzegorz Bliżycki').id, true
			id: objectid().toHexString()
			state: 'Draft'
			name: "Unnamed tenant mix - #{moment().format 'HH:mm DD/MM/YYYY'}"
			inline: true
			_isBaseline: false
			contextID: context.id
		}
		tenantMixes.insert variant


		context.tenantMixID = variant.id


		# Find all local leasing offers drafts and make a copy
		for type in ['offersIDS', 'attachedOffersIDS']
			offersMapping = _.map offers.find(id: {$in: variant[type]}, state: 'Draft'), (originalOfferDraft) ->
				original: originalOfferDraft
				clone: {
					..._.omit originalOfferDraft, 'meta', '$loki'
					...createDocumentMetaInfo users.findOne(name: 'Grzegorz Bliżycki').id, true
					id: objectid().toHexString()
					state: 'Draft'
					name: originalOfferDraft.name
					description: originalOfferDraft.description
					tenantMixID: variant.id
					spaces: _.map originalOfferDraft.spaces, (space) ->
						{
							...space
							id: objectid().toHexString()
						}
				}
			# Replace previous local drafts ids for new ones
			variant[type] = _.map variant[type], (offerID) ->
				offerMapping = _.find offersMapping, (mapping) -> mapping.original.id is offerID
				if !originalOffer?
					offerID
				else
					console.log 'We found a bugger'
					offerMapping.clone.id

			# Save leasing offers
			offers.insert _.map offersMapping, 'clone'


		contexts.update context
		scenario
		# Set it as inline
	startReconciliation: (obj, args, context, info) ->
		scenario = scenarios.findOne id: args.scenarioID
		context = contexts.findOne id: scenario.contextID
		# Duplicate costs with draft state
		variant = costsVariants.findOne id: context.costsVariantID
		oldVariantID = variant.id
		variant = {
			..._.omit variant, 'meta', '$loki'
			...createDocumentMetaInfo users.findOne(name: 'Grzegorz Bliżycki').id, true
			id: objectid().toHexString()
			state: 'Draft'
			name: "Unnamed costs variant - #{moment().format 'HH:mm DD/MM/YYYY'}"
			inline: true
			_isBaseline: false
			contextID: context.id
		}
		costsVariants.insert variant

		context.costsVariantID = variant.id

		# Duplicate cost lines and items
		linesMapping = {}
		lines = costsLines.find variantID: oldVariantID
		newLines = _.map lines, (line) ->
			linesMapping[line.id] = objectid().toHexString()

			{
				..._.omit line, 'meta', '$loki'
				id: linesMapping[line.id]
				variantID: variant.id
			}
		costsLines.insert newLines
		items = costsItems.find variantID: oldVariantID
		costsItems.insert _.map items, (item) ->
			{
				..._.omit item, 'meta', '$loki'
				id: objectid().toHexString()
				variantID: variant.id
				lineID: linesMapping[item.id]
			}

		variantTM = tenantMixes.findOne id: context.tenantMixID
		variantTM.state = 'Commited'

		tenantMixes.update variantTM

		contexts.update context
		scenario
		# Set it as inline
	reviewScenario: (obj, args, context, info) ->
		scenario = scenarios.findOne id: args.scenarioID
		context = contexts.findOne id: scenario.contextID
		variant = costsVariants.findOne id: context.costsVariantID
		variant.state = 'Commited'

		costsVariants.update variant
		scenario
		# Change state from draft to commited
	commitScenario: (obj, args, context, info) ->
		# Commit scenario
		scenario = scenarios.findOne id: args.scenarioID
		context = contexts.findOne id: scenario.contextID
		scenario.state = 'Commited'
		scenarios.update scenario

		variant = costsVariants.findOne id: context.costsVariantID
		variant.inline = false
		costsVariants.update variant

		scenario

	createDocument: (obj, args, context, info) ->
		document = {
			...createDocumentMetaInfo users.findOne(name: 'Grzegorz Bliżycki').id, true
			__typename: 'Document'
			id: objectid().toHexString()
			companyID: args.basicInfo.companyID
			contractorID: args.basicInfo.contractorID
			status: 'BasicInfo'
			vdrStatus: 'Pending'
			location: 'HQ'
			dates: []
			identifiers: []
			entities: []
			asigneeID: users.findOne(name: 'Grzegorz Bliżycki').id
		}
		delete document.description
		delete document.name

		if args.category?
			document.type = args.category.type
			document.topicID = args.category.topicID
			document.status = 'Categorized'
		if args.category? and args.details?
			if args.details.description?
				document.description = args.details.description
			document.dates = args.details.dates
			document.identifiers = args.details.identifiers
			document.entities = args.details.entities
			document.status = 'Filled'
		if args.category? and args.details? and args.reviewed?
			if args.reviewed then document.status = 'Reviewed'

		documents.insert document
		console.log 'Created document', document
		document

	editDocument: (obj, args, context, info) ->
		document = documents.findOne id: args.documentID
		if args.basicInfo?
			document.companyID = args.basicInfo.companyID
			document.contractorID = args.basicInfo.contractorID
		if args.category?
			document.type = args.category.type
			document.topicID = args.category.topicID
			if document.status is 'BasicInfo' then document.status = 'Categorized'
		if document.type? and args.details?
			document.description = if args.details.description? then args.details.description
			document.dates = args.details.dates
			document.identifiers = args.details.identifiers
			document.entities = args.details.entities
			if _.includes ['BasicInfo', 'Categorized'], document.status then document.status = 'Filled'
		if document.type? and document.dates? and args.reviewed?
			if args.reviewed and _.includes ['BasicInfo', 'Categorized', 'Filled'], document.status
				document.status = 'Reviewed'

		documents.insert document
		console.log 'Edited document:', document
		document


	updateTenantMixContext: (obj, args, context, info) ->
		tenantMix = tenantMixes.findOne id: args.tenantMixID
		context = contexts.findOne id: tenantMix.contextID
		if args.costsVariantID?
			context.costsVariantID = args.costsVariantID
		if args.divestmentID?
			context.divestmentID = args.divestmentID
		if args.timeline?
			change = {...args.timeline}
			if args.timeline.closingDate?
				change.closing = args.timeline.closingDate
			delete change.closingDate
			context.timeline = {
				...context.timeline
				...change
			}
		contexts.update context
		context
	promoteContext: (obj, args, context, info) ->
		activeContext = collections.contexts.findOne id: args.contextID
		project = projects.findOne id: activeContext.projectID
		baseContext = collections.contexts.findOne id: project.contextID

		# Scenario context duplication
		context = {
			...createEmptyContext project.id
			..._.cloneDeep _.pick baseContext, [
				'tenantMixID'
				'costsVariantID'
				'divestmentID'
				'timeline'
				'finance'
				'freeSpaceDefinitions'
				'building'
			]
			..._.cloneDeep _.pick activeContext, [
				'tenantMixID'
				'costsVariantID'
				'divestmentID'
				'timeline'
				'finance'
				'freeSpaceDefinitions'
				'building'
			]
		}

		# Solidify tenant mix choice
		variant = tenantMixes.findOne id: context.tenantMixID
		oldVariantID = variant.id
		variant = {
			..._.cloneDeep _.omit variant, 'meta', '$loki'
			...createDocumentMetaInfo users.findOne(name: 'Grzegorz Bliżycki').id, true
			id: objectid().toHexString()
			state: 'Draft'
			name: "Unnamed tenant mix - #{moment().format 'HH:mm DD/MM/YYYY'}"
			inline: true
			_isBaseline: false
			contextID: context.id
		}
		tenantMixes.insert variant
		context.tenantMixID = variant.id


		# Find all local leasing offers drafts and make a copy
		for type in ['offersIDS', 'attachedOffersIDS']
			offersMapping = _.map offers.find(id: {$in: variant[type]}, state: 'Draft'), (originalOfferDraft) ->
				original: originalOfferDraft
				clone: {
					..._.omit originalOfferDraft, 'meta', '$loki'
					...createDocumentMetaInfo users.findOne(name: 'Grzegorz Bliżycki').id, true
					id: objectid().toHexString()
					state: 'Draft'
					name: originalOfferDraft.name
					description: originalOfferDraft.description
					tenantMixID: variant.id
					spaces: _.map originalOfferDraft.spaces, (space) ->
						{
							...space
							id: objectid().toHexString()
						}
				}
			# Replace previous local drafts ids for new ones
			variant[type] = _.map variant[type], (offerID) ->
				offerMapping = _.find offersMapping, (mapping) -> mapping.original.id is offerID
				if !originalOffer?
					offerID
				else
					console.log 'We found a bugger'
					offerMapping.clone.id

			# Save leasing offers
			offers.insert _.map offersMapping, 'clone'


		scenario = {
			...createDocumentMetaInfo users.findOne(name: 'Grzegorz Bliżycki').id, true
			__typename: 'Scenario'
			id: objectid().toHexString()
			name: if !_.isEmpty(args.name) then args.name else "Unamed scenario #{moment().format 'DD/MM/YYYY HH:mm'}"
			description: if !_.isEmpty(args.description) then args.description else ''
			type: null
			projectID: project.id
			state: 'Draft'
			version: '1.0.0'
			touched: true
			contextID: context.id
		}

		contexts.insert context
		scenarios.insert scenario
		scenario
	restoreTenantMixContext: (obj, args, context, info) ->
		tenantMix = collections.tenantMixes.findOne id: args.tenantMixID
		activeContext = collections.contexts.findOne id: tenantMix.contextID
		project = projects.findOne id: activeContext.projectID
		baseContext = collections.contexts.findOne id: project.contextID

		activeContext = {
			..._.omit activeContext, [
				'costsVariantID'
				'divestmentID'
				'timeline'
				'finance'
				'freeSpaceDefinitions'
				'building'
			]
		}
		contexts.update activeContext
		activeContext

