import _ from 'lodash'

import { calculateBuildingKPI } from 'data/helpers'

export default (collections) ->
	{
		opportunities
		projects
		# stackingPlans
		offers
		users
		contexts
	} = collections

	grossValue: (obj, args, context, info) ->
		throw new Error 'NOT IMPLEMENTED: LeasingOfferResult – grossValue'
	averageOccupancy: (obj, args, context, info) ->
		project = projects.findOne id: obj.projectID
		{building} = contexts.findOne id: project.contextID
		buildingKPI = calculateBuildingKPI building
		_.sumBy(obj.spaces, 'totalGla') / buildingKPI.totalGla
	economicOccupancy: (obj, args, context, info) ->
		_.sumBy(obj.spaces, 'rent.annual') / obj.results.totalAnnualRent
