import React from 'react'
import moment from 'moment-mini'

formatterMoney = new Intl.NumberFormat 'pl-PL',
		style: 'decimal'
		minimumFractionDigits: 2
		maximumFractionDigits: 2
formatterInteger = new Intl.NumberFormat 'pl-PL',
	style: 'decimal'
	minimumFractionDigits: 0
	maximumFractionDigits: 0
formatterEur = new Intl.NumberFormat 'pl-PL',
	style: 'currency'
	currency: 'EUR'
	minimumFractionDigits: 2
	maximumFractionDigits: 2
	currencyDisplay: 'symbol'
formatterPln = new Intl.NumberFormat 'pl-PL',
	style: 'currency'
	currency: 'PLN'
	minimumFractionDigits: 2
	maximumFractionDigits: 2
	currencyDisplay: 'code'
formatterSqm = new Intl.NumberFormat 'pl-PL',
		style: 'decimal'
		minimumFractionDigits: 2
		maximumFractionDigits: 2
formatterUnits = new Intl.NumberFormat 'pl-PL',
	style: 'decimal'
		minimumFractionDigits: 0
		maximumFractionDigits: 0

contextDefault =
	eur: (val) ->
		if !val?
			return '–'
		formatterEur.format val
	pln: (val) ->
		if !val?
			return '–'
		formatterPln.format val
	money: (val) ->
		if !val?
			return '–'
		formatterMoney.format val
	numericValue: (val) ->
		if !val?
			return '–'
		val
			.toFixed(2)
			.replace('.', ',')
			.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
	integer: (val) ->
		if !val?
			return '–'
		formatterInteger.format val
	sqm: (val, unit = true) ->
		if !val?
			return '–'
		_.compact([
			formatterSqm.format(val)
			if unit then 'sqm.'
		]).join ' '
	units: (val, unit = true) ->
		if !val?
			return '–'
		_.compact([
			formatterUnits.format(val)
			if unit then 'units'
		]).join ' '
	percentageShort: (val) ->
		if !val?
			return '–'
		formatterInteger.format val * 100
	percentage: (val) ->
		if !val?
			return '–'
		formatterSqm.format val * 100
	percentageSign: (val) ->
		if !val?
			return '–'
		"#{formatterSqm.format val * 100} %"
	dateTimeAgo: (val, relativePoint = new Date()) ->
		if !(val instanceof moment)
			val = moment.unix(val)

		if Math.abs(val.diff(relativePoint, 'days')) >= 3
			contextDefault.dateLong val
		else
			val.from(relativePoint)

	date: (val) ->
		if val instanceof moment
			val.format 'DD/MM/YYYY'
		else
			moment.unix(val).format 'DD/MM/YYYY'
	dateShort: (val) ->
		if val instanceof moment
			val.format 'MM/YYYY'
		else
			moment.unix(val).format 'MM/YYYY'
	dateLong: (val) ->
		if val instanceof moment
			val.format 'MMMM D, YYYY, HH:mm'
		else
			moment.unix(val).format 'MMMM D, YYYY, HH:mm'
	time: (val) ->
		if val instanceof moment
			val.format 'HH:mm'
		else
			moment.unix(val).format 'HH:mm'
	floors: (val) ->
		if !val?
			return '–'
		else
			val.sort().reverse().join ', '
	fileSize: (val) ->
		magnitude = Math.floor( Math.log(val) / Math.log(1024) )
		(val / Math.pow(1024, magnitude) ).toFixed(2) + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][magnitude]
	rentFree: ({months, rate}) ->
		"#{contextDefault.integer months}mo (#{contextDefault.percentageShort rate / 100}%)"
context = React.createContext contextDefault

# Risky export only as default for other libraries
export Formatter = contextDefault
export default context
export FormatterProvider = React.createFactory context.Provider
export FormatterConsumer = React.createFactory context.Consumer
