export default '''
	enum ScenarioType {
		BestCase
		WorstCase
		MostLikely
	}

	input ScenariosFilters {
		type: [ScenarioType!]
		state: [DocumentState!]
	}

	type Scenario implements FileInterface & EditableMetainfo {
		# UID of tenant mix
		id: ID!
		# Short name created by the user
		name: String!
		# Short document summary & FileInterface
		description: String!
		comments: [Comment!]
		annotations: [Annotation!]
		state: DocumentState!

		# Meta info
		createdAt: Int!
		createdBy: Actor
		modifiedAt: Int!
		modifiedBy: Actor

		project: Project!
		context: ProjectContext!

		type: ScenarioType
		touched: Boolean!
	}
	type DivestmentOption implements FileInterface & EditableMetainfo & BaselineFileInterface {
		# UID of tenant mix
		id: ID!
		# Short name created by the user
		name: String!
		# Short document summary & FileInterface
		description: String!
		comments: [Comment!]
		annotations: [Annotation!]
		state: DocumentState!

		# Meta info
		createdAt: Int!
		createdBy: Actor
		modifiedAt: Int!
		modifiedBy: Actor

		isBaseline: Boolean!

		# Real parameters
		additionalPriceDeduction: Float
		bookValueContingency: Float
		additionalDiscount: Float
		warrantyProvision: Float
		agentFeesDivestment: Float
		advisoryFeesDivestment: Float
		provisionForMiscallenous: Float
		yield: Float
		closingDate: Int
	}
'''
