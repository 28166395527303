import gql from 'graphql-tag'
import {
	AccountList
} from './query.graphql'

export RootQuery = gql """
	query{
		accounts @client {
			#{AccountList}
		}
	}
"""
