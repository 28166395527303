import _ from 'lodash'

import {
	resolveMetaInfo
	getSignedSpaces
	listAllMissingVacantSpaces
	calculateFullResults
	getProjectBaselineContext
	expandContext
	trimContext
} from 'data/helpers'

export default (collections) ->
	{
		opportunities
		projects
		# stackingPlans
		offers
		users
		tenantMixes
		companies
		contexts
	} = collections

	# NOTE: All spaces are always align but only on basic level
	{
		...resolveMetaInfo collections
		# name: (obj, args, context, info) ->
		# 	account = companies.findOne id: obj.accountID
		# 	opportunity = opportunities.findOne id: obj.opportunityID
		# 	if account? and opportunity?
		# 		"##{obj.number}"
		# 	else
		# 		"Unnamed-#{obj.number}"
		account: (obj, args, context, info) ->
			{
				...companies.findOne id: obj.accountID
				__typename: 'Account'
			}
		opportunity: (obj, args, context, info) ->
			opportunities.findOne id: obj.opportunityID
		grossValue: (obj, args, context, info) ->
			_.round _.sumBy(obj.spaces, (space) -> space.grossValue.total), 2
		units: (obj, args, context, info) ->
			_.sumBy _.filter(obj.spaces, type: 'Garage'), 'volume.base'
		volume: (obj, args, context, info) ->
			_.sumBy _.reject(obj.spaces, type: 'Garage'), 'volume.base'
		nla: (obj, args, context, info) ->
			_.round _.sumBy(_.reject(obj.spaces, type: 'Garage'), 'nla'), 2
		gla: (obj, args, context, info) ->
			_.round _.sumBy(_.reject(obj.spaces, type: 'Garage'), 'gla'), 2
		totalGla: (obj, args, context, info) ->
			_.round _.sumBy(obj.spaces, 'totalGla'), 2
		floors: (obj, args, context, info) ->
			_.uniq(_.map obj.spaces, 'floor').sort()
		averageRent: (obj, args, context, info) ->
			types =  _.reduce _.groupBy(obj.spaces, 'type'), (acc, value, key) ->
				acc.push
					type: key
					total: _.sumBy value, 'volume.base'
				acc
			, []
			if _.isEmpty types
				return null
			else
				_.meanBy _.filter(obj.spaces, type: _.maxBy(types, 'total').type), 'rent.headline'
		dominantSpaceType: (obj, args, context, info) ->
			types =  _.reduce _.groupBy(obj.spaces, 'type'), (acc, value, key) ->
				acc.push
					type: key
					total: _.sumBy value, 'volume.base'
				acc
			, []
			if _.isEmpty types
				return null
			else
				_.maxBy(types, 'total').type
		dominantSpaceVolume: (obj, args, context, info) ->
			types =  _.reduce _.groupBy(obj.spaces, 'type'), (acc, value, key) ->
				acc.push
					type: key
					total: _.sumBy value, 'volume.base'
				acc
			, []
			if _.isEmpty types
				return null
			else
				_.meanBy _.filter(obj.spaces, type: _.maxBy(types, 'total').type), 'volume.base'
		inline: (obj, args, context, info) ->
			obj.tenantMixID?
		signed: (obj, args, context, info) ->
			project = projects.findOne id: obj.projectID
			{allWonLeasingOffers} = getSignedSpaces obj.projectID, collections

			obj.id in _.map allWonLeasingOffers, 'id'

		noi: (obj, args, context, info) ->
			_.sumBy obj.spaces, 'rent.annual'
		dates: (obj, args, context, info) ->
			result =
				__typename: 'CommercialSpaceDates'
				signing: _.min(_.map obj.spaces, 'dates.signing') || null
				commencement: _.min(_.map obj.spaces, 'dates.commencement') || null
				expiry: _.max(_.map obj.spaces, 'dates.expiry') || null
			result
		rentFree: (obj, args, context, info) ->
			__typename: 'CommercialSpaceRentFree'
			months: _.round _.meanBy(obj.spaces, 'rentFree.months'), 1
			rate: _.meanBy obj.spaces, 'rentFree.rate'
			total: _.sumBy obj.spaces, 'rentFree.total'

		# This method can calculate least offer result in context of specific tenant mix, scenario or baseline of a project
		result: (obj, args, context, info) ->
			# CACHE: This must land in cache otherwise it will freeze UI completely
			context = null
			if args?.tenantMixID?
				partialContext = contexts.find id: args.tenantMixID
				baselineContext = getProjectBaselineContext {collections, projectID: obj.projectID}
				context = {
					...partialContext
					...baselineContext
				}
			else if args?.scenarioID?
				console.log  'Tenant mix context – not ready'
			if !context?
				context = getProjectBaselineContext {collections, projectID: obj.projectID}
			result = {
				...obj
				__typename: 'LeasingOfferResult'
				results: do ->
					# if args?.tenantMixID?
					# 	calculateFullResults {collections, projectID: obj.projectID, tenantMix: tenantMixes.findOne(id: args.tenantMixID)}
					# else

					context = expandContext {collections, context}
					calculateFullResults {
						...trimContext context
						collections
					}

			}

			result


		tenantMixes: (obj, args, context, info) ->
			tenantMixes.find offersIDS: {$contains: obj.id}, root: $where: (value) -> !value?
	}
