"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;

/*
 Scopes regarding access to organizations
*/
function _default() {
  var resolved = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  return {
    Organization: [{
      subject: 'Organization',
      actions: 'crud'
    }],
    OrganizationCreate: [{
      subject: 'Organization',
      actions: 'create'
    }],
    OrganizationRead: [{
      subject: 'Organization',
      actions: 'read'
    }],
    OrganizationUpdate: [{
      subject: 'Organization',
      actions: 'update'
    }],
    OrganizationDelete: [{
      subject: 'Organization',
      actions: 'delete'
    }]
  };
}

;