
###
Side navigation
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'

# Renderable
import { div, button, img } from 'react-dom-factories'

import { matchPath, Link as _Link } from 'react-router-dom'
Link = React.createFactory _Link

Fragment = React.createFactory React.Fragment

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

# Styles
import {
	fileText
	chevronDown
	chevronUp
} from 'react-icons-kit/feather'
import styles from './index.styl'


export class NavItem extends React.Component
	@propTypes =
		isFoldable: PropTypes.bool
		isInitiallyOpen: PropTypes.bool
		# title: PropTypes.string.isRequired
		# children: PropTypes.element

	@defaultProps =
		isFoldable: true
		isInitiallyOpen: false

	constructor: (props) ->
		super props
		@state =
			isOpen: @props.isInitiallyOpen

	render: ->
		div
			key: @props.title
			className: cnames [
				styles.accessoryNavItem
				if @props.isScrollable then styles.scrollable
			]
		,
			if !_.isEmpty @props.title
				div
					onClick: () => if @props.isFoldable then @setState isOpen: !@state.isOpen
					className: cnames styles.titleBar, if @props.isFoldable then styles.interactive
				,
					div className: styles.title,
						@props.title
					if @props.isFoldable
						Icon
							icon: if @state.isOpen then chevronUp else chevronDown
							size: 9
			if @state.isOpen or !@props.isFoldable
				div {className: cnames [styles.body, if _.isEmpty @props.title then styles.noTitle]},
					@props.children

export default class SideNavigation extends React.Component
	@propTypes =
		hub: PropTypes.string.isRequired
		# hubIcon: PropTypes.string.isRequired
		project: PropTypes.string.isRequired
		applications: PropTypes.arrayOf PropTypes.shape
			icon: PropTypes.node
			label: PropTypes.string.isRequired
			counter: PropTypes.number
			href: PropTypes.string
		accessoryNavigation: PropTypes.element

	@defaultProps =
		interlaceAccessory: true

	constructor: (props) ->
		super props

	matchPath: (paths) ->
		_.find paths, (path) ->
			match = matchPath window.location.pathname,
				path: path
			match?

	render: ->
			div { className: styles.sideNavWrapper },
				div { className: styles.sideNav },
					div { className: cnames [styles.hubNav, @props.hubClassName ]},
						if _.isString @props.hubIcon
							img { className: styles.hubIcon, src: @props.hubIcon }
						else
							div { className: styles.hubIcon }, @props.hubIcon
						div {},
							div {}, @props.project
							div { className: styles.hubLabel }, @props.hub
					Fragment {},
						_.map @props.applications, (app, index) =>
							isMatch = @matchPath _.compact _.concat app.link, app.otherLinks
							Fragment {key: app.label},
								Link {
									key: app.label
									className: cnames styles.appItem, if isMatch
										if @props.linkClassName? then @props.linkClassName else styles.active
									to: app.link
									onClick: if app.onClick? then app.onClick
								},
									div { className: cnames [styles.hubColorIndicator, if isMatch then @props.hubClassName] }
									if app.icon?
										div { className: styles.appIcon }, app.icon
									app.label
									if app.counter? then div { className: styles.counter}, app.counter
								if @props.interlaceAccessory and isMatch
									@props.accessoryNavigation
					if !@props.interlaceAccessory
						div { className: styles.accessoryNavList }, @props.accessoryNavigation
					if @props.footer?
						div { className: styles.footer }, @props.footer
				div {className: styles.pageWrapper}, @props.children
