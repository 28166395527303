###
List of Users for project
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import Loadable from 'react-loadable'
import adopt from 'libs/adopt'

# Renderable
import { div, p, ul, li, small, h1, h2, h3, span } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import {
	Route as _Route
	Switch as _Switch
	Redirect as _Redirect
} from 'react-router-dom'
Route = React.createFactory _Route
Switch = React.createFactory _Switch
Redirect = React.createFactory _Redirect

import _RouterTabs from '@bevy/router-tabs'
RouterTabs = React.createFactory _RouterTabs

import _ApplicationHeader from 'components/ApplicationHeader'
ApplicationHeader = React.createFactory _ApplicationHeader

import { Query as _Query } from 'react-apollo'
Query = React.createFactory _Query

import { NotificationConsumer } from '../../application/components/NotificationManager'

import { UserInfoConsumer, Can } from 'libs/userInfo'

import _HeaderBar from 'components/HeaderBar'
HeaderBar = React.createFactory _HeaderBar

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _Toolbox from 'components/Toolbox'
Toolbox = React.createFactory _Toolbox

import { ButtonGroup as _ButtonGroup } from '@atlaskit/button'
ButtonGroup = React.createFactory _ButtonGroup

import _Button from '@bevy/button'
Button = React.createFactory _Button

import _Tooltip from '@bevy/tooltip'
Tooltip = React.createFactory _Tooltip

import _TableView from '@bevy/table-view'
TableView = React.createFactory _TableView

import _EditableItem from '@bevy/editable-item'
EditableItem = React.createFactory _EditableItem

import {CheckboxSelect as _CheckboxSelect, components} from '@atlaskit/select'
CheckboxSelect = React.createFactory _CheckboxSelect

# Data
import { RootQuery, UpdateUserRoles } from './data'

# Styles
import styles from './index.styl'
import {
	plus
} from 'react-icons-kit/feather'


export default class ProjectUsers extends React.Component
	constructor: (props) ->
		super props
		@data = {}

	render: ->
		div {
			className: cnames [
				@props.className
				styles.mainPage
			]
		},
			Query
				query: RootQuery
				variables:
					projectSlug: @props.match.params.project
			, ({error, loading, data}) =>
				if !_.isEmpty data
					@data = data
				if loading
					div {},
				else
					Switch {},
						Route
							render: =>
								RouterTabs
									className: styles.tabs
									location: @props.location
									header: ApplicationHeader {
										loading: loading
										project: if !loading then @data.project.name
										application: 'Users'
									}
									tabs: [
											id: 1
											label: 'All'
											href: "/projects/#{@props.match.params.project}/users"
											content: div {className: styles.tabContent},
												React.createElement UsersView, {...@props, project: @data.project}
									]

roleOptions = [
		label: 'Full access'
		options: [
				value: 'Project-Manager'
				label: 'Project Manager'
			,
				value: 'Project-Leader'
				label: 'Project Leader'
			,
				value: 'Document-Manager'
				label: 'Document Manager'
		]
	,
		label: 'Edit access'
		options: [
			value: 'Legal-User'
			label: 'Legal User'
		,
			value: 'Finance-Tester'
			label: 'Finance Tester'
		]
]

DataLayer = adopt
	queries: {}
	mutations: {
		updateRoles: ({setNotification}) ->
			mutation: UpdateUserRoles
			onCompleted: -> setNotification({content: 'Roles updated', appearance: 'success'})
	}

class UsersView extends React.Component
	constructor: (props) ->
		super props
		@state =
			selectedUsers: []

	getTableColumns: (userInfo) -> [
			key: 'name'
			header:
				content: 'Name'
				width: min: 200
				isSortable: false
			cell: ({user}) -> div {className: cnames styles.compactText, styles.centered}, user.name
		,
			key: 'email'
			header:
				content: 'Email'
				width: min: 50
				isSortable: false
			cell: ({user}) -> div {className: cnames styles.compactText, styles.centered}, user.email
		,
			key: 'groups'
			header:
				content: 'Groups'
				width: min: 50
				isSortable: false
			cell: ({user}) -> div {className: cnames styles.compactText, styles.centered}, _.join (_.map user.groups, 'name'), ', '
		,
			key: 'role'
			header:
				content: 'Role'
				width: min: 400
			cell: ({user, onRolesChange}) =>
				div {className: styles.editableCell, onClick: (e) -> e.stopPropagation()},
					do =>
						userRoles = _.map user.projectsPermissions[0].roles, (role) => _.find (_.flatten _.map roleOptions, 'options'), value: role
						EditableItem
							showButtonsAlways: true
							renderDisplay: ({onClick}) =>
								div
									className: cnames [
										if userInfo.ability.can 'update', @props.project then styles.interactive
									]
									onDoubleClick: onClick
								,
									_.join (_.map userRoles, 'label'), ', '
							renderEdit: ({tempValue, onTempChange, onBlur}) =>
								CheckboxSelect
									menuPortalTarget: document.body
									styles: menuPortal: (base) -> { ...base, zIndex: 9999 }
									isSearchable: true
									isMulti: true
									options: roleOptions
									formatOptionLabel: (option) -> option.label
									getOptionLabel: (option) -> option.label
									getOptionValue: (option) -> option.value
									onChange: onTempChange
									value: tempValue
									spacing: 'compact'
							initialEditValue: userRoles
							onChange: onRolesChange
							# enableEdit: userInfo.ability.can 'update', @props.project
							enableEdit: false
							enableEnterPress: false
							isRequired: false
							hideButtonWhenNoChange: false
	]

	getActions4Users: (userInfo, users) ->
		result = []
		if users.length is 1
			user = users[0]
			# FIXME QUICKFIX
			# if (user.id isnt userInfo.me.id) and (userInfo.ability.can 'logAs', user)
			if (user.id isnt userInfo.me.id) and _.includes userInfo.me.globalRoles, 'Admin'
				result.push
					key: 'logAs'
					name: 'Log as this user'
					description: 'Inspect user\'s access & perform actions on behalf of this user'
		result

	handleAction: (userInfo, actionKey, users) ->
		switch actionKey
			when 'logAs'
				userInfo.logAs users[0]
			else
				console.debug 'Not yet implemented'

	renderToolbar: (userInfo) ->
		fixedActions = [
			Can {do: 'update', on: @props.project},
				ButtonGroup {},
					Tooltip {content: 'Add user to this project'},
						Button
							appearance: 'subtle'
							isDisabled: true
							iconBefore: Icon
								icon: plus
								size: 16
								className: styles.icon
							className: styles.button
							onClick: =>
						, 'Add people'
		]

		users = _.compact _.map @state.selectedUsers, (id) => _.find @props.project.members, 'id': id
		usersActions = if _.isEmpty users then [] else @getActions4Users userInfo, users
		customActions = _.map usersActions, (item) =>
			if _.isArray item
				ButtonGroup {},
					_.map item, (subitem) => @renderActionButton userInfo, subitem
			else
				@renderActionButton userInfo, item

		Toolbox
			className: styles.header
			actions: fixedActions
			custom: customActions

	renderActionButton: (userInfo, action) ->
		Tooltip {key: action.key, content: action.description},
			Button
				appearance: 'subtle'
				className: styles.button
				isDisabled: action.isDisabled
				isLoading: action.isLoading
				onClick: => @handleAction userInfo, action.key, (_.compact _.map @state.selectedUsers, (id) => _.find @props.project.members, 'id': id)
				iconBefore: if action.icon?
					Icon
						icon: action.icon
						size: 16
						className: styles.icon
			, action.name

	render: ->
		NotificationConsumer {}, ({setNotification}) =>
			DataLayer {
				props: @props
				setNotification: setNotification
			}, (operations) =>
				UserInfoConsumer {}, (userInfo) =>
					div {className: styles.pageWrapper},
						div {className: styles.page},
							# Header bar
							# do => if userInfo.ability.can 'update', @props.project
							Can {do: 'update', on: @props.project},
								HeaderBar toolbar: @renderToolbar(userInfo)
							div {className: styles.content},
								div {className: styles.headerText}, 'Application access'
								div {}, '''
									A user must belong to group assigned to an application to be able to log in and access that application.
									When you create a user for a S360 application, that user is automaticaly added to the application's default group.
									Additional permissions can be assigned to a group via global permisions.
								'''
								div {className: styles.headerText}, 'Users'
								div {className: styles.tableWrapper},
									TableView
										rowHeight: 40
										head: cells: _.map @getTableColumns(userInfo), (column) -> {key: column.key, ...column.header}
										rows: _.map (_.sortBy @props.project.members, 'name'), (user) =>
											id: user.id
											user: user
											onRolesChange: (values) =>
												operations.updateRoles.mutation
													variables:
														userID: user.id
														roles: _.map values, 'value'
														projectSlug: @props.match.params.project

										columns: @getTableColumns(userInfo)
										# handleSort: @props.onSort
										# sortKey: @props.sortKey
										# sortOrder: @props.sortOrder
										onSelectionChange: (users) =>
											@setState selectedUsers: _.concat [], users
										selectedRows: @state.selectedUsers
