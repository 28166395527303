export default '''
	# Regular user
	type Person {
		id: ID!
		email: String!
		name: String!
		picture: Image!
		department: String
		position: String
		telephone: String!
		company: Company
	}
	# Service users e.g. applications
	type Service {
		id: ID!
		name: String!
		description: String!
		picture: Image!
	}
'''
