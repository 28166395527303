###
Inline section header with support for description, actions, and advance setting
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import DataAttribute from '@bevy/data-attribute'
# Renderable
import { div, img, p } from 'react-dom-factories'

import _Button from '@bevy/button'
Button = React.createFactory _Button

import { ButtonGroup as _ButtonGroup } from '@atlaskit/button'
ButtonGroup = React.createFactory _ButtonGroup
# Styles
import styles from './index.styl'



export default class SectionHeader extends React.Component
	render: ->
		div {
			className: cnames [
				@props.className
				styles.base
				if @props.separate then styles.separate
			]
		},
			do => if @props.action?
				div {className: styles.action}, @props.action
			div {className: styles.info},
				div {className: styles.title}, @props.title
				do => if @props.description?
					p {className: styles.description}, @props.description
			if @props.actions?
				div {className: styles.actions}, @props.actions


