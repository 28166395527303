export default '''
	type ProjectTimeline {
		start: Int!
		end: Int!

		landAcquisition: Int!

		constructionStart: Int!
		constructionEnd: Int!

		closingDate: Int!
		phases: [ProjectPhase!]
	}
	type ProjectPhase {
		type: ProjectPhaseType!
		start: Int!
		end: Int!
		duration: Int!
		name: String!

	}
	type ProjectFinanceProperties {
		fxRate: Float!
		serviceChargeFxRate: Float
		interestRate: Float!
	}

	type ProjectBaseline {
		timeline: ProjectTimeline!
		finance: ProjectFinanceProperties!
		# Baseline spaces definitions
		definitions: [BaselineSpace!]
		# Presentable baseline tenant mix
		tenantMix: TenantMix!
		# Costs baseline
		costsTable: CostVariant!
		# Divestment option baseline
		divestment: DivestmentOption!
	}


	# PROJECT
	type Project {
		id: ID!
		name: String!
		slug: String!
		city: String!
		country: String!
		size: Float
		spv: Organization!
		documents(filters: DocumentFilter, pagination: PaginationInput): [Document!]
		documentsStats: DocumentsStats!
		opportunities(filter: OpportunitiesFilter): [Opportunity!]
		offers(filter: LeasingOfferFilters): [LeasingOffer]
		costsTables(type: CostVariantType): [CostVariant!]
		# TODO!: ID Should not be optional?
		costsTable(id: ID): CostVariant
		# Fetch single tenant mix be ID
		tenantMix(id: ID!): TenantMix
		# TODO: Add filters
		tenantMixes: [TenantMix!]
		baseline: ProjectContext!
		thumbnail: Image!
		# Custom business logic
		openAccounts: [Account!]

		scenarios(filter: ScenariosFilters): [Scenario!]
		scenario(id: ID!): Scenario

		divestments: [DivestmentOption!]
		divestment(id: ID!): DivestmentOption

	}

	# Brief description of error encountered during calculations of project result
	type CalculationsError {
		name: String!
		description: String!
		code: Int!
		value: Float
	}

	type UncashedRentFlow {
		startDate: Int!
		endDate: Int!
		totalRent: [Float!]
		effectiveRent: [Float!]
		uncashedRent: [Float!]
	}

	# If value of result is undefined it is a result of error in calculations
	type ProjectResult {
		totalAnnualRent: Float
		rentFreeClosing: Float
		shortfallRent: Float
		shortfallServiceCharge: Float
		PV: Float
		grossSalesPrice: Float
		agents: Float
		incentives: Float
		addedValue: Float
		fitout: Float
		uncashedRent: Float
		uncashedRentFlow: UncashedRentFlow
		bookValue: Float
		bookValueContingency: Float
		additionalDiscount: Float
		warrantyProvision: Float
		advisoryFeesDivestment: Float
		masterLeaseProvision: Float
		provisionForMiscallenous: Float
		totalInvestment: Float
		totalDeductions: Float
		agentFeesAgreements: Float
		agentFeesDivestment: Float
		totalCostOfSale: Float
		marketValue: Float
		GOS: Float
		DP: Float
		# Percentage
		averageOccupancy: Float
		averageVacancy: Float
		economicOccupancy: Float
		economicVacancy: Float
		#
		errors: [CalculationsError!]

		local: ProjectResultLocal
	}
	type ProjectResultLocal {
		totalAnnualRent: Float
		rentFreeClosing: Float
		shortfallRent: Float
		shortfallServiceCharge: Float
		PV: Float
		grossSalesPrice: Float
		agents: Float
		incentives: Float
		fitout: Float
		# addedValue: Float
		uncashedRent: Float
		bookValue: Float
		bookValueContingency: Float
		additionalDiscount: Float
		warrantyProvision: Float
		advisoryFeesDivestment: Float
		masterLeaseProvision: Float
		provisionForMiscallenous: Float
		totalInvestment: Float
		totalDeductions: Float
		agentFeesAgreements: Float
		agentFeesDivestment: Float
		totalCostOfSale: Float
		marketValue: Float
		GOS: Float
		DP: Float
	}


	enum ProjectContextType {
		Baseline
		WhatIfContext
		Forecast
	}

	type ProjectContext {
		type: ProjectContextType!

		timeline: ProjectTimeline!
		finance: ProjectFinanceProperties!
		freeSpaceDefinitions: [BaselineSpace!]
		tenantMix: TenantMix!
		costsTable: CostVariant!
		divestment: DivestmentOption!
		building: Building!

		result: ProjectResult!
	}
'''
