exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3FpseBmCJEZmaOrq_JdVzy ._3HEbNkiA-RMJs7pjKiUM1M {\n  margin-top: 0;\n  margin-bottom: 0;\n}\n._3FpseBmCJEZmaOrq_JdVzy ._3HEbNkiA-RMJs7pjKiUM1M .YB9jPoAud7KDNZ4RKCyFp {\n  padding: 8px 8px 0;\n}\n._30FklRD0OlRajyuvwey0y5 span {\n  background-color: initial;\n  border: 0;\n  padding: 0;\n}\n", ""]);

// Exports
exports.locals = {
	"base": "_3FpseBmCJEZmaOrq_JdVzy",
	"header": "_3HEbNkiA-RMJs7pjKiUM1M",
	"bevy-document-header-document-info": "YB9jPoAud7KDNZ4RKCyFp",
	"avatarWrapper": "_30FklRD0OlRajyuvwey0y5"
};