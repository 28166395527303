###
Common way of representing floor information on the left of the building
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
# Renderable
import { div } from 'react-dom-factories'

import _Toolbox from 'components/Toolbox'
Toolbox = React.createFactory _Toolbox

# Styles
import styles from './index.styl'


export default class HeaderBar extends React.Component
	@propTypes:
		# header: PropTypes.node.isRequired
		# descriptor: PropTypes.node
		header: PropTypes.node
		kpi: PropTypes.node
		toolbar: PropTypes.oneOfType [
			PropTypes.node
			PropTypes.object
		]
		filters: PropTypes.node
	@defaultProps:
		document: false
	render: ->
		div {
			className: cnames [
				@props.className
				styles.base
				if @props.spacing is 'compact' then styles.compact
				if @props.header? then styles.documentHeader else styles.pageHeader
				if @props.document then styles.embeddedHeader
			]
		},
			if @props.header? then div {className: cnames [styles.element, styles.header]}, div {className: styles.elementContent}, @props.header
			if @props.kpi? then div {className: cnames [styles.element, styles.kpi]}, div {className: styles.elementContent}, @props.kpi
			if @props.toolbar? then div {className: cnames [styles.element, styles.toolbar]}, div {className: styles.elementContent}, @props.toolbar
			if @props.filters? then div {className: cnames [styles.element, styles.filters]}, div {className: styles.elementContent}, @props.filters

			# div {className: cnames [
			# 	styles.top
			# ]},
			# 	@props.header
			# 	do => if @props.descriptor?
			# 		div {className: styles.descriptor}, @props.descriptor
			# do => if @props.message?
			# 	div {className: styles.message}, @props.message
			# do => if @props.toolbar?
			# 	div {className: cnames [
			# 		styles.toolbar
			# 	]}, @props.toolbar
