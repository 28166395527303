###
Skeleton version of popular components used in "box" layouts to present simplified version of final solution / flow
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import PropTypes from 'prop-types'
import Chance from 'chance'
chance = new Chance
# Renderable
import { div, table, thead, tbody, tr, th, td } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment
# Styles
import styles from './index.styl'



defaultPropTypes =
	level: PropTypes.oneOf [
		-3
		-2
		-1
		0
		1
		2
		3
		4
		5
	]
	animated: PropTypes.bool

export Box = class BoxSkeleton extends React.Component
	@propTypes: {
		...defaultPropTypes
	}
	@defaultProps:
		level: 0
		animated: false
	render: ->
		div {
			className: cnames [
				styles.base
				@props.className
				styles["level#{@props.level}"]
				if @props.animated then styles.animated
				'skeleton-box'
			]
			style: @props.style
		}, @props.children

export Button = class ButtonSkeleton extends React.Component
	@propTypes: {
		...defaultPropTypes
	}
	@defaultProps:
		level: 0
		animated: false
	render: ->
		div {
			..._.omit @props, ['animated', 'level']
			className: cnames [
				styles.base
				styles.button
				@props.className
				styles["level#{@props.level}"]
				if @props.animated then styles.animated
				'skeleton-button'
			]
		}, @props.children
export Tree = class TreeSkeleton extends React.Component
	@propTypes: {
		...defaultPropTypes
		nestedLevel: PropTypes.number
		showRoot: PropTypes.bool
	}
	@defaultProps:
		level: 0
		animated: false
		nestedLevel: 4
		showRoot: false
	renderLevel: (level) ->
		Fragment {}, _.map [0..._.random 2, 4], (index) =>
			div {
				className: cnames [
					styles.treeItem
				]
				key: index
			},
				React.createElement Box, {
					className: styles.treeItemTitle
					level: @props.level
				}, _.capitalize chance.word syllables: _.random 2, 6
				if level + 1 < @props.nestedLevel
					div {
						className: styles.treeItemContent
					}, @renderLevel level + 1
	render: ->
		div {
			className: styles.tree
		}, do =>
			div {className: cnames [
				styles.treeRoot
				styles.treeItem
			]},
				do =>
					if @props.showRoot
						div {className: styles.treeItemTitle}, 'Root'
				div {
					className: styles.treeItemContent
					style:
						paddingLeft: "#{(if @props.showRoot then 2 else 0) * 10}px"
				}, @renderLevel if @props.showRoot then 1 else 0


export Table = class TableSkeleton extends React.Component
	@propTypes: {
		...defaultPropTypes
		columns: PropTypes.number
		rows: PropTypes.number
		children: PropTypes.func
	}
	@defaultProps:
		level: 0
		animated: false
		columns: 6
		rows: 20
	render: ->
		table {className: styles.table},
			thead {},
				tr {},
					_.map [0...@props.columns], (index) =>
						th {
							key: index
						}, React.createElement Box,
							animated: @props.animated
							level: @props.level + 2
							style:
								width: "#{_.random 100, if index is 0 then 200 else 120}px"
			tbody {}, _.map [0...@props.rows], (rowIndex) =>
				tr {key: rowIndex}, _.map [0...@props.columns], (index) =>
					td {
						key: index
					}, React.createElement Box,
						# animated: @props.animated
						level: @props.level + (if index is 0 then 1 else 0)
						style: do ->
							if index is 0
								width: "#{_.random 100, 200}px"
								marginLeft: if rowIndex % 10 is 0 then 0 else '20px'
						,
							if @props.children? and _.isFunction @props.children
								@props.children {row: rowIndex, column: index}
