###
Section conflict resolution modal
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import moment from 'moment-mini'
# Renderable
import { div, p, ul, li, small, h1, span, img } from 'react-dom-factories'

Fragment = React.createFactory React.Fragment

import _Blanket from '@bevy/blanket'
Blanket = React.createFactory _Blanket

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

import _Button from '@bevy/button'
Button = React.createFactory _Button

import { ButtonGroup as _ButtonGroup } from '@atlaskit/button'
ButtonGroup = React.createFactory _ButtonGroup

import _FieldText from '@atlaskit/field-text'
FieldText = React.createFactory _FieldText
import _FieldTextArea from '@atlaskit/field-text-area'
FieldTextArea = React.createFactory _FieldTextArea

import _Form, {
	Field as _Field
	# FieldGroup as _FieldGroup
	FormHeader as _FormHeader
	FormSection as _FormSection
} from '@atlaskit/form'
Form = React.createFactory _Form
Field = React.createFactory _Field
# # FieldGroup = React.createFactory _FieldGroup
FormHeader = React.createFactory _FormHeader
FormSection = React.createFactory _FormSection


import _Modal, {
	ModalHeader as _ModalHeader
	ModalFooter as _ModalFooter
	ModalTitle as _ModalTitle
} from '@atlaskit/modal-dialog'
Modal = React.createFactory _Modal
ModalHeader = React.createFactory _ModalHeader
ModalFooter = React.createFactory _ModalFooter
ModalTitle = React.createFactory _ModalTitle

import { Query as _Query } from 'react-apollo'
Query = React.createFactory _Query

import _Select from '@atlaskit/select'
Select = React.createFactory _Select

import {
	DatePicker as _DatePicker
} from '@atlaskit/datetime-picker'
DatePicker = React.createFactory _DatePicker



# Styles
import styles from './index.styl'

import {
	x as close
} from 'react-icons-kit/feather'



export default class BIMIssueModal extends React.Component
	@propTypes:
		title: PropTypes.node
		onClose: PropTypes.func
		onSubmit: PropTypes.func
		data: PropTypes.shape(
			name: PropTypes.string
			description: PropTypes.string
		).isRequired
	constructor: (props) ->
		super props
		@state =
			# name: props.data.name
			comment: props.data.comment
			type: null
			company: null
			location: null
			dueDate: moment().add(7, 'days').toDate()
	submit: (event) =>
		@props.onSubmit @state
	render: ->
		selectStyle =
			menuPortal:
				(base) ->
					{
						...base
						zIndex: 9999
					}
		div {className: @props.className},
			Modal {
				width: 'x-large'
				shouldCloseOnEscapePress: false
				shouldCloseOnOverlayClick: false
				onClose: @props.onClose
				header: ({showKeyline}) =>
					div {},
						ModalHeader
							showKeyline: showKeyline
						,
							ModalTitle {className: styles.title}, @props.title
							Button
								spacing: 'compact'
								className: cnames [styles.closeIcon]
								iconBefore: Icon
									icon: close
									size: 12
									className: styles.icon
								onClick: @props.onClose
				footer: ({showKeyline, onClose}) =>
					ModalFooter
						showKeyline: showKeyline
						className: cnames [
							styles.content
							styles.footer
							if @state.step is 'edit' then styles.active
						]
					,
						ButtonGroup {},
								Button {
									appearance: 'primary'
									isDisabled: do =>
										_.some @state, (vl) -> !vl?
									className: styles.saveButton
									onClick: @submit
								}, 'Save'
			}, do =>
				div {className: styles.content},
					do =>
						if @props.loading
							div {className: styles.blanket}, Spinner {invertColor: true, size: 'large'}

					div {className: styles.observation},
						img {
							src: @props.data.photos[0].original
						}
					div {className: styles.form},
						Field
							label: 'Type'
							isRequired: true
							autoFocus: true
						,
							Select
								menuPortalTarget: document.body
								styles: selectStyle
								isSearchable: true
								isClearable: true
								options: _.orderBy @props.options.types, 'name'
								formatOptionLabel: (option) -> option.name
								getOptionLabel: (option) -> option.name
								getOptionValue: (option) -> option.id
								onChange: (value) =>
									@setState type: value
								value: @state.type
						Field
							label: 'Company'
							isRequired: true
						,
							Select
								menuPortalTarget: document.body
								styles: selectStyle
								isSearchable: true
								isClearable: true
								options: _.orderBy @props.options.companies, 'name'
								formatOptionLabel: (option) -> option.name
								getOptionLabel: (option) -> option.name
								getOptionValue: (option) -> option.id
								onChange: (value) =>
									@setState company: value
								value: @state.company
						Field
							label: 'Location'
							isRequired: true
						,
							Select
								menuPortalTarget: document.body
								styles: selectStyle
								isSearchable: true
								isClearable: true
								options: _.orderBy @props.options.locations, 'name'
								formatOptionLabel: (option) -> option.description
								getOptionLabel: (option) -> option.description
								getOptionValue: (option) -> option.id
								onChange: (value) =>
									@setState location: value
								value: @state.location

						Field
							label: 'Description'
							isRequired: true
						,
							FieldTextArea
								className: styles.formItem
								onChange: (e) => @setState comment: e.target.value
								value: @state.comment
								shouldFitContainer: true
						Field
							label: 'Due date'
							isRequired: true
						,
							DatePicker
								id: 'dueDate'
								onChange: (value) => @setState dueDate: value
								value: @state.dueDate
