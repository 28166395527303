"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;

/*
 Scopes regarding access to projects
*/
function _default() {
  var resolved = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  return {
    Project: [{
      subject: 'Project',
      actions: 'crud'
    }],
    ProjectCreate: [{
      subject: 'Project',
      actions: 'create'
    }],
    ProjectRead: [{
      subject: 'Project',
      actions: 'read'
    }],
    ProjectReadRevenue: [{
      subject: 'Project',
      actions: 'read',
      fields: 'Revenue'
    }],
    ProjectReadCosts: [{
      subject: 'Project',
      actions: 'read',
      fields: 'Costs'
    }],
    ProjectReadWhatIf: [{
      subject: 'Project',
      actions: 'read',
      fields: 'WhatIf'
    }],
    ProjectReadSafetyRD: [{
      subject: 'Project',
      actions: 'read',
      fields: 'Safety',
      conditions: {
        branch: 'RD'
      }
    }],
    ProjectReadSafetyCDE: [{
      subject: 'Project',
      actions: 'read',
      fields: 'Safety',
      conditions: {
        branch: 'CDE'
      }
    }],
    ProjectReadTQT: [{
      subject: 'Project',
      actions: 'read',
      fields: 'TQT'
    }],
    ProjectReadLegal: [{
      subject: 'Project',
      actions: 'read',
      fields: 'Legal'
    }],
    ProjectReadPersonel: [{
      subject: 'Project',
      actions: 'read',
      fields: 'Personel'
    }],
    ProjectUpdate: [{
      subject: 'Project',
      actions: 'update'
    }],
    ProjectDelete: [{
      subject: 'Project',
      actions: 'delete'
    }]
  };
}

;