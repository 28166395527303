export Project = '''
	id
	name
	slug
	city
	country
'''

export Visit = '''
	id
	recordType
	type
	startedAt
	endedAt
	duration
	name
	guide { id }
	members { id }
	project { id }
'''
export Observation = '''
	id
	state
	type
	createdAt
	photos {
		original
	}
	comments {
		id
	}
	categories {
		name
		type
	}
'''
