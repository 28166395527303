###
Platform abstract list (stripes) layout with pagination
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
# Renderable
import { div } from 'react-dom-factories'

Fragment = React.createFactory React.Fragment

import _Breadcrumbs, {BreadcrumbsItem as _BreadcrumbsItem} from '@atlaskit/breadcrumbs'
Breadcrumbs = React.createFactory _Breadcrumbs
BreadcrumbsItem = React.createFactory _BreadcrumbsItem

import _PageHeader from '@atlaskit/page-header'
PageHeader = React.createFactory _PageHeader

import _DynamicTable from '@atlaskit/dynamic-table'
DynamicTable = React.createFactory _DynamicTable

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _Button from '@bevy/button'
Button = React.createFactory _Button

import _ContentBar from '@bevy/content-bar'
ContentBar = React.createFactory _ContentBar

import _LayoutGrid from '@bevy/layout-grid'
LayoutGrid = React.createFactory _LayoutGrid

# Styles
import styles from './index.styl'
import {
	grid
	list
} from 'react-icons-kit/feather'


export default class LayoutList extends React.Component
	@propTypes:
		actions: PropTypes.element
		search: _ContentBar.propTypes.search
		filters: _ContentBar.propTypes.filters
		breadcrumbs: PropTypes.arrayOf PropTypes.shape
			hasSeparator: PropTypes.bool
			href: PropTypes.string
			iconBefore: PropTypes.node
			iconAfter: PropTypes.node
			onClick: PropTypes.func
			text: PropTypes.string.isRequired
			truncationWidth: PropTypes.number
			target: PropTypes.oneOf ['_blank' | '_parent' | '_self' | '_top' | '']
			component: PropTypes.element
		title: PropTypes.oneOfType([
			PropTypes.string
			PropTypes.element
		]).isRequired
		table: PropTypes.shape
			rowsPerPage: PropTypes.number
			defaultPage: PropTypes.number
		cards: PropTypes.shape _LayoutGrid.propTypes
		summary: PropTypes.element
	@defaultProps:
		modesIcons:
			table: list
			cards: grid
	constructor: (props) ->
		super props
		@availableModes = _.compact [
			if props.table? then 'table'
			if props.cards? then 'cards'
		]
		@state =
			mode: _.first @availableModes
	render: ->
		div {
			className: styles.contentExplorer
			..._.pickBy @props, (val, key) -> /^data-/.test key
		},
			PageHeader
				actions: @props.actions
				breadcrumbs: if @props.breadcrumbs?
					Breadcrumbs {},
						do =>
							_.map @props.breadcrumbs, (item) ->
								BreadcrumbsItem {
									key: item.text
									...item
									}
				bottomBar: if !@props.search? and !@props.filters? and @availableModes.length <= 1
					null
				else
					div {},
						do => if @props.search? or @props.filters?
							ContentBar
								search: @props.search
								filters: @props.filters
						do =>
							if @availableModes.length > 1
								Button
									className: styles.modeButton
									onClick: =>
										idx = _.indexOf @availableModes, @state.mode
										@setState mode: @availableModes[(idx + 1) % @availableModes.length]
								,
									Icon size: 16, icon: @props.modesIcons[@state.mode]
				disableTitleStyles: !_.isString @props.title
			, @props.title
			do => if @state.mode is 'table'
				DynamicTable {
					rowsPerPage: 20
					defaultPage: 1
					onSort: () -> 'sort'
					...@props.table
				}

			do => if @state.mode is 'cards'
				LayoutGrid {...@props.cards}
			do => if @props.summary
				div {className: styles.summary},
					@props.summary
