export default '''

	type TenantMix implements FileInterface & EditableMetainfo & BaselineFileInterface{
		# UID of tenant mix
		id: ID!
		# Short name created by the user
		name: String!
		# Short document summary & FileInterface
		description: String!
		comments: [Comment!]
		annotations: [Annotation!]
		state: DocumentState!
		# Meta info
		createdAt: Int!
		createdBy: Actor
		modifiedAt: Int!
		modifiedBy: Actor

		isBaseline: Boolean!

		# Active leasing offers
		activeOffers: [LeasingOffer!]
		# Attached only leasing offers
		attachedOffers: [LeasingOffer!]
		# Free space is not modeled vacants
		freeSpaces: [LeasingOfferSpace!]!
		# Defined vacants
		definedVacants: [LeasingOfferSpace!]!
		# Manualy inputed overrides
		overrides: [LeasingOfferSpaceOverride!]!

		# Signed NLA (sqm) in tenant mix
		signedNLA: Float!

		# Signed GLA (sqm) in tenant mix
		signedGLA: Float!

		# Offers grouped by opportunities
		attachedOpportunities: [Opportunity!]
		# List of snapshots
		snapshots: [TenantMix!]
		# TenantMixSnapshot has a root in another TenantMix
		rootTenantMix: TenantMix
		inline: Boolean!

		context: ProjectContext!
	}
'''
