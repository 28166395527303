export default '''
	enum DocumentState {
		Draft
		Commited
	}

	# mimeType?
	enum FileType {
		Image
		PDF
		Excel
	}
'''
