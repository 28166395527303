import gql from 'graphql-tag'
import _ from 'lodash'
import objectid from 'bson-objectid'
import moment from 'moment-mini'

import Account from './Account'
import Building from './Building'
import Document from './Document'
import DocumentEntity from './DocumentEntity'
import Floor from './Building/Floor'
import FloorSection from './Building/FloorSection'
import CostVariant from './CostVariant'
import CostLine from './CostLine'
import CostItem from './CostItem'
import CommercialSpaceDates from './CommercialSpaceDates'
import FloorVolume from './FloorVolume'
import LeasingOffer from './LeasingOffer'
import LeasingOfferSpace from './LeasingOfferSpace'
import Mutation from './Mutation'
import Opportunity from './Opportunity'
import Person from './Person'
import Project from './Project'
import ProjectBaseline from './ProjectBaseline'
import ProjectTimeline from './ProjectTimeline'
import Query from './Query'
import TenantMix from './TenantMix'
import Scenario from './Scenario'
import DivestmentOption from './DivestmentOption'
import LeasingOfferResult from './LeasingOfferResult'
import Establishment from './Establishment'
import ProjectContext from './ProjectContext'

DecreesService = ({decrees, projects, codes, contexts}) ->
	_.memoize (projectID) ->
		project = projects.findOne id: projectID
		context = contexts.findOne id: project.contextID
		startDate = moment.unix(context.timeline.landAcquisition).startOf 'month'
		endDate = moment.unix(context.timeline.closing).endOf 'month'


		decrees.mapReduce (invoice) ->
			inv = _.pick invoice, [
				'codeID'
				'date'
				'value'
			]
			{
				...inv
				month: _.ceil moment.unix(inv.date).startOf('month').diff startDate, 'months', true
			}
		, (invoices) ->
			_.reduce invoices, (acc, invoice) ->
				acc[invoice.codeID][invoice.month] += invoice.value
				acc
			,
				_.reduce codes.find(), (acc, code) ->
					acc[code.id] = Array(_.ceil(endDate.diff startDate, 'months', true)).fill 0
					acc
				, {}

CacheService = (db, drop = false) ->
	cache = db.getCollection '__CACHE__'
	if !cache?
		cache = db.addCollection '__CACHE__', unique: ['key']
	if drop is true
		cache.clear(removeIndices: true)
	# General cache methods
	value: (key, ...relatedKeys, func) ->
		relatedKeys = _.compact _.flatten relatedKeys

		value = cache.findOne {key}
		# If value is in the cache return value
		if value?
			value.value
		else
			# If value is not in the cache perform calculations func and store result in the cache
			value = func()
			cache.insert {
				key
				value: value
				relatedKeys
			}
			value
	invalidate: (key) ->
		relatedKeys = [key]
		while !_.isEmpty relatedKeys
			# Invalidate cache for all keys in current scope
			cache.chain().find(key: $in: relatedKeys).remove()
			# Walk up in DAG to clear all related keys
			related = cache.find relatedKeys: $containsAny: relatedKeys
			relatedKeys = _.compact _.flatten _.map related, 'key'

export default (db) ->
	collections =
		projects: db.getCollection 'projects'
		# accounts: db.getCollection 'accounts'
		offers: db.getCollection 'offers'
		users: db.getCollection 'users'
		companies: db.getCollection 'companies'
		opportunities: db.getCollection 'opportunities'
		tenantMixes: db.getCollection 'tenantMixes'
		opportunitiesStages: db.getCollection 'opportunitiesStages'
		costsVariants: db.getCollection 'costsVariants'
		costsLines: db.getCollection 'costsLines'
		costsItems: db.getCollection 'costsItems'
		codes: db.getCollection 'codes'
		invoices: db.getCollection 'invoices'
		decrees: db.getCollection 'decrees'
		invoiceItems: db.getCollection 'invoiceItems'
		documents: db.getCollection 'documents'
		scenarios: db.getCollection 'scenarios'
		divestments: db.getCollection 'divestments'
		services: db.getCollection 'services'
		documentsTopics: db.getCollection 'documentsTopics'
		contexts: db.getCollection 'contexts'
	indices = db.__indices__
	service = DecreesService(collections)
	service collections.projects.findOne(name: 'Green 2 Day').id
	services =
		decreesService: service
		cache: CacheService db

	# Avoiding cold start


	DocumentEntity: DocumentEntity collections, services, indices
	Document: Document collections, services, indices
	Opportunity: Opportunity collections, services, indices
	Account: Account collections, services, indices
	Building: Building collections, services, indices
	Floor: Floor collections, services, indices
	FloorSection: FloorSection collections, services, indices
	CostVariant: CostVariant collections, services, indices
	CostLine: CostLine collections, services, indices
	LeasingOffer: LeasingOffer collections, services, indices
	LeasingOfferSpace: LeasingOfferSpace collections, services, indices
	FloorVolume: FloorVolume collections, services, indices
	CommercialSpaceDates: CommercialSpaceDates collections, services, indices
	Project: Project collections, services, indices
	ProjectBaseline: ProjectBaseline collections, services, indices
	ProjectContext: ProjectContext collections, services, indices
	TenantMix: TenantMix collections, services, indices
	ProjectTimeline: ProjectTimeline collections, services, indices
	Person: Person collections, services, indices
	Query: Query collections, services, indices
	Mutation: Mutation collections, services, indices
	Scenario: Scenario collections, services, indices
	DivestmentOption: DivestmentOption collections, services, indices
	LeasingOfferResult: LeasingOfferResult collections, services, indices
	Establishment: Establishment collections, services, indices
