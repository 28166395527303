"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;

var _core = _interopRequireDefault(require("lodash/core"));

var _slugify = _interopRequireDefault(require("slugify"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/*
 System-defined user roles on project level
*/
var partialRoles;
partialRoles = [{
  name: 'Legal Paths',
  key: 'legal'
}, {
  name: 'Controlling Paths',
  key: 'controlling'
}, {
  name: 'Accounting Paths',
  key: 'accounting'
}, {
  name: 'Taxes Paths',
  key: 'taxes'
}, {
  name: 'Facility Paths',
  key: 'facility'
}, {
  name: 'Marketing Paths',
  key: 'marketing'
}, {
  name: 'TQT Paths',
  key: 'tqt'
}, {
  name: 'Leasing Paths',
  key: 'leasing'
}, {
  name: 'Asset Management Paths',
  key: 'assetManagement'
}, {
  name: 'Skanska SA Paths',
  key: 'skanskaSA'
}, {
  name: 'Architects Paths',
  key: 'architects',
  scopes: ['LegalDocumentExternal']
}, {
  name: 'Consultant Paths',
  key: 'consultant'
}];

function _default(user, paths) {
  var resolved = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var full, partial;
  full = [{
    name: 'Skanska CDE Paths'
  }];
  partial = _core.default.map(partialRoles, function (role) {
    var idSet;
    idSet = _core.default.map(_core.default.filter(paths, function (path) {
      return path.permissions[role.key];
    }), '_id');
    return {
      name: role.name,
      conditions: {
        pathID: {
          $in: idSet
        }
      }
    };
  });
  return _core.default.map(_core.default.flatten([full, partial]), function (item) {
    return _objectSpread({}, item, {
      slug: (0, _slugify.default)(item.name),
      scopes: item.scopes == null ? ['LegalDocumentReadCommited'] : _core.default.concat(item.scopes, ['LegalDocumentReadCommited'])
    });
  });
}

;