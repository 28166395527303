import _ from 'lodash'
import {
	getSignedSpaces
	getProjectBaselineContext
	expandContext
	trimContext
	calculateFullResults
} from 'data/helpers'


export default (collections) ->
	{
		projects
		offers
		tenantMixes
		contexts
		costsVariants
		divestments
	} = collections

	# Check type of this context
	type: (obj, args, context, info) ->
		# Baseline
		# WhatIfContext
		project = projects.findOne id: obj.projectID
		context = collections.contexts.findOne id: project.contextID
		if obj.id is context.id # Directly baseline context
			'Baseline'
		else
			# Simple comparison
			simpleCompareProperties = [
				'timeline'
				'finance'
				'freeSpaceDefinitions'
				'building'
			]

			advanceCompareProperties = [
				'costsVariantID'
				'tenantMixID'
				'divestmentID'
			]

			current = {
				...context
				...obj
			}

			if !_.isEqual _.pick(current, simpleCompareProperties), _.pick(context, simpleCompareProperties)
				'WhatIfContext'
			else
				# One of advance properties can be different
				differences = _.sumBy advanceCompareProperties, (prop) -> current[prop] isnt context[prop]
				if differences < 2
					'Baseline'
				else
					# We can compare deeper on variants level
					'WhatIfContext'

	###
	Baseline tenant mix is composition of all signed spaces
	###
	result: (obj, args, context, info) ->
		# TODO: Skip base context from project if this context is the same as base and when we have all the data

		project = projects.findOne id: obj.projectID
		context = null
		if project.contextID isnt obj.id
			baseContext = collections.contexts.findOne id: project.contextID
			context = {
				...baseContext
				..._.omitBy obj, (value, key) -> !value?
			}
		else
			context = {...obj}

		context = expandContext {collections, context}
		calculateFullResults {
			...trimContext context
			collections
		}
	tenantMix: (obj, args, context, info) ->
		if obj.tenantMixID?
			tenantMixes.findOne id: obj.tenantMixID
		else
			project = projects.findOne id: obj.projectID
			context = collections.contexts.findOne id: project.contextID
			tenantMixes.findOne id: context.tenantMixID

	costsTable: (obj, args, context, info) ->
		if obj.costsVariantID?
			costsVariants.findOne id: obj.costsVariantID
		else
			project = projects.findOne id: obj.projectID
			context = collections.contexts.findOne id: project.contextID
			costsVariants.findOne id: context.costsVariantID

	divestment: (obj, args, context, info) ->
		if obj.divestmentID?
			divestments.findOne id: obj.divestmentID
		else
			project = projects.findOne id: obj.projectID
			context = collections.contexts.findOne id: project.contextID
			divestments.findOne id: context.divestmentID
	building: (obj, args, context, info) ->
		if obj.building?
			obj.building
		else
			project = projects.findOne id: obj.projectID
			context = collections.contexts.findOne id: project.contextID
			context.building
	timeline: (obj, args, context, info) ->
		if obj.timeline?
			obj.timeline
		else
			project = projects.findOne id: obj.projectID
			context = collections.contexts.findOne id: project.contextID
			context.timeline

