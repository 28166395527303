export default '''

	type Document implements FileInterface & EditableMetainfo {
		id: ID!
		# Unix timstamp of document's creation time
		createdAt: Int!
		# Original reporter of the document
		createdBy: Actor!
		# Unix timestamp of last modification time
		modifiedAt: Int!
		# Last entity to modify the document
		modifiedBy: Actor!

		# Document name (Depending on status either contractor's name or document topic)
		name: String!
		# Provided document description
		description: String!
		# Comments about the document
		comments: [Comment!]
		# Annotations to the document
		annotations: [Annotation!]
		# Current state of the document
		state: DocumentState!

		# Skanska company to which this document belongs
		company: Organization!
		# External company or office which serves as source or destination for this document
		contractor: Organization!

		# Type of document (based on structure & character of the document)
		type: DocumentType
		# Path to topic of document (based on context of the document)
		topic: [DocumentTopic!]

		# Important dates appearing on the document
		dates: [DocumentDate!]
		# Identifications numbers of given document in external systems
		identifiers: [DocumentIdentifier!]
		# Entities appearing on the document
		entities: [DocumentEntity!]

		# Person responsible for the document at the moment
		asignee: DocumentAsignee
		# Active transfer of the document (personal or by delivery service)
		transfer: DocumentTransfer
		# Digital version of related document
		scan: File
		# Location of physical version of the document
		location: DocumentLocation
		# Current document's status in VDR
		vdrStatus: DocumentVDRStatus

		# Status of the document implied by its filled properties
		status: DocumentStatus!
		# History of important changes to the document
		history: [DocumentAction!]!
	}
	enum DocumentType {
		Annex
		Application
		Contract
		Decision
		Excerpt
		NotarialAct
		Notification
		PowerOfAttorney
		Resolution
	}
	type DocumentTopic {
		# 2-letter code of the topic node
		code: String!
		# Human readable name for the topic node
		name: String!
	}

	type DocumentDate {
		date: Int!
		type: DocumentDateType!
	}
	enum DocumentDateType {
		ReceivalDate
		CreationDate
		ValidationDate
		ExpirationDate
	}

	type DocumentIdentifier {
		# identifier code
		code: String!
		type: DocumentIdentifierType!
	}
	enum DocumentIdentifierType {
		NotarialReview
		CaseNumber
		DecisionNumber
		LandRegisterNumber
		AssetIdentificationNumber
		PrecintNumber
		ParcelNumber
	}

	type DocumentEntity {
		entity: DocumentEntityUnion!
		role: DocumentEntityRole!
	}
	union DocumentEntityUnion = Person | Organization
	enum DocumentEntityType {
		Person
		Organization
	}
	enum DocumentEntityRole {
		Participant
		Buyer
		Seller
		Plenipotentiary
		Sender
		Recipient
		Office
	}

	union DocumentAsignee = Person | Service
	type DocumentTransfer {
		# Entity handing over the document
		source: DocumentAsignee!
		# Entity receiving the document
		target: DocumentAsignee!
		# Delilvery details
		delivery: CourierDelivery
	}

	enum DocumentStatus {
		BasicInfo
		Categorized
		Filled
		Reviewed
		Archived
	}

	type DocumentAction {
		# Time of performing the action
		timestamp: Int!
		# Author / trigger responsible for the action
		author: DocumentAsignee!
		# Custom action description
		description: String
		# Detailed action info
		action: DocumentActionUnion
	}

	union DocumentActionUnion = DocumentTransfer | DocumentScan | DocumentReassign | DocumentBasicEdit | DocumentCategoryEdit | DocumentDetailEdit
	type DocumentBasicEdit {
		company: Organization!
		contractor: Organization!
	}
	type DocumentCategoryEdit {
		type: DocumentType
		topic: [DocumentTopic!]
	}
	type DocumentDetailEdit {
		dates: [DocumentDate!]
		identifiers: [DocumentIdentifier!]
		entities: [DocumentEntity!]
	}
	type DocumentScan {
		scan: File
	}
	type DocumentReassign {
		asignee: DocumentAsignee
	}
	enum DocumentLocation {
		HQ
		Construction
	}
	enum DocumentVDRStatus {
		Pending
		Included
		Excluded
	}

	input DocumentFilter {
		# ID of company that holds the document
		companies: [String]
		# ID of contractor involved with the document
		contractors: [String]

		# Formal type of the document
		types: [String]
		# Topic path for ancestor of the document
		topicIDs: [String!]

		# Current VDRstatus of the document
		vdrStatuses: [DocumentVDRStatus]

		# ID of current document asignee
		assignees: [String]
		# Current status of the document
		statuses: [String]

		# whether document has scan or not
		hasScan: Boolean
		# whether document is in transfer or not
		inTransfer: Boolean
	}

	type DocumentsStats {
		# number of all documents
		all: Int!
		# documents per topic
		topics: [DocumentTopicsStats!]!
	}
	type DocumentTopicsStats {
		topic: [DocumentTopic!]!
		# number of all documents in the
		all: Int!
		# documents per type
		type: Int!
	}

	# type DocumentSet implements FileInterface & EditableMetainfo {
	# 	id: ID!
	# 	# Unix timstamp of document's creation time
	# 	createdAt: Int!
	# 	# Original reporter of the document
	# 	createdBy: Actor!
	# 	# Unix timestamp of last modification time
	# 	modifiedAt: Int!
	# 	# Last entity to modify the document
	# 	modifiedBy: Actor!

	# 	# Document name
	# 	name: String!
	# 	# Provided document description
	# 	description: String!
	# 	# Comments about the document
	# 	comments: [Comment!]
	# 	# Annotations to the document
	# 	annotations: [Annotation!]
	# 	# Current state of the document
	# 	state: DocumentState!

	# 	# Connected documents
	# 	documents: [Document!]!
	# 	# Person responsible for the document at the moment
	# 	asignee: DocumentAsignee
	# }

	# type VDRSnapshot implements EditableMetainfo {
	# 	id: ID!
	# 	createdAt: Int!
	# 	# Original reporter of the document
	# 	createdBy: Actor!
	# 	# Unix timestamp of last modification time
	# 	modifiedAt: Int!
	# 	# Last entity to modify the document set
	# 	modifiedBy: Actor!

	# 	# Documents explicitly excluded from vdr
	# 	excluded: [Document!]!
	# 	# Documents explicitly included in vdr
	# 	included: [Document!]!
	# 	# Documents waiting for include/exclude decision
	# 	pending: [Document!]!
	# }
'''
