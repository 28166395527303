###
Revenue HUB for project
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import Loadable from 'react-loadable'
# Renderable
import { div, p, ul, li, small, h1, h2, h3, span } from 'react-dom-factories'

import _RouterTabs from '@bevy/router-tabs'
RouterTabs = React.createFactory _RouterTabs

import { Box as _SkeletonBox } from '@bevy/skeleton'
SkeletonBox = React.createFactory _SkeletonBox

import { Query as _Query } from 'react-apollo'
Query = React.createFactory _Query

import {
	Route as _Route
	Switch as _Switch
	Link as _Link
} from 'react-router-dom'
Route = React.createFactory _Route
Switch = React.createFactory _Switch
Link = React.createFactory _Link

import _ApplicationHeader from 'components/ApplicationHeader'
ApplicationHeader = React.createFactory _ApplicationHeader

# Views
OverviewView = Loadable
	loader: -> `import(/* webpackChunkName: "scenarios-overview-view" */'../ProjectScenariosOverview')`
	loading: -> div {}

ListView = Loadable
	loader: -> `import(/* webpackChunkName: "scenarios-list-view" */'../ProjectScenariosList')`
	loading: -> div {}

DivestmentListView = Loadable
	loader: -> `import(/* webpackChunkName: "scenarios-divestment-list-view" */'../ProjectScenariosDivestments')`
	loading: -> div {}

CompareView = Loadable
	loader: -> `import(/* webpackChunkName: "scenarios-compare-view" */'../ProjectScenariosCompare')`
	loading: -> div {}

EditView = Loadable
	loader: -> `import(/* webpackChunkName: "scenarios-edit-view" */'../ProjectScenariosEdit')`
	loading: -> div {}



# Data
import { RootQuery } from './data'
# Styles
import styles from './index.styl'


export default class DMSView extends React.Component
	render: ->
		div {
			className: cnames [
				@props.className
				styles.page
			]
		},
			Query
				query: RootQuery
				variables:
					projectSlug: @props.match.params.project
			, ({error, loading, data}) =>
				RouterTabs
					className: styles.tabs
					location: @props.location
					header: ApplicationHeader {
						loading: loading
						project: if !loading then data.project.name
						application: 'What-if'
					}
					tabs: [
							label: 'Overview'
							content: div {className: styles.tabContent},
								React.createElement OverviewView, @props
							href: "/projects/#{@props.match.params.project}/scenarios"
							exact: true
							id: 1
							redirect: "/projects/#{@props.match.params.project}/scenarios/list"
						,
							label: 'List'
							content: div {className: styles.tabContent},
								# React.createElement ListView, @props
								Switch {},
									Route
										path: '/projects/:project/scenarios/:scenario/edit'
										exact: false
										component: EditView
									Route
										path: '/projects/:project/scenarios'
										exact: false
										component: ListView
							href: "/projects/#{@props.match.params.project}/scenarios/list"
							catchAll: true
							exact: false
							id: 2
						,
							label: 'Divestments'
							content: div {className: styles.tabContent},
								React.createElement DivestmentListView, @props
							href: "/projects/#{@props.match.params.project}/scenarios/divestments"
							exact: false
							id: 4
						# ,
						# 	label: 'Compare'
						# 	content: div {className: styles.tabContent},
						# 		React.createElement CompareView, @props
						# 	href: "/projects/#{@props.match.params.project}/scenarios/compare"
						# 	exact: false
						# 	id: 3
					]
