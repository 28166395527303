import _ from 'lodash'
import React from 'react'


context = React.createContext()
RefetchProviderFactory = React.createFactory context.Provider
export RefetchConsumer = React.createFactory context.Consumer

export default class RefetchProvider extends React.Component
	constructor: (props) ->
		super props
		@state =
			refetchList: {}

	handleRefetch: (item) =>
		if !_.has @state.refetchList, Object.keys(item)[0]
			state = _.clone @state.refetchList
			# console.log 'stary state', state
			# console.log 'nowy item', item
			@setState refetchList: _.assign state, item

	render: ->
		RefetchProviderFactory
			value:
				addRefetch: @handleRefetch
				refetchList: @state.refetchList
		,
			@props.children
