###
Legal HUB for project
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import Loadable from 'react-loadable'
# Renderable
import { div, p, span, a } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import {
	Route as _Route
	Switch as _Switch
	Redirect as _Redirect
} from 'react-router-dom'
Route = React.createFactory _Route
Switch = React.createFactory _Switch
Redirect = React.createFactory _Redirect

import _SideNavigation from 'components/SideNavigation'
SideNavigation = React.createFactory _SideNavigation

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import { Query as _Query } from 'react-apollo'
Query = React.createFactory _Query

import _RefetchProvider from 'libs/refetch'
RefetchProvider = React.createFactory _RefetchProvider

import _SideNavFiltersProvider, { SideNavFiltersConsumer } from 'libs/filtersDMS'
SideNavFiltersProvider = React.createFactory _SideNavFiltersProvider

# Views
InboxView = Loadable
	loader: -> `import(/* webpackChunkName: "legal-inbox-view" */'../ProjectLegalInbox')`
	loading: -> div {}
RegistryView = Loadable
	loader: -> `import(/* webpackChunkName: "legal-registry-view" */'../ProjectLegalRegistry')`
	loading: -> div {}
DocumentView = Loadable
	loader: -> `import(/* webpackChunkName: "legal-document-view" */'../ProjectLegalDocument')`
	loading: -> div {}

# Sidenav
InboxSideNav = Loadable
	loader: -> `import(/* webpackChunkName: "legal-inbox-sidenav" */'../ProjectLegalInbox/accessory')`
	loading: -> div {}
registrySideNav = Loadable
	loader: -> `import(/* webpackChunkName: "legal-registry-sidenav" */'../ProjectLegalRegistry/accessory')`
	loading: -> div {}

# Data
import { RootQuery } from './data'
import trLegal from 'data/local/legal'
import {INBOX_PATH, REGISTRY_PATH} from 'data/paths'
# Styles
import styles from './index.styl'
import {
	refreshCw
	inbox
	archive
	alertTriangle
} from 'react-icons-kit/feather'

export default class DMSView extends React.Component
	constructor: (props) ->
		super props

	renderFooter: (data) =>
		stage = if __STAGE__? then __STAGE__ else 'local'
		domain = switch stage
			when 'local' then 'localhost'
			when 'development' then 'bevy.biz'
			when 'test' then 'mail.360-test.skanska.pl'
			when 'staging' then 'mail.360-staging.skanska.pl'
			when 'production' then 'mail.360.skanska.pl'
		address = "#{_.toLower data.project.code}.dms@#{domain}"
		a {className: styles.sidebarFooter, href: "mailto:#{address}"},
			div {}, _.toUpper 'Email scans to:'
			address

	render: ->
		div {
			className: cnames [
				@props.className
				styles.page
			]
		},
			Query
				query: RootQuery
				variables:
					projectSlug: @props.match.params.project
					inboxFilters: {hasApproval: false}
					registryFilters: {hasApproval: true}
			, ({error, loading, data}) =>
				if loading
					null
				else
					SideNavFiltersProvider {},
						SideNavFiltersConsumer {}, ({setActiveFolder, setActivePaths}) =>
							SideNavigation
								hub: trLegal.global.module
								hubIcon: require 'assets/legal-icon.png'
								hubClassName: styles.color
								linkClassName: styles.textColor
								project: if !loading then data.project.name
								interlaceAccessory: false
								applications: [
									label: trLegal.global.inbox
									link: "/projects/#{@props.match.params.project}/legal/#{INBOX_PATH}"
									icon: Icon { icon: inbox, size: 10}
									counter: data.project.inboxCount
									onClick: () ->
										setActiveFolder {}
										setActivePaths {}
								,
									label: trLegal.global.registry
									link: "/projects/#{@props.match.params.project}/legal/#{REGISTRY_PATH}"
									icon: Icon { icon: archive, size: 10}
									counter: data.project.registryCount
									onClick: () ->
										setActiveFolder {}
										setActivePaths {}
								]
								footer: @renderFooter data
								accessoryNavigation:
									Switch {},
										Route
											path: "/projects/:project/legal/#{INBOX_PATH}"
											component: InboxSideNav
										Route
											path: "/projects/:project/legal/#{REGISTRY_PATH}"
											component: registrySideNav
							,
								Switch {},
									Route
										exact: true
										path: "/projects/#{@props.match.params.project}/legal"
										render: (props) => Redirect to: "/projects/#{@props.match.params.project}/legal/#{INBOX_PATH}"
									Route
										path: "/projects/:project/legal/#{INBOX_PATH}"
										exact: true
										component: InboxView
									Route
										path: "/projects/:project/legal/#{REGISTRY_PATH}"
										exact: true
										component: RegistryView
									Route
										path: '/projects/:project/legal/:app/:documentID'
										exact: false
										component: DocumentView

