import _ from 'lodash'
import React from 'react'

context = React.createContext()
SideNavFiltersProviderFactory = React.createFactory context.Provider
export SideNavFiltersConsumer = React.createFactory context.Consumer

export default class SideNavFiltersProvider extends React.Component
	constructor: (props) ->
		super props
		@state =
			foldersListName: ''
			foldersList: []
			activeFolder: null
			activePaths: null
			contentParams: null

	handleFoldersList: (listName, items) =>
		if (_.isEmpty @state.foldersList) or (listName isnt @state.foldersListName)
			@setState
				foldersList: items
				foldersListName: listName

	handleActiveFolder: (item) =>
		if (_.isEmpty @state.activeFolder) or (@state.activeFolder isnt item)
			if _.isEmpty @state.activeFolder
				@setState
					activeFolder: item
			else
				@setState
					activeFolder: item
					contentParams: null
				@props.history.push
					search: "?folder=#{item.slug}"

	handleActivePaths: (paths) =>
		if (_.isEmpty @state.activePaths) or (@state.activePaths isnt paths)
			@setState activePaths: paths

	handleSetContentParams: (params) =>
		@setState contentParams: params
		@props.history.push
			search: "?#{params.querystring}"

	render: ->
		SideNavFiltersProviderFactory
			value:
				foldersList: @state.foldersList
				activeFolder: @state.activeFolder
				activePaths: @state.activePaths
				contentParams: @state.contentParams
				addFoldersList: @handleFoldersList
				setActiveFolder: @handleActiveFolder
				setActivePaths: @handleActivePaths
				setContentParams: @handleSetContentParams
		,
			@props.children
