"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;

/*
 Scopes regarding access to users
*/
function _default() {
  var resolved = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  return {
    User: [{
      subject: 'User',
      actions: 'crud'
    }],
    UserCreate: [{
      subject: 'User',
      actions: 'create'
    }],
    UserRead: [{
      subject: 'User',
      actions: 'read'
    }],
    UserDelete: [{
      subject: 'User',
      actions: 'delete'
    }],
    UserLogAs: [{
      subject: 'User',
      actions: 'logAs'
    }]
  };
}

;