import Enums from './enums'
import Mutation from './mutations/index.graphql'
import Query from './queries/index.graphql'
import Types from './types'

export default """
	#{Enums}

	#{Types}

	#{Query}

	#{Mutation}

	schema {
		query: Query
		mutation: Mutation
	}
"""
