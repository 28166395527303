import _ from 'lodash'
import React from 'react'
import { adopt } from 'react-adopt'
import { Query as _Query, Mutation as _Mutation } from 'react-apollo'
Query = React.createFactory _Query
Mutation = React.createFactory _Mutation

wrapMutation = (mutationParams) ->
	(props) ->
		mutationData = mutationParams
		if _.isFunction mutationParams
			mutationData = mutationParams _.omit props, 'render'
		Mutation mutationData, (mutation, result) ->
			props.render {mutation, result}
wrapQuery = (queryParams) ->
	(props) ->
		queryData = queryParams
		if _.isFunction queryParams
			queryData = queryParams _.omit props, 'render'
		Query queryData, props.render

export default ({mutations, queries, props}, callback) ->
	config = {
		..._.reduce mutations, (acc, mutation, key) ->
			acc[key] = wrapMutation mutation
			acc
		, {}
		..._.reduce queries, (acc = {}, query, key) ->
			acc[key] = wrapQuery query
			acc
		, {}
	}
	if _.isEmpty config
		(children) -> children {}
	else
		React.createFactory adopt(config)
