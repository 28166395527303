export default '''
	type OpportunityStage {
		id: OpportunityStageID!
		name: String!
		probability: Float!
		category: String!
		type: String!
	}
	type Opportunity implements FileInterface & EditableMetainfo {
		id: ID!
		name: String!
		account: Account!
		project: Project
		stage: OpportunityStage!
		offers(filter: LeasingOfferFilters): [LeasingOffer!]
		numberOfOffers(filter: LeasingOfferFilters): Int!
		poc: Person
		agent: Person
		closeDate: Int
		# Average values of leasing offers
		averageNOI: Float
		averageGLA: Float
		# Dominants
		dominantAverageRent: Float
		dominantSpaceType: FloorSectionType
		dominantSpaceVolume: Float

		# Short document summary & FileInterface
		description: String!
		comments: [Comment!]
		annotations: [Annotation!]
		state: DocumentState!

		# Meta info
		createdAt: Int!
		createdBy: Actor
		modifiedAt: Int!
		modifiedBy: Actor
	}
'''
