###
Tooltip component
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'

# Renderable
import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

# Styles
import styles from './index.styl'

export default class Tooltip extends React.Component
	@propTypes:
		content: PropTypes.node

	@defaultProps:
		showTimeout: 800
		hideTimeout: 3000

	constructor: (props) ->
		super props
		@target = React.createRef()
		@state =
			isMouseOver: false
			isVisible: false

	showTooltip: =>
		targetRect = @target.current.getBoundingClientRect()

		# set horizontal position
		if targetRect.left < 100
			left = targetRect.left
			alignCenter = false
		else if window.innerWidth - targetRect.right < 100
			right = window.innerWidth - targetRect.right
			alignCenter = false
		else
			left = targetRect.left + (targetRect.width / 2)
			alignCenter = true

		# set vertical position
		top =
			if targetRect.top < 100 then targetRect.bottom
			else targetRect.bottom

		@setState
			isMouseOver: true
			top: top
			left: left
			right: right
			alignCenter: alignCenter
		if @hideTimer?
			clearTimeout @hideTimer
		@showTimer = setTimeout =>
			if @state.isMouseOver then @setState isVisible: true
		, @props.showTimeout
		@hideTimer = setTimeout =>
			if @state.isMouseOver then @setState isVisible: false
		, @props.hideTimeout

	hideTooltip: => @setState isMouseOver: false, isVisible: false

	componentDidMount: () =>
		if @target.current?
			@target.current.addEventListener('mouseenter', @showTooltip)
			@target.current.addEventListener('mouseleave', @hideTooltip)

	componentWillUnmount: () =>
		if @target.current?
			@target.current.removeEventListener('mouseenter', @showTooltip)
			@target.current.removeEventListener('mouseleave', @hideTooltip)


	render: ->
		if @props.content?
			Fragment {},
				div {ref: @target, className: @props.className},
					@props.children
				if @state.isMouseOver
					do =>
						targetRect = @target.current.getBoundingClientRect()
						tooltip =
							div {
								className: cnames [
									styles.tooltip
									if @state.isVisible then styles.visible
									if @state.alignCenter then styles.alignCenter
								]
								style:
									top: @state.top
									left: @state.left
									right: @state.right
							}, @props.content
						ReactDOM.createPortal(tooltip, document.body)
		else
			@props.children




