import gql from 'graphql-tag'
import {
	Observation
} from './query.graphql'


export ObservationQuery = gql """
	query($id: ID!){
		ehsObservation(id: $id) {
			#{Observation}
		}
	}
"""


export getBimCompanies = gql """
query {
	bimCompanies {
		id
		name
	}
	}
"""

export getBimLocations = gql """
query {
	bimLocations {
		id
		path
		level
		description
	}
	}
"""


export Remove = gql '''
	mutation ehsObservationRemove(
		$observationID: ID!
	) {
		ehsObservationRemove(ehsObservation: $observationID)
	}
'''

export MarkAsChecked = gql '''
	mutation ehsObservationMarkAsChecked(
		$observationID: ID!
	) {
		ehsObservationMarkAsChecked(ehsObservation: $observationID) {
			id
			status
		}
	}
'''

export MarkAsPending = gql '''
	mutation ehsObservationMarkAsPending(
		$observationID: ID!
	) {
		ehsObservationMarkAsPending(ehsObservation: $observationID) {
			id
			status
		}
	}
'''

export SyncToBim = gql '''
	mutation createBimIssue(
		$observationID: ID!
		$companyID: ID
		$dueDate: Int
		$status: BimIssueStatus
	) {
		createBimIssue (
			observationID: $observationID
			companyID: $companyID
			dueDate: $dueDate
			status: $status
		) {
			id
			status
			isSynced
			bimIssue {
				id
				status
			}
		}
	}
'''
