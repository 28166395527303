
###
Range (from - to) input field
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
# Renderable
import { div, span } from 'react-dom-factories'

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _InlineDialog from '@atlaskit/inline-dialog'
InlineDialog = React.createFactory _InlineDialog

import { FieldTextStateless as _FieldTextStateless } from '@atlaskit/field-text'
FieldTextStateless = React.createFactory _FieldTextStateless

import _Button from '@bevy/button'
Button = React.createFactory _Button

# Styles
import styles from './index.styl'
import {
	xCircle
} from 'react-icons-kit/feather'


export default class RangeSelector extends React.Component
	@propTypes =
		start: PropTypes.shape
			value: PropTypes.string
			onChange: PropTypes.func.isRequired
		end: PropTypes.shape
			value: PropTypes.string
			onChange: PropTypes.func.isRequired
		renderValue: PropTypes.func
		InputComponent: PropTypes.func
		onChange: PropTypes.func

	@defaultProps =
		InputComponent: FieldTextStateless
		renderValue: (start, end) ->
			hasStart = start?.value? and !_.isEmpty start.value
			hasEnd = end?.value? and !_.isEmpty end.value
			if hasStart and hasEnd
				"#{start.value} - #{end.value}"
			else if hasStart
				"from #{start.value}"
			else if hasEnd
				"to #{end.value}"
			else
				'Select range...'

	constructor: (props) ->
		super props
		@state =
			isOpen: false

	handleChange: (value, {label, item, index}) ->
		key = if index is 0 then 'start' else 'end'
		if value is ''
			value = null
		if @props[key].value isnt value
			item.onChange value
			@props.onChange
				name: @props.name
				start: if @props.start?.value? and !_.isEmpty @props.start.value then @props.start.value else null
				end: if @props.end?.value? and !_.isEmpty @props.end.value then @props.end.value else null
				"#{key}": value

	render: ->
		hasStart = @props.start?.value? and !_.isEmpty @props.start.value
		hasEnd = @props.end?.value? and !_.isEmpty @props.end.value
		InlineDialog
			isOpen: @state.isOpen
			onClose: => @setState isOpen: false
			content:
				div {className: styles.dialogBase},
					do =>
						_.map [ {label: 'from', item: @props.start}, {label: 'to', item: @props.end} ], ({label, item}, index) =>
							div {key: label, className: styles.input},
								@props.InputComponent {
									label: label,
									placeholder: '-'
									...item
									value: if item.value? then item.value else ''
									onChange: (event) => @handleChange event.target.value, {label, item, index}
								}
								div
									className: styles.icon
									onClick: => @handleChange null, {label, item, index}
								,
									Icon {size: 12, icon: xCircle}
		,
			Button
				className: cnames [
					@props.className
					styles.button
					if hasStart or hasEnd then styles.filled
					if @props.spacing is 'compact' then styles.compact
				]
				compact: @props.spacing is 'compact'
				onClick: => @setState isOpen: !@state.isOpen
			,
				@props.renderValue @props.start, @props.end
