# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import PropTypes from 'prop-types'
import DataAttribute from '@bevy/data-attribute'

import { div, span, input } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import _EditableItem from '@bevy/editable-item'
EditableItem = React.createFactory _EditableItem

import _AutosizeInput from 'react-input-autosize'
AutosizeInput = React.createFactory _AutosizeInput

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

import styles from './index.styl'

export default class EditableDocumentName extends React.Component
	@propTypes:
		isLoading: PropTypes.bool
	render: ->
		EditableItem
			showButtonsAlways: true
			forceEdit: @props.forceEdit
			renderDisplay: ({onClick}) =>
				div {
					onClick
					className: cnames [
						styles.EditableName
						styles.placeholder if _.isEmpty @props.value
						@props.textClass
						styles.loading if @props.isLoading
						styles.active if @props.onChange?
					]
				},
					if @props.isLoading
						Spinner {size: 'xsmall'}
					span {className: styles.value}, @props.value || 'Untitled'
			renderEdit: ({tempValue, onTempChange, onBlur}) =>
				AutosizeInput {
					className: cnames styles.input, @props.inputClass
					autoFocus: true
					value: tempValue
					onChange: (event) -> onTempChange event.target.value
					placeholder: 'Untitled'
					minWidth: 100
					placeholderIsMinWidth: true
					onBlur
				}
			enableEdit: @props.onChange?
			initialEditValue: @props.value
			onChange: @props.onChange
			enableEnterPress: true
			isRequired: true
			hideButtonWhenNoChange: false
			standardSize: false
