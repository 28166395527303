export default '''

	type LeasingOfferResult {
		grossValue: Float!
		# Based on totalGLA
		averageOccupancy: Float!
		# Based on total annual rent
		economicOccupancy: Float!
	}
	type LeasingOffer implements EditableMetainfo & FileInterface {
		# Basic properties
		id: ID!
		name: String!
		# Meta info – based on properties
		createdAt: Int!
		createdBy: Actor

		modifiedAt: Int!
		modifiedBy: Actor

		inline: Boolean!
		description: String!
		comments: [Comment!]
		annotations: [Annotation!]
		# Draft / Commited
		state: DocumentState!
		# Internal / Shared / etc.
		status: LeasingOfferStatus!
		signed: Boolean

		# Staticics
		volume: Float!
		nla: Float!
		gla: Float!
		totalGla: Float!
		spaces: [LeasingOfferSpace!]
		units: Int
		floors: [Int!]

		# Small KPIs
		dates: CommercialSpaceDates!
		noi: Float!
		rentFree: CommercialSpaceRentFree!

		averageRent: Float!
		dominantSpaceType: FloorSectionType
		dominantSpaceVolume: Float

		# Related entities
		opportunity: Opportunity!
		account: Account!

		# For specific result TenantMix or Scenario should be provided
		result(tenantMixID: ID, scenarioID: ID): LeasingOfferResult!

		tenantMixes: [TenantMix!]
	}

	type LeasingOfferSpaceResult {
		grossValue: CommercialSpaceGrossValue!
		serviceCharge: Float!
		shortfall: CommercialSpaceShortfall
		uncashed: Float!
	}
	type LeasingOfferSpace {
		# Basic properties
		type: FloorSectionType!
		floor: Float!
		volume: FloorVolume!

		rent: CommercialSpaceRent!
		effectiveRent: CommercialSpaceRent!
		incentives: CommercialSpaceIncentives!
		agentFee: CommercialSpaceAgentFee!
		rentFree: CommercialSpaceRentFree!
		fitout: CommercialSpaceFitout!
		dates: CommercialSpaceDates!
		expansion: Boolean
		serviceChargeReconciled: Boolean
		yield: Float!

		# Staticics
		nla: Float!
		gla: Float!
		totalGla: Float!
		# TODO!: Implementation
		# Difference between baseline tenant mix including and excluding this leasing offer
		# calculations are done always using baseline tenant mix
		# addedValue: Float!

		# For specific result TenantMix or Scenario should be provided
		result(tenantMixID: ID, scenarioID: ID): LeasingOfferSpaceResult!


	}

	type CommercialSpaceRent {
		headline: Float!
		# Calculated based on calculations module
		monthly: Float!
		daily: Float!
		annual: Float!
	}
	type CommercialSpaceIncentives {
		total: Float!
	}
	type CommercialSpaceAgentFee {
		months: Float!
		# Calculated based on calculations module
		total: Float!
	}
	type BaselineSpaceAgentFee {
		months: Float!
	}

	type CommercialSpaceRentFree {
		months: Float!
		rate: Float!
		# Calculated based on calculations module
		total: Float!
	}
	type BaselineSpaceRentFree {
		months: Float!
		rate: Float!
	}

	type CommercialSpaceFitout {
		value: Float!
		# Calculated based on calculations module
		total: Float!
	}
	type CommercialSpaceDates {
		signing: Int
		commencement: Int
		expiry: Int
		# Calculated based on calculations module
		term: DatesDuration
	}
	type CommercialSpaceGrossValue {
		# Calculated based on calculations module
		total: Float!
		sqm: Float!
	}
	type CommercialSpaceShortfall {
		# Calculated based on calculations module
		rent: Float!
		serviceCharge: Float!
	}


	type BaselineSpace {
		type: FloorSectionType!
		floor: Int!
		incentives: CommercialSpaceIncentives!
		agentFee: BaselineSpaceAgentFee!
		rentFree: BaselineSpaceRentFree!
		rent: Float!
		fitout: Float!
		dates: CommercialSpaceDates!
		serviceChargeReconciled: Boolean
		yield: Float!
	}

	type LeasingOfferSpaceOverride {
		# Location of override
		offer: LeasingOffer
		type: FloorSectionType!
		floor: Int!
		# Properties for overrides
		incentives: CommercialSpaceIncentives
		agentFee: BaselineSpaceAgentFee
		rentFree: BaselineSpaceRentFree
		rent: Float
		fitout: Float
		dates: CommercialSpaceDates
		serviceChargeReconciled: Boolean
		yield: Float
	}

	type LeasingOfferPreview {
		# Displaying of leasing offer and manipulations
		vacants: [LeasingOfferSpace!]!
		# Null in this place means that there is an error
		estimatedResult: ProjectResult
	}
	# Leasing offers


	enum NumericOperator {
		GT
		GTE
		LT
		LTE
	}
	input NumericFilter {
		operator: NumericOperator!
		value: Float!
	}
	input FloorSectionFilter {
		type: FloorSectionType!
		floor: Float!
	}
	input OpportunitiesFilter {
		# Closing date of opportunity
		closingDateYear: [Int!]
		# Full text search on leasing offers and opportunities
		search: String
		# Opportunity stage filter
		stage: [OpportunityStageID!]
		# Bulk search operator
		closed: Boolean
	}
	input LeasingOfferFilters {
		# Direct search with opportunity
		opportunityID: ID
		# Offer state filter
		status: [LeasingOfferStatus!]
		# Filter for volume of leasing offer
		volume: NumericFilter
		# Filter for floor of leasing offer spaces
		floor: [Int]
		# Section types filter for leasing offers
		type: [FloorSectionType!]
		# Specific areas filter
		areas: [FloorSectionFilter!]
		# Drafts & Commited
		state: [DocumentState!]
		# Inline to tenant mix
		inline: Boolean
		# NOTE: Mutually exclusive with inline filter
		# Include offers from this tenant mix
		tenantMixID: ID!
	}
'''
