exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".RD0naTndsDpjYvzy3-5JE {\n  flex: 1;\n  margin-top: 8px;\n  background-color: #f4f5f7;\n}\n._12z6N_yKBElFNPFKzCtGzb {\n  padding: 20px 10px;\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr;\n  min-height: 300px;\n}\n._12z6N_yKBElFNPFKzCtGzb ._1KEySaYWWRUg4fT9jHvOx- {\n  border-right: 2px solid #e6e8ed;\n  padding: 10px 20px;\n}\n._12z6N_yKBElFNPFKzCtGzb ._1KEySaYWWRUg4fT9jHvOx-:last-child {\n  border-right: 0;\n}\n._12z6N_yKBElFNPFKzCtGzb ._1KEySaYWWRUg4fT9jHvOx- ._18RXyl7rG_-5ODqT2nJ1uW {\n  color: #091e42;\n  font-weight: 600;\n  font-size: 1.4rem;\n  margin-bottom: 15px;\n}\n._12z6N_yKBElFNPFKzCtGzb ._1KEySaYWWRUg4fT9jHvOx- .kOZJtzdCSEEkJAaloP9ws {\n  margin-bottom: 10px;\n}\n._12z6N_yKBElFNPFKzCtGzb ._1KEySaYWWRUg4fT9jHvOx- .kOZJtzdCSEEkJAaloP9ws ._2XJxqUqCwiQVDMSCfnQDYb {\n  color: #8993a4;\n  font-size: 0.9rem;\n}\n._12z6N_yKBElFNPFKzCtGzb ._1KEySaYWWRUg4fT9jHvOx- .kOZJtzdCSEEkJAaloP9ws ._32ERUsBXFtH1Vr2t0k6JVQ {\n  padding: 10px;\n}\n._12z6N_yKBElFNPFKzCtGzb ._1KEySaYWWRUg4fT9jHvOx- .kOZJtzdCSEEkJAaloP9ws ._32ERUsBXFtH1Vr2t0k6JVQ:hover {\n  cursor: pointer;\n  background-color: #ebecf0;\n  border-radius: 3px;\n}\n._12z6N_yKBElFNPFKzCtGzb ._1KEySaYWWRUg4fT9jHvOx- .kOZJtzdCSEEkJAaloP9ws ._2pk9jvepuC2i6Cl7dh_rs {\n  padding-left: 10px;\n  cursor: pointer;\n}\n._12z6N_yKBElFNPFKzCtGzb ._1KEySaYWWRUg4fT9jHvOx- .FsoWk3lk-HgtHRFJ8a4zp {\n  padding: 10px;\n  background-color: #e6e8ed;\n}\n.IGOOnS8KhdZDh8RsGoJHr {\n  display: grid;\n  grid-template-columns: 70px auto;\n  grid-row-gap: 10px;\n  background-color: #e6e8ed;\n  padding: 30px;\n}\n.IGOOnS8KhdZDh8RsGoJHr ._2XJxqUqCwiQVDMSCfnQDYb {\n  color: #8993a4;\n}\n.IGOOnS8KhdZDh8RsGoJHr ._32ERUsBXFtH1Vr2t0k6JVQ {\n  color: #091e42;\n}\n._2a4Wt3M-K0CsPIco-WgYQE {\n  color: #7a869a;\n}\n", ""]);

// Exports
exports.locals = {
	"base": "RD0naTndsDpjYvzy3-5JE",
	"content": "_12z6N_yKBElFNPFKzCtGzb",
	"column": "_1KEySaYWWRUg4fT9jHvOx-",
	"header": "_18RXyl7rG_-5ODqT2nJ1uW",
	"item": "kOZJtzdCSEEkJAaloP9ws",
	"title": "_2XJxqUqCwiQVDMSCfnQDYb",
	"value": "_32ERUsBXFtH1Vr2t0k6JVQ",
	"paddingLeft": "_2pk9jvepuC2i6Cl7dh_rs",
	"note": "FsoWk3lk-HgtHRFJ8a4zp",
	"footer": "IGOOnS8KhdZDh8RsGoJHr",
	"placeholder": "_2a4Wt3M-K0CsPIco-WgYQE"
};