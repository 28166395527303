###
Common way of representing floor information on the left of the building
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
# Renderable
import { div, p, ul, li, small, h1, span } from 'react-dom-factories'

import { Box as _SkeletonBox } from '@bevy/skeleton'
SkeletonBox = React.createFactory _SkeletonBox

# Styles
import styles from './index.styl'


export default class ApplicationHeader extends React.Component
	render: ->
		div {
			className: cnames [
				@props.className
				styles.base
			]
		},
			do =>
				if @props.loading
					SkeletonBox {
						animated: true
						style:
							height: '15px'
							width: '100px'
							marginBottom: '5px'
						}
				else
					p {className: styles.project}, @props.project
			p {className: styles.application}, @props.application
