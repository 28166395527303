###
Easily switch between projects with dropdown and projects as an options
FIXME: Items in dropdown should feature avatar of projects
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
# Renderable
import { div, img, p, span} from 'react-dom-factories'

Fragment = React.createFactory React.Fragment

import _Avatar from '@atlaskit/avatar'
Avatar = React.createFactory _Avatar

import { Query as _Query } from 'react-apollo'
Query = React.createFactory _Query

# Styles
import styles from './index.styl'

export default class ProjectIndicator extends React.Component
	constructor: (props) ->
		super props
		@data = {}
	render: ->
		Query
			variables: _.pick @props, 'slug'
			skip: !@props.slug?
			query: gql '
				query($slug: String!) {
					project(slug: $slug){
						id
						photo {
							mini
						}
					}
				}
			'
		, ({data, loading, error}) =>
			Avatar
				size: 'small'
				component: ({children, className}) ->
					div {
						style:
							pointerEvents: 'none'
						className: className
					}, children
				src: if !loading and data?.project?.photo? then data.project.photo.mini
				appearance: 'square'
				className: @props.className
