export default '''
	input OpportunityInput {
		name: String
		accountID: ID
		projectID: ID
		stateID: OpportunityStageID
	}
	input LeasingOfferInput {
		projectID:ID!
		baseLeasingOfferID: ID
		opportunityID: ID
	}
	# input LeasingOfferInStackingPlanInput {
	# 	stackingPlanID: ID!
	# 	baseLeasingOfferID: ID
	# 	opportunityID: ID
	# }
	# type LeasingOfferInStackingPlanCreateResutl {
	# 	stackingPlan: StackingPlan
	# 	offer: LeasingOffer
	# }
	input LeasingOfferSpaceInput {
		agentFee: Float
		area: Float
		commencement: Float
		# expansion: Boolean
		expiry: Float
		fitout: Float
		incetives: Float
		rent: Float
		rentFreeMonths: Float
		rentFreePercentage: Float
		signing: Float
		yield: Float
	}
	input SplitInput {
		itemID: ID
		lineID: ID!
		value: Float!
		month: Int
	}

	input TenantMixInput {
		name: String
		offers: [ID!]!
	}

	input TenantMixLeasingOfferAttachingInfo {
		offerID: ID!
		active: Boolean!
	}

	input LeasingOfferUpdateAgentFees {
		months: Float!
		rate: Float!
	}

	input LeasingOfferUpdateInput {
		id: ID!
		volumePlanned: Float!
		volumeMeasured: Float!
		rent: Float!
		incentives: Float!
		agentFee: Float!
		rentFree: LeasingOfferUpdateAgentFees!
		fitout: Float!
		signingDate: Int
		commencementDate: Int!
		expiryDate: Int!
		expansion: Boolean!
		serviceChargeReconciled: Boolean!
		yield: Float!
	}

	input VacantSpaceInput {
		floor: Int!
		type: FloorSectionType!
		volumePlanned: Float!
		volumeMeasured: Float!
	}


	input DocumentBasicInfoInput {
		companyID: ID!
		contractorID: ID!
	}
	input DocumentCategoryInput {
		type: DocumentType!
		topicID: ID!
	}
	input DocumentDetailsInput {
		description: String
		dates: [DocumentDateInput!]!
		identifiers: [DocumentIdentifierInput!]!
		entities: [DocumentEntityInput!]!
	}
	input DocumentDateInput {
		date: Int!
		type: DocumentDateType!
	}
	input DocumentIdentifierInput {
		code: String!
		type: DocumentIdentifierType!
	}
	input DocumentEntityInput {
		entityID: ID!
		entityType: DocumentEntityType!
		role: DocumentEntityRole!
	}

	input TimelineInput {
		landAcquisition: Int
		constructionStart: Int
		constructionEnd: Int
		closingDate: Int
	}


	type Mutation{
		createOpportunity(accountID: ID!, projectID: ID!, name: String!, stage: OpportunityStageID!, closeDate: Int!): Opportunity
		updateOpportunity(id: ID!, input: OpportunityInput!): Opportunity

		createTenantMix(projectID: ID!, input: TenantMixInput!): TenantMix
		createTenantMixVariant(tenantMixID: ID!): TenantMix
		createTenantMixSnapshot(tenantMixID: ID!, name: String, description: String): TenantMix
		restoreSnapshot(tenantMixID: ID!, snapshotID: ID!): TenantMix
		deleteSnapshot(snapshotID: ID!): TenantMix
		promoteSnapshot(snapshotID: ID!, name: String!, description: String): TenantMix

		createLeasingOfferVariant(offerID: ID!, tenantMixID: ID): LeasingOffer
		createNewLeasingOffer(tenantMixID: ID, opportunityID: ID!): LeasingOffer

		updateLeasingOfferActiveStatus(offerID: ID!, tenantMixID: ID!, activate: Boolean!): TenantMix

		updateTenantMixAttachedOffers(tenantMixID: ID!, offers: [TenantMixLeasingOfferAttachingInfo!]): TenantMix

		updateLeasingOffer(offerID: ID!, spaces: LeasingOfferUpdateInput!): LeasingOffer

		assignVacantSpace(offerID: ID!, space: VacantSpaceInput!): LeasingOffer
		unassignSpace(offerID: ID!, spaceID: ID!): LeasingOffer
		# TODO!: Should support also assigning defined vacants
		# assignDefinedVacant(offerID: ID!, )

		#
		updateCostVariantSplit(variantID: ID!, input: SplitInput!): CostVariant!

		createScenario(projectID: ID!): Scenario

		updateScenario(scenarioID: ID!, tenantMixID: ID, costTableID: ID, divestmentID: ID): Scenario

		moveSpace(offerID: ID!, spaceID: ID!, floor: Int, section: FloorSectionType!): LeasingOffer

		startReconciliation(scenarioID: ID!): Scenario
		startRevenueAdjustments(scenarioID: ID!): Scenario
		reviewScenario(scenarioID: ID!): Scenario
		commitScenario(scenarioID: ID!): Scenario

		updateTenantMixContext: (tenantMixID: ID!, costsVariantID: ID, divestmentID: ID, timeline: TimelineInput): ProjectContext
		restoreTenantMixContext: (tenantMixID: ID!): ProjectContext
		promoteContext: (contextID: ID!, name: String!, description: String): Scenario

		createDocument(
			basicInfo: DocumentBasicInfoInput!
			category: DocumentCategoryInput
			details: DocumentDetailsInput
			reviewed: Boolean
		): Document
		editDocument(
			documentID: ID!
			basicInfo: DocumentBasicInfoInput
			category: DocumentCategoryInput
			details: DocumentDetailsInput
			reviewed: Boolean
		): Document
	}
'''
