###
Accounts list view
###

# Libs
import _ from 'lodash'
import React from 'react'
import DataAttribute from '@bevy/data-attribute'

# Renderable
import { div, p } from 'react-dom-factories'

import { Query as _Query } from 'react-apollo'
Query = React.createFactory _Query

import { Link as _Link } from 'react-router-dom'
Link = React.createFactory _Link

import _Button from '@bevy/button'
Button = React.createFactory _Button

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

import _LayoutList from '@bevy/layout-list'
LayoutList = React.createFactory _LayoutList

# Data
import {RootQuery} from './data'


export default class AccountsHub extends React.Component
	render: ->
		div {
			className: @props.className
		},
			Query
				query: RootQuery
			, ({data, loading}) =>
				if loading
					Spinner {}
				else
					LayoutList
						'data-test-id': DataAttribute 'accounts-list'
						title: 'Accounts'
						onSearchChange: (event) -> console.log 'search:', event.target.value
						filters: [
								label: 'test filter'
								value: 'test filter'
							,
								label: 'another one'
								value: 'another one'
							]
						onFiltersChange: (filters) -> console.log 'filters:', filters
						table:
							head:
								cells: [
										key: 'name'
										content: 'Name'
										isSortable: true
									,
										key: 'opp'
										content: 'No. opportunities'
										isSortable: true
								]
							rows: _.map data.accounts, (account) ->
								key: account.id
								cells:
									[
											key: 'name'
											content: account.name
										,
											key: 'opp'
											content: account.numberOfOpportunities
										,
											key: 'name'
											content: Button {
												href: "/accounts/#{account.slug}"
											}, 'Open'
									]

						summary: div {}, 'Summary'
