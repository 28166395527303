###
Project entrypoint view
TODO: Replace image with real content
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'

# Renderable
import { div, p, ul, li, img } from 'react-dom-factories'

Fragment = React.createFactory React.Fragment

import _Breadcrumbs, {BreadcrumbsItem as _BreadcrumbsItem} from '@atlaskit/breadcrumbs'
BreadcrumbsItem = React.createFactory _BreadcrumbsItem
Breadcrumbs = React.createFactory _Breadcrumbs

import _PageHeader from '@atlaskit/page-header'
PageHeader = React.createFactory _PageHeader

import { Query as _Query } from 'react-apollo'
Query = React.createFactory _Query

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

import {Redirect as _Redirect} from 'react-router-dom'
Redirect = React.createFactory _Redirect

# Data
import { RootQuery } from './data'

# Styles
import styles from './index.styl'


export default class ProjectView extends React.Component
	render: ->
		# Redirect to: "/projects/#{@props.match.params.project}/legal/documents"
		# div {className: cnames @props.className, styles.pageWrapper},
		div {className: cnames @props.className },

			Query
				query: RootQuery
				variables:
					projectSlug: @props.match.params.project
			, ({data, loading}) =>
				if loading
					Spinner {}
				else
					{project} = data
					Fragment {},
						# PageHeader {
						# 	breadcrumbs: Breadcrumbs {},
						# 		BreadcrumbsItem text: 'Projects', href: '/projects', onClick: @props.navigate('/projects')
						# 		BreadcrumbsItem text: project.name
						# }, project.name
						do =>
							if @props.match.params.project is 'centrum-poludnie'
								img {src: require('assets/centrum-poludnie-card.jpg'), className: styles.imgResponsive}
							else
								img {src: require('assets/aleja-pokoju-card.jpg'), className: styles.imgResponsive}
