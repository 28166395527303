###
Calculations for leasing offers
###
import _ from 'lodash'
import objectid from 'bson-objectid'
import moment from 'moment-mini'
import { addTypenamesToSpace, alignSpace, getSignedSpaces } from '../helpers'

# TODO!: REMOVE MAGIC CONSTS
GARAGE_UNIT_VOLUME = 25
DAYS_IN_MONTH_LOW_PRECISION = 30.416
DAYS_IN_MONTH_HIGH_PRECISION = 30.41666
buildingProperties = [
	'addonFactor'
	'serviceCharge'
]
# Present Value (PV) formula – "The worth of a future amount of money at specific point in time."
PV = (rate, periods, payment, future = 0, type = 0) ->
	if rate == 0
		-payment * periods - future
	else
		((1 - (1 + rate) ** periods) / rate * payment * (1 + rate * type) - future) / (1 + rate) ** periods

# Conflict detection
detectConflicts = ({building, spaces}) ->
	conflicts = []
	for floor in building.floors
		for section in floor.sections
			sectionBase = if section.volume.measured? then section.volume.measured else section.volume.planned
			spacesSum = _.sumBy(_.filter(spaces, {type: section.type, floor: floor.number}), 'volume.base')
			if _.round(spacesSum, 2) > _.round(sectionBase, 2)
				conflicts.push
					floor: floor.number
					section: section.type
	return _.uniqBy conflicts, (conflict) -> "#{conflict.floor}/#{conflict.section}"
# NOTE: Investor rent roll should be a part of higher level of business logic
# CHANGE: PVPercentage removed for yield usage in PV formula

calculateSpaceCommonProperties = (space, building) ->
	space = {
		...space
		... _.omit(_.find(_.find(building.floors, number: space.floor).sections, type: space.type), ['volume'])
	}
	dates =
		expiry: moment.unix space.dates.expiry
		commencement: moment.unix space.dates.commencement
	# Duration of the offer with _.ceil
	dates.term =
		years: _.ceil dates.expiry.diff dates.commencement, 'years', true
		months: _.ceil dates.expiry.diff dates.commencement, 'months', true
	# Gross volume of space for planned and measured
	grossVolume = _.reduce ['planned', 'measured'], (acc, type) ->
		if space.volume[type]?
			acc[type] = _.round space.volume.measured * (1 + (if space.addonFactor? then space.addonFactor else 0)), 2
		acc
	, {}
	grossVolume.base = if grossVolume.measured then grossVolume.measured else grossVolume.planned
	# Space rent
	rents =
		headline: space.rent.headline
		monthly: _.round space.rent.headline * grossVolume.base, 2
		daily: _.round(space.rent.headline * grossVolume.base, 2) / (365 / 12)
		annual: _.round(space.rent.headline * grossVolume.base, 2) * 12
	totalRentFree = rents.monthly * space.rentFree.months * (space.rentFree.rate / 100)
	effectiveRents = do ->
		total = rents.annual - (totalRentFree / dates.term.years)
		{
			annual: total
			monthly: ((rents.monthly * dates.term.months) - (rents.monthly * space.rentFree.months * (space.rentFree.rate / 100))) / dates.term.months
			daily: total / 365
			headline: (total / 12) / grossVolume.base
		}
	rentFreeEnd = dates.commencement.clone().add(space.rentFree.months, 'months').subtract 1, 'day'

	{
		space
		dates
		grossVolume
		rents
		totalRentFree
		effectiveRents
		rentFreeEnd
	}
# CACHE: Add cache at this level with simple key "space-#{space.id}"
export calculateSpaceDerivedValues = (space, building, options) ->
	if options?
		throw new Error 'Space derived values don\'t support options!'
	# Attach all building floor space properties to leasing offer space
	{
		space
		dates
		grossVolume
		rents
		totalRentFree
		effectiveRents
		rentFreeEnd
	} = calculateSpaceCommonProperties space, building

	# Final structure of leasing offer space
	{
		...space
		volume: {
			...space.volume
			base: if space.volume.measured then space.volume.measured else space.volume.planned
		}
		grossVolume: grossVolume
		gla: _.round grossVolume.base * (if space.type is 'Garage' then 0 else 1), 2
		nla: if space.volume.measured then space.volume.measured else space.volume.planned
		totalGla: _.round grossVolume.base * (if space.type is 'Garage' then GARAGE_UNIT_VOLUME else 1), 2
		rent: {
			...space.rent
			...rents
		}
		dates: {
			...space.dates
			term: dates.term
		}
		rentFree: {
			...space.rentFree
			total: totalRentFree
		}
		agentFee: {
			...space.agentFee
			total: rents.headline * grossVolume.planned * space.agentFee.months
		}
		effectiveRent: effectiveRents
		fitout: {
			...space.fitout
			total: space.fitout.value * space.volume.planned
		}
	}
export calculateSpaceResult = (space, building, options) ->
	# Simple validation for missing parameters
	if !options?.closingDate?
		throw new Error 'Missing closing date'
	if !options?.yield?
		throw new Error 'Missing yield'
	if !options?.fxRate?
		throw new Error 'Missing fx rate'
	if !options?.serviceChargeFxRate?
		throw new Error 'Missing fx rate for service charges'


	{fxRate, closingDate, serviceChargeFxRate} = options
	closingDate = moment.unix closingDate
	{
		space
		dates
		grossVolume
		rents
		totalRentFree
		effectiveRents
		rentFreeEnd
	} = calculateSpaceCommonProperties space, building
	shortfallDuration = _.max [0, _.ceil dates.commencement.diff closingDate, 'days', true]
	remainingTime = closingDate.diff(dates.commencement, 'days', true) / DAYS_IN_MONTH_LOW_PRECISION
	remainingRentFree = space.rentFree.months - _.clamp remainingTime, 0, space.rentFree.months

	# NOTE: If local yield is defined use local, in other cases use yield supplied with options
	yieldParameter = if space.yield? then space.yield / 100 else options.yield / 100


	{
		lease:
			if dates.commencement.isBefore closingDate
				closingDate.unix()
			else
				dates.commencement.unix()
		grossValue:
			total: rents.annual / yieldParameter
			sqm: (rents.annual / yieldParameter) / grossVolume.base
		agentFee: _.round(rents.headline * grossVolume.planned * space.agentFee.months) * fxRate
		fitout:
			if space.fitout?
				(space.fitout.value / fxRate) * space.volume.planned
			else
				0
		serviceCharge:
			if space.serviceCharge?
				(grossVolume.planned * space.serviceCharge) / serviceChargeFxRate
			else
				0
		shortfall:
			rent: rents.monthly * (shortfallDuration / DAYS_IN_MONTH_LOW_PRECISION)
			serviceCharge: (grossVolume.planned * space.serviceCharge / serviceChargeFxRate) * (shortfallDuration / DAYS_IN_MONTH_LOW_PRECISION)
		uncashed: do ->
			effective = ((rents.monthly * dates.term.months) - (rents.monthly * space.rentFree.months)) / dates.term.months
			ineffective = rents.monthly - effective
			if rentFreeEnd.isSameOrAfter closingDate
				rentFreeEnd = closingDate
			rentFreeDays = _.max [0, rentFreeEnd.diff dates.commencement, 'days']
			fullDays = _.max [0, closingDate.diff rentFreeEnd, 'days', true]

			discounted = (effective - (rents.monthly * ((100 - space.rentFree.rate) / 100))) * (rentFreeDays / (DAYS_IN_MONTH_HIGH_PRECISION))
			full = ineffective * (fullDays / (365 / 12))
			discounted - full
		remainingRentFree: remainingRentFree * rents.monthly * (space.rentFree.rate / 100)
		PV:
			if !space.rent.signed?
				0
			else
				PV yieldParameter / 12, dates.term.months, (space.rent.headline - space.rent.signed) * space.grossVolume.base
	}


export listAllMissingVacantSpaces = ({definitions, allSpaces, building}) ->
	# NOTE: Don't produce negative vacant spaces
	vacants = []
	for floor in building.floors
		for section in floor.sections
			allSpacesInSection = _.filter allSpaces, {
				floor: floor.number
				type: section.type
			}
			remainingSpace = _.reduce allSpacesInSection, (acc, space) ->
				acc.planned = _.round acc.planned - space.volume.planned, 2
				acc.measured = _.round acc.measured - space.volume.measured, 2
				acc
			,
				{...section.volume}
			# TODO!: Spaces without measurments!!
			if (remainingSpace.planned is 0 and remainingSpace.planned isnt 0) or (remainingSpace.planned isnt 0 and remainingSpace.planned is 0)
				throw new Error 'Remaining space mismatch in baseline creation'
			if remainingSpace.planned > 0
				definition = _.find definitions, {
					floor: floor.number
					type: section.type
				}
				vacantSpace = {
					...definition
					rent:
						headline: definition.rent
					fitout:
						value: definition.fitout
					volume: remainingSpace
				}

				vacants.push vacantSpace
	vacants


###
CALCULATIONS
TODO!: Replace baseline tenant mix from calculated to document based approach similar to costs baseline variant
TODO!: Replace baseline in project for baseline divestment option
TODO!: Clean up project baseline definition
###
calculateInterestRates = ({timeline, finance, lines, interestRate, closingDate}) ->
	# Interest rates start after construction start and are calculated to project end

	startDate = moment.unix(timeline.landAcquisition).startOf 'month'
	interestRateStart = moment.unix(timeline.constructionStart).startOf 'month'
	interestRateEnd = moment.unix(closingDate || timeline.closing).endOf 'month'
	endDate = moment.unix(timeline.closing).endOf 'month'

	monthlyInterestRate = ((interestRate || finance.interestRate) / 100) / 12

	costs = _.reduce lines, (acc, line) ->
		for value, month in line.cashflow
			acc[month] += value
		acc
	, Array(_.ceil(endDate.diff startDate, 'months', true)).fill 0

	cumulativeCosts = _.reduce costs, (acc, monthlyCost, month) ->
		acc.items[month] = acc.sum += monthlyCost
		acc
	,
		sum: 0
		items: []

	values = _.map cumulativeCosts.items, (value, month) ->
		if startDate.clone().add(month, 'months').isBetween interestRateStart, interestRateEnd, null, '[]'
			value * monthlyInterestRate
		else
			0

	value: _.sum values
	cashflow: values
calculateAllSpacesInTenantMix = ({building, freeSpaceDefinitions, tenantMix, collections}) ->
	offersInPlan = collections.offers.find(id: $in: tenantMix.offersIDS)
	spacesInOffers = _.reduce offersInPlan, (acc, offer) ->
		overridesInOffer = _.filter tenantMix.overrides, offerID: offer.id
		if _.isEmpty overridesInOffer
			acc = _.concat acc, offer.spaces
		else
			acc = _.concat acc, _.map offer.spaces, (space) ->
				overridesInSpace = _.find overridesInOffer, {type: space.type, floor: space.floor}
				if !overridesInSpace?
					space
				else
					_space = {
						...space
					}
					# Just copy overrides
					if overridesInSpace.incentives?
						_space.incentives = overridesInSpace.incentives
					if overridesInSpace.dates?
						_space.dates = overridesInSpace.dates
					if overridesInSpace.serviceChargeReconciled?
						_space.serviceChargeReconciled = overridesInSpace.serviceChargeReconciled
					if overridesInSpace.yield?
						_space.yield = overridesInSpace.yield
					# Assign new properties
					if overridesInSpace.rent?
						_space.rent = {
							headline: overridesInSpace.rent
							signed: space.rent.headline
						}
					if overridesInSpace.fitout?
						_space.fitout = value: overridesInSpace.fitout
					# Copy but change __typename
					if overridesInSpace.agentFee?
						_space.agentFee = overridesInSpace.agentFee
					if overridesInSpace.rentFree?
						_space.rentFree = overridesInSpace.rentFree
					_space

		acc
	, []

	# TODO!: Add missing vacant spaces based on the baseline definitions
	missingVacants = listAllMissingVacantSpaces {
		definitions: freeSpaceDefinitions
		allSpaces: _.concat tenantMix.definedVacants, spacesInOffers
		building: building
	}
	vacants = _.map _.concat(tenantMix.definedVacants, missingVacants), (space) ->
		{
			...space
			_____VACANT____: true
		}

	allSpaces = _.concat vacants, spacesInOffers

export getProjectBaselineContext = ({collections, projectID}) ->
	project = collections.projects.findOne id: projectID
	context = collections.contexts.findOne id: project.contextID
	expandContext {
		collections
		context
	}
export expandContext = ({collections, context}) ->
	{
		...context
		tenantMix: if !context.tenantMixID? then null else collections.tenantMixes.findOne id: context.tenantMixID
		costsTable: if !context.costsVariantID? then null else collections.costsVariants.findOne id: context.costsVariantID
		divestment: if !context.divestmentID? then null else collections.divestments.findOne id: context.divestmentID
	}
# Return fields only related to calculateFullResults arguments
export trimContext = (context) ->
	_.pick context, [
		'timeline'
		'finance'
		'freeSpaceDefinitions'
		'tenantMix'
		'costsTable'
		'divestment'
		'building'
	]

# Caclulation of result should work with baseline and building only
export calculateFullResults = ({
	collections
	# tenantMix # Optional – representation of tenantMix, when missing baseline will be used
	# costsVariant # Optional – representation of costs, when missing baseline will be used
	# divestment # Optional – in case of missing baseline will be used
	timeline
	finance
	freeSpaceDefinitions
	tenantMix
	costsTable
	divestment
	building
}) ->
	# if divestment._closingDate?
	# 	timeline = {
	# 		...timeline
	# 		closing: divestment._closingDate
	# 	}
	spaces = calculateAllSpacesInTenantMix {
		building
		freeSpaceDefinitions
		tenantMix
		collections
	}

	codes = collections.codes.find()
	codesBookValue = _.map _.filter(codes, type: 'B'), 'id'
	agentFeesCodes = _.map _.filter(codes, (code) -> code.code in [6007, 5004]), 'id'
	interestRateCode = _.find(codes, code: 7001).id
	costsData = {}

	if !costsTable?
		throw new Error 'Calculations without costs variant not implemented!'
	else
		lines = collections.costsLines.find variantID: costsTable.id
		# Change currency from LOCAL TO EUR
		lines = _.map lines, (line) ->
			{
				...line
				value: _.round line.value / finance.fxRate, 2
				cashflow: _.map line.cashflow, (cashflowItem) -> _.round cashflowItem / finance.fxRate, 2
			}
		# Swap interest rates lines
		lines = _.reject lines, codeID: interestRateCode
		interestRateLine = calculateInterestRates {
			lines: lines
			timeline: timeline
			finance: finance
			interestRate: null
			closingDate: timeline.closing
		}
		interestRateLine = {
			...interestRateLine
			codeID: interestRateCode
		}
		lines = _.concat lines, interestRateLine


		# Recalculate interst rates
		totalInvestment = _.sumBy lines, 'value'
		notIncludedInBV = _.sumBy _.filter(lines, (line) -> line.codeID not in codesBookValue), 'value'

		costsData =
			bookValue: totalInvestment - notIncludedInBV #Sum of all lines with code 'B'
			masterLeaseProvision: 0 # This feature is not yet implemented
			totalInvestment: totalInvestment # Sum of all lines
			agentFeesAgreements: _.sumBy _.filter(lines, (line) -> line.codeID in agentFeesCodes), 'value' # 6007 & 5004

	# CACHE: Add cache with this combination of keys from divestment, tenantmix, costs
	spaces = _.map spaces, (space) ->
		{
			...alignSpace space, building
			result: calculateSpaceResult space, building, {
				...finance
				closingDate: timeline.closing
				yield: divestment.yield
			}
		}


	conflicts = detectConflicts {building: building, spaces}
	revenueData =
		totalAnnualRent: _.sumBy spaces, (space) -> space.rent.annual
		rentFreeClosing: _.sumBy spaces, (space) -> space.result.remainingRentFree
		shortfallRent: _.sumBy spaces, (space) -> space.result.shortfall.rent
		shortfallServiceCharge: _.sumBy spaces, (space) -> space.result.shortfall.serviceCharge
		PV: _.sumBy spaces, (space) -> space.result.PV
		grossSalesPrice: _.sumBy spaces, (space) -> space.result.grossValue.total
		agents: _.sumBy spaces, (space) -> space.result.agentFee
		incentives: _.sumBy spaces, (space) -> space.incentives.total
		# addedValue: _.sumBy spaces, (space) -> if space.addedValue?.total? then space.addedValue.total else 0
		uncashedRent: _.sumBy(spaces, (space) -> space.result.uncashed)
		fitout: _.sumBy(spaces, (space) -> space.result.fitout)

	errors = _.map conflicts, (conflict) ->
		__typename: 'CalculationsError'
		name: 'Conflicting spaces'
		description: "Conflicts spaces on floor #{conflict.floor} in section #{conflict.section}"
		code: 1000

	if !_.isEmpty errors
		return {
			__typename: 'ProjectResult'
			totalAnnualRent: null
			rentFreeClosing: null
			shortfallRent: null
			shortfallServiceCharge: null
			PV: null
			grossSalesPrice: null
			agents: null
			incentives: null
			fitout: null
			# addedValue: null
			uncashedRent: null
			uncashedRentFlow: null
			bookValue: null
			bookValueContingency: null
			additionalDiscount: null
			warrantyProvision: null
			advisoryFeesDivestment: null
			masterLeaseProvision: null
			provisionForMiscallenous: null
			totalInvestment: null
			totalDeductions: null
			agentFeesAgreements: null
			agentFeesDivestment: null
			totalCostOfSale: null
			marketValue: null
			GOS: null
			DP: null
			averageOccupancy: null
			averageVacancy: null
			economicOccupancy: null
			economicVacancy: null
			errors: errors
		}
	# Check line 5004
	agentsCheck = _.find lines, codeID: _.find(codes, code: 5004).id
	if revenueData.agents > agentsCheck.value
		errors.push {
			__typename: 'CalculationsError'
			name: 'Agent fees not covered'
			description: """
				Agents fees from revenue (#{_.round(revenueData.agents, 2)}) are bigger than total on line 5004 (#{_.round(agentsCheck.value, 2)}).
				Total overflow #{_.round(agentsCheck.value - revenueData.agents, 2)}
			"""
			code: 5004
			value: _.round revenueData.agents - agentsCheck.value, 2
		}
	# Check line 5005
	incetivesCheck = _.find lines, codeID: _.find(codes, code: 5005).id
	if revenueData.incentives > incetivesCheck.value
		errors.push {
			__typename: 'CalculationsError'
			name: 'Incetives not covered'
			description: """
				Incetives from revenue (#{_.round(revenueData.agents, 2)}) are bigger than total on line 5005 (#{_.round(incetivesCheck.value, 2)}).
				Total overflow #{_.round(revenueData.agents - incetivesCheck.value, 2)}
			"""
			code: 5005
			value: _.round revenueData.incentives - incetivesCheck.value, 2
		}
	# TOTAL FITOUT is classified to cost category 2700
	fitoutCodes = _.map _.filter(codes, category: 'Fitout'), 'id'
	fitoutCheck = _.filter lines, (line) -> line.codeID in fitoutCodes
	fitoutCheck = _.sumBy fitoutCheck, 'value'
	if revenueData.fitout > fitoutCheck
		errors.push {
			__typename: 'CalculationsError'
			name: 'Fitout not covered'
			description: """
				Fitout from revenue (#{_.round revenueData.fitout, 2}) is bigger than total in category 2700 (#{_.round fitoutCheck, 2}).
				Total overflow #{_.round(revenueData.fitout - fitoutCheck, 2)}
			"""
			code: 50051
			value: _.round revenueData.fitout - fitoutCheck, 2
		}


	# NOTE: Real calculations
	result = {
		__typename: 'ProjectResult'
		# Revenue data
		...revenueData

		# Costs data
		...costsData

		# Divestment data
		..._.pick divestment, [
			'additionalPriceDeduction'
			'bookValueContingency'
			'additionalDiscount'
			'warrantyProvision'
			'agentFeesDivestment'
			'advisoryFeesDivestment'
			'provisionForMiscallenous'
		]


		totalDeductions: null
		totalCostOfSale: null
		marketValue: null
		GOS: null
		DP: null
		averageOccupancy: null
		averageVacancy: null
		economicOccupancy: null
		economicVacancy: null
		errors: errors
	}
	result.uncashedRentFlow = do ->
		resultFlow =
			startDate: _.min _.map spaces, 'dates.commencement'
			endDate: _.max _.map spaces, (space) -> moment.unix(space.dates.commencement).add(space.rentFree.months, 'months').unix()
		startDate = moment.unix(resultFlow.startDate)
		endDate = moment.unix(resultFlow.endDate)
		totalEffectiveRent = _.sumBy spaces, (space) -> space.effectiveRent.annual
		resultFlowArray = _.map [0.._.ceil  startDate.diff endDate, 'months', true], ->
			rent: result.totalAnnualRent / 12
			effective: totalEffectiveRent / 12
			uncashed: 0
		for space in spaces
			spaceStartIndex = _.floor moment.unix(space.dates.commencement).diff startDate, 'months', true
			uncashedPerMonth = space.result.uncashed / space.rentFree.months
			for index in [0...space.rentFree.months]
				propertIndex = spaceStartIndex + index
				resultFlowArray[propertIndex].uncashed += uncashedPerMonth

		{
			...resultFlow
			__typename: 'UncashedRentFlow'
			totalRent: _.map resultFlowArray, 'rent'
			effectiveRent: _.map resultFlowArray, 'effective'
			uncashedRent: _.map resultFlowArray, 'uncashed'
		}


	result.totalDeductions = -_.sumBy [
		-result.rentFreeClosing
		-result.shortfallRent
		-result.shortfallServiceCharge
		result.PV
		result.additionalPriceDeduction
	]
	result.agentFeesAgreements = result.agents + result.incentives
	result.agentFeesDivestment = result.grossSalesPrice * (result.agentFeesDivestment / 100)

	result.totalCostOfSale = _.sum [
		result.bookValue
		result.bookValueContingency
		result.additionalDiscount
		result.warrantyProvision
		result.agentFeesAgreements
		result.agentFeesDivestment
		result.advisoryFeesDivestment
		result.uncashedRent
		result.masterLeaseProvision
		result.provisionForMiscallenous
	]

	result.marketValue = result.grossSalesPrice - result.totalDeductions
	result.GOS = (result.grossSalesPrice - result.totalDeductions) - result.totalCostOfSale
	result.DP = (result.marketValue - result.totalInvestment) / result.totalInvestment

	total = _.sumBy spaces, 'totalGla'
	result.averageOccupancy = _.sumBy(_.filter(spaces, (space) -> !space._____VACANT____?), 'totalGla') / total
	result.averageVacancy = _.sumBy(_.filter(spaces, (space) -> space._____VACANT____?), 'totalGla') / total

	result.economicOccupancy = _.sumBy(_.filter(spaces, (space) -> !space._____VACANT____?), 'rent.annual')  / result.totalAnnualRent
	result.economicVacancy = _.sumBy(_.filter(spaces, (space) -> space._____VACANT____?), 'rent.annual')  / result.totalAnnualRent

	result.local = _.reduce result, (acc, value, key) ->
		if key not in ['PV', 'averageOccupancy', 'averageVacancy', 'economicOccupancy', 'economicVacancy', 'errors', 'DP', '__typename']
			acc[key] = value * finance.fxRate
		else
			acc[key] = value
		acc
	, {}
	result.local.__typename = 'ProjectResultLocal'

	result
