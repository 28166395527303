import _ from 'lodash'
import { resolveMetaInfo, alignOffer } from '../../helpers'

export default (collections) ->
	{
		projects
		companies
		offers
		users
		opportunitiesStages
		contexts
	} = collections
	{
		...resolveMetaInfo collections
		name: (obj, args, context, info) ->
			account = companies.findOne id: obj.accountID
			project = projects.findOne id: obj.projectID
			if account? and project?
				"#{project.name}-#{account.name}"
			else
				'ERROR NAME'
		account: (obj, args, context, info) ->
			{
				...companies.findOne id: obj.accountID
				__typename: 'Account'
			}
		offers: (obj, args, context, info) ->
			#TODO!: Add all filtering options
			offersQuery =
				opportunityID: obj.id
			if args?.filter?.tenantMixID?
				offersQuery.tenantMixID = $or: [
						$eq: null
					,
						$eq: args.filter.tenantMixID
				]
			else if args?.filter?.inline?
				offersQuery.tenantMixID =
					if args.filter.inline
						$ne: null
					else
						$eq: null

			# Calculate all properties of all spaces in all offers
			project = projects.findOne id: obj.projectID
			{building} = contexts.findOne id: project.contextID
			# Default – Sort offers by id
			fullOffers = _.map offers.find(offersQuery), (offer) -> alignOffer offer, building
			fullOffers = _.orderBy fullOffers, 'id'
			_.reject fullOffers, (offer) ->
				if args?.filter?.type?  and !_.isEmpty args.filter.type
					types = _.map offer.spaces, 'type'
					if _.isEmpty _.intersection args.filter.type, types
						return true
				return false
		numberOfOffers: (obj, args, context, info) ->
			#TODO!: Add all filtering options
			offers.count
				opportunityID: obj.id
		poc: (obj, args, context, info) ->
			users.findOne id: obj.pocID
		agent: (obj, args, context, info) ->
			users.findOne id: obj.agentID
		stage: (obj, args, context, info) ->
			opportunitiesStages.findOne id: obj.stageID
		dominantAverageRent: (obj, args, context, info) ->
			offersInOpportunity = offers.find
				opportunityID: obj.id
				tenantMixID: null
			spaces = _.flatten _.map offersInOpportunity, 'spaces'
			types =  _.reduce _.groupBy(spaces, 'type'), (acc, value, key) ->
				acc.push
					type: key
					total: _.sumBy value, (space) -> if space.volume.measured then space.volume.measured else space.volume.planned
				acc
			, []
			if _.isEmpty types
				return null
			else
				_.meanBy _.filter(spaces, type: _.maxBy(types, 'total').type), 'rent.headline'
		dominantSpaceType: (obj, args, context, info) ->
			offersInOpportunity = offers.find
				opportunityID: obj.id
				tenantMixID: null
			spaces = _.flatten _.map offersInOpportunity, 'spaces'
			types =  _.reduce _.groupBy(spaces, 'type'), (acc, value, key) ->
				acc.push
					type: key
					total: _.sumBy value, (space) ->
						if space.volume.measured then space.volume.measured else space.volume.planned
				acc
			, []
			if _.isEmpty types
				return null
			else
				_.maxBy(types, 'total').type
		dominantSpaceVolume: (obj, args, context, info) ->
			offersInOpportunity = offers.find
				opportunityID: obj.id
				tenantMixID: null
			spaces = _.flatten _.map offersInOpportunity, 'spaces'
			types =  _.reduce _.groupBy(spaces, 'type'), (acc, value, key) ->
				acc.push
					type: key
					total: _.sumBy value, (space) ->
						if space.volume.measured then space.volume.measured else space.volume.planned
				acc
			, []
			if _.isEmpty types
				return null
			else
				_.meanBy _.filter(spaces, type: _.maxBy(types, 'total').type), (space) ->
					if space.volume.measured then space.volume.measured else space.volume.planned
		averageNOI: (obj, args, context, info) ->
			offersInOpportunity = offers.find
				opportunityID: obj.id
				tenantMixID: null
			project = projects.findOne id: obj.projectID
			{building} = contexts.findOne id: project.contextID
			fullOffers = _.map offersInOpportunity, (offer) -> alignOffer offer, building
			val = _.meanBy fullOffers, 'noi'
			if _.isNaN val
				0
			else
				val
		averageGLA: (obj, args, context, info) ->
			offersInOpportunity = offers.find
				opportunityID: obj.id
				tenantMixID: null
			project = projects.findOne id: obj.projectID
			{building} = contexts.findOne id: project.contextID
			fullOffers = _.map offersInOpportunity, (offer) -> alignOffer offer, building
			val = _.round _.meanBy fullOffers, 'gla'
			if _.isNaN val
				null
			else
				val
		createdAt: (obj, args, context, info) ->
			obj.metaInfo.createdAt
		createdBy: (obj, args, context, info) ->
			users.findOne id: obj.metaInfo.createdBy.id
		modifiedAt: (obj, args, context, info) ->
			offersInOpportunity = offers.find
				opportunityID: obj.id
			maxOffer = _.maxBy offersInOpportunity, (offer) -> offer.metaInfo.modifiedAt
			if maxOffer? and maxOffer.metaInfo.modifiedAt > obj.metaInfo.modifiedAt
				maxOffer.metaInfo.modifiedAt
			else
				obj.metaInfo.modifiedAt

			# obj.metaInfo.modifiedAt
		modifiedBy: (obj, args, context, info) ->
			# users.findOne id: obj.metaInfo.modifiedBy.id
			offersInOpportunity = offers.find
				opportunityID: obj.id
			maxOffer = _.maxBy offersInOpportunity, (offer) -> offer.metaInfo.modifiedAt
			modifier = obj.metaInfo.modifiedBy
			if maxOffer? and maxOffer.metaInfo.modifiedAt > obj.metaInfo.modifiedAt
				modifier = maxOffer.metaInfo.modifiedBy

			users.findOne modifier.id
	}
