export default '''
	enum FloorSectionType {
		Office
		Retail
		Restaurant
		Canteen
		Garage
		Storage
	}
	enum OpportunityStageID {
		InitialPresales
		RFPProposal
		Shortlist
		FinalOffer
		OfferAccepted
		LOI
		FinalAgreement
		ClosedWon
		ClosedLost
		OnHold
	}
	enum LeasingOfferStatus {
		Draft
		Commited
		InternalShared
		InternalApproved
		InternalRejected
		ClientShared
		ClientApproved
		ClientRejected
	}
	enum DocumentState {
		Draft
		Commited
	}
	enum ProjectPhaseType {
		Construction
		Land
		Divestment
	}
	enum CostVariantType {
		Draft
		Forecast
	}
'''
