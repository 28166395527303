
###
Toolbar combining common operations on content: search, filter, change perspective
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import PropTypes from 'prop-types'
# Renderable
import { div, button, span } from 'react-dom-factories'

Fragment = React.createFactory React.Fragment

import { ButtonGroup as _ButtonGroup } from '@atlaskit/button'
ButtonGroup = React.createFactory _ButtonGroup

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _DropdownSelector from '@bevy/dropdown-selector'
DropdownSelector = React.createFactory _DropdownSelector

import _RangeSelector from '@bevy/range-selector'
RangeSelector = React.createFactory _RangeSelector

import _SearchField from '@bevy/search-field'
SearchField = React.createFactory _SearchField

# Styles
import styles from './index.styl'
import {
	search
	filter as filterIcon
} from 'react-icons-kit/feather'





export default class ContentBar extends React.Component
	@filterType = PropTypes.shape
		name: PropTypes.string # isRequired
		options: PropTypes.arrayOf PropTypes.shape
			label: PropTypes.string.isRequired
			options: PropTypes.arrayOf PropTypes.shape # subgroup
				label: PropTypes.string.isRequired

	@propTypes =
		layout: PropTypes.oneOf [
			'vertical'
			'horizontal' # Default
		]
		search: PropTypes.shape
			isDisabled: PropTypes.boolean
			onChange: PropTypes.function
			defaultValue: PropTypes.string
		filters: PropTypes.shape
			primary: PropTypes.arrayOf ContentBar.filterType
			secondary: PropTypes.arrayOf ContentBar.filterType
			onChange: PropTypes.function

	constructor: (props) ->
		super props
		@state =
			searchValue: if props.search?.defaultValue? then props.search?.defaultValue else ''
			additionalFilters: []
			activePrimary: if props.filters?.primary?
				_.map props.filters.primary, (filter) -> if filter.value then filter.value else []
			activeSecondary: if props.filters?.secondary?
				_.map props.filters.secondary, (filter) -> if filter.value then filter.value else []
	render: ->
		div {className: styles.wrapper},
			# ButtonGroup {className: styles.base},
			div {
				className: cnames [
					styles.base
					if @props.layout is 'vertical' then styles.vertical
				]
			},
				# Search
				do => if @props.search?
					div {className: styles.field},
						div {
							className: cnames [
								styles.label
								if @props.search.active then styles.active
							]
						},
							span {}, 'SEARCH'
							do => if @props.search.active
								div {className: styles.activeIndicator},
									Icon
										icon: filterIcon
										size: 12
						SearchField
							isLabelHidden: true
							value: @state.searchValue
							shouldFitContainer: true
							compact: @props.spacing is 'compact'
							onClear: =>
								@setState searchValue: ''
								if @props.onTextSearch?
									@props.onTextSearch ''
							onChange: (event) =>
								@setState searchValue: event.target.value
								if @props.onTextSearch?
									@props.onTextSearch event.target.value

				# Primary filters
				do => if @props.filters?.primary?
					_.map @props.filters.primary, (filter, index) =>
						onChange = (value, others...) =>
							newPrimary = _.clone @state.activePrimary
							newPrimary[index] = value
							@setState activePrimary: newPrimary
							if filter.onChange?
								all = [value, others...]
								filter.onChange.apply null, all
							if @props.filters.onChange?
								@props.filters.onChange
									primary: newPrimary
									secondary: @state.activeSecondary
									all: _.flattenDeep [newPrimary, @state.activeSecondary]
						div
							className: cnames styles.field, @props.fieldClassName, if @props.shouldFitContainer then styles.fitContainer
							key: filter.name+index
						,
							do -> if filter.name
								div {
									className: cnames [
										styles.label
										if filter.active then styles.active
									]
								},
									span {}, filter.name
									do -> if filter.active
										div {className: styles.activeIndicator},
											Icon
												icon: filterIcon
												size: 12
							do =>
								if filter.type? and filter.type is 'range'
									RangeSelector {
										...filter
										spacing: @props.spacing
										className: cnames [styles.filter, @props.primaryClassName, filter.className]
										onChange: onChange
									}
								else
									DropdownSelector {
										...filter
										spacing: @props.spacing
										className: cnames [styles.filter, @props.primaryClassName, filter.className]
										onChange: onChange
									}

				# Secondary filters
				do => if @props.filters?.secondary?
					div {className: styles.field},
						span {className: styles.label}, 'Other filters'
						DropdownSelector
							className: styles.filter
							hideSelectedOptions: false
							controlShouldRenderValue: false
							isClearable: false
							options: _.map @props.filters.secondary, (filter) ->
								label: filter.name
								value: filter
							onChange: (value) =>
								@setState additionalFilters: value
				do => _.map @state.additionalFilters, (filterOption) =>
					filter = filterOption.value
					onChange = (value, others...) =>
						index = _.indexOf @props.filters.secondary, filter
						newSecondary = _.clone @state.activeSecondary
						newSecondary[index] = value
						@setState activeSecondary: newSecondary
						if filter.onChange?
							all = [value, ...others]
							filter.onChange.apply null, all
						if @props.filters.onChange?
							@props.filters.onChange
								primary: @state.activePrimary
								secondary: newSecondary
								all: _.flattenDeep [@state.activePrimary, newSecondary]
					div {className: styles.field, key: filterOption.label},
						span {className: styles.label}, filter.name
						do =>
							if filter.type? and filter.type is 'range'
								RangeSelector {
									...filter
									className: cnames [styles.filter, @props.secondaryClassName, filter.className]
									onChange: onChange
								}
							else
								DropdownSelector {
									...filter
									className: cnames [styles.filter, @props.secondaryClassName, filter.className]
									onChange: onChange
								}
			@props.children
