import Building from './building.graphql'
import Comment from './comment.graphql'
import CourierService from './courierService.graphql'
import Misc from './misc.graphql'
import Leasing from './leasing.graphql'
import TenantMix from './tenantMix.graphql'
import SalesForce from './salesforce.graphql'
import Users from './users.graphql'
import Project from './project.graphql'
import Document from './document.graphql'
import Costs from './costs.graphql'
import Scenarios from './scenarios.graphql'
import Companies from './companies.graphql'

export default """
	#{Building}

	#{Comment}

	#{CourierService}

	#{Misc}

	#{Leasing}

	#{TenantMix}

	#{SalesForce}

	#{Users}

	#{Project}

	#{Document}

	#{Costs}

	#{Scenarios}

	#{Companies}

"""
