
###
Platform global navigation
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { matchPath, Link as _Link } from 'react-router-dom'
Link = React.createFactory _Link

# Renderable
import { div, button } from 'react-dom-factories'

Fragment = React.createFactory React.Fragment

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _ProjectNavigation from './components/ProjectNavigation'
ProjectNavigation = React.createFactory _ProjectNavigation

import _SearchPanel from './components/SearchPanel'
SearchPanel = React.createFactory _SearchPanel

import _ApplicationSwitcher from './components/ApplicationSwitcher'
ApplicationSwitcher = React.createFactory _ApplicationSwitcher

import _ProjectIndicator from './components/ProjectIndicator'
ProjectIndicator = React.createFactory _ProjectIndicator

import _GlobalNav from '@bevy/global-navigation'
GlobalNav = React.createFactory _GlobalNav

# Styles
import {
	grid
	menu
	bell
	helpCircle
	link
	search
	home
	briefcase
	alignJustify
	fileText
	settings
	user
} from 'react-icons-kit/feather'
import {
	ic_apps
} from 'react-icons-kit/md'
import styles from './index.styl'

export default class GlobalNavigation extends React.Component
	constructor: (props) ->
		super props
		@state =
			appSwitcherSize: 'wide'
	matchPath: (path, exact = true) ->
		match = matchPath window.location.pathname,
			path: path
			exact: exact
		match?
	render: ->
		GlobalNav
			primaryItems: _.compact [
					id: 'app-switcher'
					openAs: "drawer-#{@state.appSwitcherSize}"
					name: 'Application switcher'
					tooltip: 'Quickly jump between application and recent activities in S360 platform'
					icon: Icon
						icon: ic_apps
						size: 18
					content: ({close}) =>
						ApplicationSwitcher
							onExpand: => @setState appSwitcherSize: 'extended'
							onCollapse: => @setState appSwitcherSize: 'wide'
				,
					id: 'search'
					openAs: 'drawer-wide'
					name: 'Search'
					tooltip: 'Search platform documents, applications and users'
					icon: Icon
						icon: search
						size: 18
					content: ({close}) ->
						SearchPanel {}
			]
			secondaryItems: _.compact [
				do =>
					match = matchPath window.location.pathname,
						path: '/projects/:project'
					slug = if match? then match.params.project
					return null if !slug?
					id: 'project-navigation'
					openAs: 'drawer-narrow'
					name: 'Application switcher'
					tooltip: 'Quickly jump between application and recent activities in S360 platform'
					className: styles.ProjectIndicator
					trigger:
						ProjectIndicator {
							slug
							className: styles.ProjectIndicatorAvatar
						}
					content: ({close}) =>
						ProjectNavigation {
							..._.pick @props, ['history', 'location', 'match']
							project: slug
							onClick: (event) ->
								if !event.shiftKey and !event.ctrlKey
									close()
						}
			]
			accessoryItems: [
					id: 'notifications'
					openAs: 'drawer-wide'
					name: 'Search'
					tooltip: 'Search platform documents, applications and users'
					icon: Icon
						icon: bell
						size: 18
					content: ({close}) ->
				,
					id: 'help'
					openAs: 'drawer-wide'
					name: 'Search'
					tooltip: 'Search platform documents, applications and users'
					icon: Icon
						icon: helpCircle
						size: 18
					content: ({close}) ->
				,
					id: 'settings'
					openAs: 'drawer-wide'
					name: 'Search'
					tooltip: 'Search platform documents, applications and users'
					icon: Icon
						icon: settings
						size: 18
					content: ({close}) ->
				,
					id: 'profile'
					openAs: 'drawer-wide'
					name: 'Profile'
					tooltip: 'Search platform documents, applications and users'
					icon: Icon
						icon: user
						size: 18
					content: ({close}) ->
				,
			]
		,
			@props.children
