###
Project costs HUB
TODO: Change Route props from render to component
###

# Libs
import _ from 'lodash'
import React from 'react'
import moment from 'moment-mini'
import {matchPath} from 'react-router-dom'
import cnames from 'classnames'
import Loadable from 'react-loadable'

# Renderable
import { div, p, ul, li, small, h1, h2, h3, span } from 'react-dom-factories'

import { Query as _Query } from 'react-apollo'
Query = React.createFactory _Query

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _RouterTabs from '@bevy/router-tabs'
RouterTabs = React.createFactory _RouterTabs

import { Box as _SkeletonBox } from '@bevy/skeleton'
SkeletonBox = React.createFactory _SkeletonBox

import _Button from '@bevy/button'
Button = React.createFactory _Button

import _ApplicationHeader from 'components/ApplicationHeader'
ApplicationHeader = React.createFactory _ApplicationHeader

import {
	Route as _Route
	Switch as _Switch
	Link as _Link
} from 'react-router-dom'
Route = React.createFactory _Route
Switch = React.createFactory _Switch
Link = React.createFactory _Link

# Views
Overview = React.createFactory Loadable
	loader: -> `import(/* webpackChunkName: "revenue-overview-view" */'../ProjectCostsOverview')`
	loading: -> div {}
InvoicesView = React.createFactory Loadable
	loader: -> `import(/* webpackChunkName: "revenue-overview-view" */'../ProjectCostsInvoices')`
	loading: -> div {}
CurrentView = React.createFactory Loadable
	loader: -> `import(/* webpackChunkName: "revenue-overview-view" */'../ProjectCostsCurrent')`
	loading: -> div {}
DraftsView = React.createFactory Loadable
	loader: -> `import(/* webpackChunkName: "revenue-overview-view" */'../ProjectCostsVariants')`
	loading: -> div {}
# Data
import { RootQuery } from './data'
# Styles
import styles from './index.styl'
import {
	externalLink
} from 'react-icons-kit/feather'


export default class ProjectCostsHUB extends React.Component
	render: ->
		div {
			className: cnames [
				@props.className
				styles.page
			]
		},
			Query
				query: RootQuery
				variables:
					projectSlug: @props.match.params.project
			, ({error, loading, data}) =>
				RouterTabs
					className: styles.tabs
					location: @props.location
					# footer: div {className: styles.footer},
					# 	Button {
					# 		appearance: 'link'
					# 		spacing: 'compact'
					# 		iconAfter: Icon
					# 			icon: externalLink
					# 	}, 'Scenario composer'
					header: ApplicationHeader {
						loading: loading
						project: if !loading then data.project.name
						application: 'Costs'
					}
					# header: div {
					# 	className: styles.header
					# },
					# 	do ->
					# 		if loading
					# 			SkeletonBox {
					# 				animated: true
					# 				style:
					# 					height: '15px'
					# 					width: '100px'
					# 					marginBottom: '5px'
					# 				}
					# 		else
					# 			p {className: styles.text}, data.project.name
					# 	p {className: styles.subtext}, 'Costs'
					tabs: [
							label: 'Overview'
							content: div {className: styles.tabContent},
								React.createElement Overview, @props
							href: "/projects/#{@props.match.params.project}/costs"
							exact: true
							id: 1
							redirect: "/projects/#{@props.match.params.project}/costs/table"
						,
							label: 'Current'
							content: div {className: styles.tabContent},
								React.createElement CurrentView, @props
							href: "/projects/#{@props.match.params.project}/costs/table/"
							exact: false
							id: 5
						,
							label: 'Variants'
							content: div {className: styles.tabContent},
								# React.createElement _DraftsView, @props
								Switch {},
									Route
										path: '/projects/:project/costs/drafts'
										component: DraftsView
										exact: true
									Route
										path: '/projects/:project/costs/drafts/:variant'
										component: CurrentView
										exact: false
							href: "/projects/#{@props.match.params.project}/costs/drafts"
							exact: false
							id: 6
						,
							label: 'Invoices'
							content: div {className: styles.tabContent},
								React.createElement InvoicesView, @props
							href: "/projects/#{@props.match.params.project}/costs/invoices"
							exact: false
							id: 4
					]
