exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1T5tTwQznIaRd8yHmp6NvZ {\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n  flex: 1;\n  align-self: flex-start;\n}\n._1T5tTwQznIaRd8yHmp6NvZ ._1zyPuA5iEjYp5-oHwYhN9I {\n  margin-right: 8px;\n}\n._3TKCmRKbPQ4bw4hxORERwB ._1T5tTwQznIaRd8yHmp6NvZ ._1zyPuA5iEjYp5-oHwYhN9I {\n  font-size: 1rem !important;\n}\n.Ab3CA7VraiWsMzQMX70BT {\n  height: 30px;\n  width: 1px;\n  margin-right: 8px;\n  background-color: #c1c7d0;\n}\n.Ab3CA7VraiWsMzQMX70BT:last-of-type {\n  width: 0;\n}\n", ""]);

// Exports
exports.locals = {
	"wrapper": "_1T5tTwQznIaRd8yHmp6NvZ",
	"item": "_1zyPuA5iEjYp5-oHwYhN9I",
	"dms": "_3TKCmRKbPQ4bw4hxORERwB",
	"separator": "Ab3CA7VraiWsMzQMX70BT"
};