import _ from 'lodash'
import moment from 'moment-mini'
import {
	resolveMetaInfo
	# calculateFullResults
	getProjectBaselineContext
	expandContext
} from '../../helpers'

export default (collections, {decreesService}) ->
	{projects, costsLines, costsItems, codes, invoices, decrees, users, contexts} = collections

	makeCostItem = (variant, item) ->
		forecasted = item.value
		splitted = _.sum(item.cashflow)
		unsplitted = forecasted - splitted
		# TODO!: invoiceItems implmenetation
		# invoiced = _.sumBy invoiceItems.find(codeID: line.codeID, itemID: item.id), 'value'
		invoiced = 0
		overrun = _.clamp invoiced - forecasted, 0, Infinity
		remaining = _.clamp forecasted - invoiced, 0, Infinity
		{
			...item
			forecasted: _.round forecasted, 2
			splitted: _.round splitted, 2
			unsplitted: _.round unsplitted, 2
			invoiced: _.round invoiced, 2
			overrun: _.round overrun, 2
			remaining: _.round remaining, 2
			split: _.map item.cashflow, (month) ->
				forecasted = month
				# TODO!: invoiceItems implmenetation
				# invoiced = _.sumBy invoiceItems.find(codeID: line.codeID, itemID: item.id), 'value'
				invoiced = 0
				overrun = _.clamp invoiced - forecasted, 0, Infinity
				remaining = _.clamp forecasted - invoiced, 0, Infinity
				{
					__typename: 'MonthlySplit'
					forecasted: _.round forecasted, 2
					invoiced: _.round invoiced, 2
					overrun: _.round overrun, 2
					remaining: _.round remaining, 2
				}
		}
	makeCostLineSplit = (decreesMatrix, project, line, items) ->
		{timeline} = contexts.findOne id: project.contextID
		startDate = moment.unix timeline.landAcquisition
		_.map line.cashflow, (value, index) ->
			currentDate = startDate.clone().add index, 'months'
			forecasted = if _.size(items) is 0 then value else _.sumBy items, (item) -> item.cashflow[index]
			invoiced = decreesMatrix[line.codeID][index]
			{
				__typename: 'MonthlySplit'
				date: currentDate.startOf('month').unix()
				forecasted: _.round forecasted, 2
				invoiced: _.round invoiced, 2
				overrun: _.round _.clamp(invoiced - forecasted, 0, Infinity), 2
				remaining: _.round _.clamp(forecasted - invoiced, 0, Infinity), 2
			}
	makeCostLine = (decreesMatrix, project, variant, line) ->
		decreesMatrix = decreesService variant.projectID
		items = costsItems.find
			lineID: line.id
			variantID: variant.id
		isLineContainer = _.isEmpty items
		forecasted = if isLineContainer then line.value else _.sumBy items, 'value'
		splitted = if isLineContainer then _.sum(line.cashflow) else _.sumBy items, (item) -> _.sum item.cashflow
		unsplitted = forecasted - splitted
		invoiced = _.sum decreesMatrix[line.codeID]
		overrun = _.clamp invoiced - forecasted, 0, Infinity
		remaining = _.clamp forecasted - invoiced, 0, Infinity
		{
			...line
			forecasted: _.round forecasted, 2
			splitted: _.round splitted, 2
			unsplitted: _.round unsplitted, 2
			invoiced: _.round invoiced, 2
			overrun: _.round overrun, 2
			remaining: _.round remaining, 2
			split: makeCostLineSplit decreesMatrix, project, line, items
			items: _.map items, (item) -> makeCostItem variant, item
		}
	calculateInterestRates = (variant) ->
		# Interest rates start after construction start and are calculated to project end

		project = projects.findOne id: variant.projectID
		{timeline, finance} = contexts.findOne id: project.contextID
		startDate = moment.unix(timeline.landAcquisition).startOf 'month'
		interestRateStart = moment.unix(timeline.constructionStart).startOf 'month'
		interestRateEnd = moment.unix(timeline.constructionEnd).endOf 'month'
		endDate = moment.unix(timeline.closing).endOf 'month'

		monthlyInterestRate = (finance.interestRate / 100) / 12

		costs = _.reduce costsLines.find(variantID: variant.id), (acc, line) ->
			items = costsItems.find
				lineID: line.id
				variantID: variant.id
			if _.isEmpty items
				for value, month in line.cashflow
					acc[month] += value
			else
				for item in items
					for value, month in item.cashflow
						acc[month] += value
			acc
		, Array(_.ceil(endDate.diff startDate, 'months', true)).fill 0

		cumulativeCosts = _.reduce costs, (acc, monthlyCost, month) ->
			acc.items[month] = acc.sum += monthlyCost
			acc
		,
			sum: 0
			items: []

		values = _.map cumulativeCosts.items, (value, month) ->
			if startDate.clone().add(month, 'months').isBetween interestRateStart, interestRateEnd, null, '[]'
				value * monthlyInterestRate
			else
				0

		__typename: 'CostLine'
		id: "#{variant.id}-ir"
		value: _.sum values
		cashflow: values
		codeID: codes.findOne(code: 7001).id
		variantID: variant.id
		projectID: project.id

	{
		...resolveMetaInfo collections
		timeline: (obj, args, context, info) ->
			project = projects.findOne id: obj.projectID
			{timeline} = contexts.findOne id: project.contextID
			startDate = moment.unix(timeline.landAcquisition).startOf 'month'
			endDate = moment.unix(timeline.closing).endOf 'month'

			ratesID = codes.findOne(code: 7001).id
			timeline = _.reduce costsLines.find(variantID: obj.id), (acc, line) ->
				# # if args? and args.includeInterest is false and line.codeID is ratesID
				# 	console.log '????'
				# 	return acc
				items = costsItems.find
					lineID: line.id
					variantID: obj.id
				if _.isEmpty items
					for value, month in line.cashflow
						acc[month] += value
				else
					for item in items
						for value, month in item.cashflow
							acc[month] += value
				acc
			, Array(_.ceil(endDate.diff startDate, 'months', true)).fill 0

			timeline = _.map timeline, (monthValue, month) ->
				currentDate = startDate.clone().add month, 'months'
				currentDecrees = decrees.find
					projectID: obj.projectID
					date:
						$between: [
							currentDate.startOf('month').unix()
							currentDate.endOf('month').unix()
						]
				invoiced = _.sumBy currentDecrees, 'value'
				forecasted = monthValue

				{
					__typename: 'CostTimelineMonth'
					date: currentDate.startOf('month').unix()
					forecasted: _.round forecasted, 2
					invoiced: _.round invoiced, 2
					overrun: _.round _.clamp(invoiced - forecasted, 0, Infinity), 2
					remaining: _.round _.clamp(forecasted - invoiced, 0, Infinity), 2
				}

			timeline
		lines: (obj, args, context, info) ->
			# NOTE: Meta optimalisations
			# TODO!: Align with categories
			throw new Error 'Not implemented correctly'
			lines = costsLines.find(variantID: obj.id)
			lines.push calculateInterestRates obj
			_.map lines, (line) -> makeCostLine obj, line
		categories: (obj, args, context, info) ->
			# NOTE: Interest rates
			# NOTE: Meta optimalisations
			console.time 'Categories'
			decreesMatrix = decreesService obj.projectID
			project = projects.findOne id: obj.projectID
			lines = costsLines.find(variantID: obj.id)
			lines.push calculateInterestRates obj
			lines = _.map lines, (line) ->
				{
					...makeCostLine decreesMatrix, project, obj, line
					_code: codes.findOne id: line.codeID
				}
			lines = _.orderBy lines, '_code.code', 'asc'
			res = _.map _.groupBy(lines, '_code.category'), (lines, name) ->
				forecasted = _.round _.sumBy(lines, 'forecasted'), 2
				splitted = _.round _.sumBy(lines, 'splitted'), 2
				unsplitted = _.round _.sumBy(lines, 'unsplitted'), 2
				invoiced = _.round _.sumBy(lines, 'invoiced'), 2

				overrun = _.round _.clamp(invoiced - forecasted, 0, Infinity), 2
				remaining = _.round _.clamp(forecasted - invoiced, 0, Infinity), 2
				{
					__typename: 'CostCategory'
					name
					lines
					forecasted
					splitted
					unsplitted
					invoiced
					overrun
					remaining
					code: parseInt _.first(lines)._code.code.toString().replace /(\d\d)\d\d/, '$100'
					codeName: _.first(lines)._code.code.toString().replace /(\d\d)\d\d/, '$1xx'
				}
			console.timeEnd 'Categories'
			res
		context: (obj, args, context, info) ->
			ctx = contexts.findOne id: obj.contextID
			ctx
		# result: (obj, args, context, info) ->
		# 	if args?.scenarioID?
		# 		scenario = scenarios.findOne id: args.scenarioID
		# 		console.warn 'Context'
		# 		context = getProjectBaselineContext {collections, projectID: obj.projectID}
		# 		context = expandContext {collections, context}


		# 		calculateFullResults {
		# 			collections
		# 			projectID: obj.projectID
		# 			tenantMix: tenantMixes.findOne id: scenario.tenantMixID
		# 			divestment: divestments.findOne id: scenario.divestmentID
		# 			costsVariant: costsVariant: obj
		# 		}

		# 	else
		# 		calculateFullResults {
		# 			collections
		# 			projectID: obj.projectID
		# 			costsVariant: obj
		# 		}
		isBaseline: (obj, args, context, info) ->
			obj._isBaseline is true
	}
