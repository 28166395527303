###
Next global navigation with all items open as a drawers or dropmenus
Temporary approach is to have primary, secondary and accessory items with exactly the same properties
TODO: Add rules for (primary, secondary, acccessory) items how, when they can open e.g. accessory items always open as 'dropmenu'
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import PropTypes from 'prop-types'
import DataAttribute from '@bevy/data-attribute'

import styles from './index.styl'

# Renderable
import { div, p, ul, li, small, h1, h2, h3, span, img, button } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import _Button from '@bevy/button'
Button = React.createFactory _Button

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon


import _Drawer from '@atlaskit/drawer'
Drawer = React.createFactory _Drawer

import _DropList from '@atlaskit/droplist'
DropList = React.createFactory _DropList


import {
} from '@bevy/theme/icons'

export default class GlobalNavigation extends React.Component
	@propTypes:
		primaryItems: PropTypes.arrayOf PropTypes.shape
			id: PropTypes.string.isRequired
			openAs: PropTypes.oneOf [
				'drawer'
				'drawer-narrow'
				'drawer-medium'
				'drawer-wide'
				'drawer-extended'
				'drawer-full'

				'droplist'
			]
			name: PropTypes.node
			tooltip: PropTypes.node
			trigger: PropTypes.node
			indicator: PropTypes.oneOfType [
				PropTypes.bool
				PropTypes.number
			]
			content: PropTypes.func
		secondaryItems: PropTypes.arrayOf PropTypes.shape
			openAs: PropTypes.oneOf [
				'drawer'
				'drawer-narrow'
				'drawer-medium'
				'drawer-wide'
				'drawer-extended'
				'drawer-full'

				'droplist'
			]
			name: PropTypes.node
			tooltip: PropTypes.node
			trigger: PropTypes.node
			indicator: PropTypes.oneOfType [
				PropTypes.bool
				PropTypes.number
			]
			content: PropTypes.func
		accessoryItems: PropTypes.arrayOf PropTypes.shape
			openAs: PropTypes.oneOf [
				'drawer'
				'drawer-narrow'
				'drawer-medium'
				'drawer-wide'
				'drawer-extended'
				'drawer-full'

				'droplist'
			]
			name: PropTypes.node
			tooltip: PropTypes.node
			trigger: PropTypes.node
			indicator: PropTypes.oneOfType [
				PropTypes.bool
				PropTypes.number
			]
			content: PropTypes.func
	constructor: (props) ->
		super props
		@state = {}
	renderTrigger: (item, props) ->
		Button {
			className: cnames styles.Item, props.className, item.className, if item.icon then styles.ItemIcon
			tooltip: item.tooltip
			iconBefore: if !item.trigger? then item.icon
			...props
		}
		,
			item.trigger
	renderSectionItems: (items, section) ->
		renderProps =
			close: => @setState openElement: null
		_.map items, (item) =>
			if /^drawer/.test(item.openAs) or !item.openAs?
				# Open as drawer
				@renderTrigger item,
					key: item.id
					onClick: if /^drawer/.test(item.openAs) or !item.openAs?
						# Drawer trigger
						=> @setState openElement: item.id
			else
				# Open as droplist
				DropList
					key: item.id
					position: do ->
						verticalPosition = switch section
							when 'primary' then 'top'
							when 'secondary' then 'center'
							when 'accessory' then 'bottom'
						"right #{verticalPosition}"
					isOpen: @state.openElement is item.id
					onOpenChange: ({isOpen}) => @setState openElement: null if !isOpen
					boundariesElement: 'window'
					isMenuFixed: true
					trigger:
						@renderTrigger item,
							onClick: (event) =>
								@setState openElement: if @state.openElement is item.id then null else item.id
								event.stopPropagation()
								event.preventDefault()
				,
					item.content {...renderProps}

	renderDrawers: ->
		renderProps =
			close: => @setState openElement: null
		items = _.compact(_.concat(@props.primaryItems, @props.secondaryItems, @props.accessoryItems))
		_.compact _.map items, (item) =>
			if /^drawer/.test(item.openAs) or !item.openAs?
				drawerWidth = 'medium'
				if item.openAs?
					drawerWidth = item.openAs.replace /^drawer(-)?/i, ''
					drawerWidth = 'medium' if _.isEmpty drawerWidth

				Drawer
					key: item.id
					onClose: => @setState openElement: null
					isOpen: @state.openElement is item.id
					width: drawerWidth
				,
					item.content {...renderProps}


	render: ->
		div {className: cnames [
			styles.GlobalNavigation
		]},
			Fragment {}, @renderDrawers()
			div {className: styles.Menu},
				div {className: cnames styles.PrimarySection}, @renderSectionItems @props.primaryItems, 'primary' if !_.isEmpty @props.primaryItems
				div {className: cnames styles.SecondarySection}, @renderSectionItems @props.secondaryItems, 'secondary' if !_.isEmpty @props.secondaryItems
				div {className: cnames styles.AccessorySection}, @renderSectionItems @props.accessoryItems, 'accessory' if !_.isEmpty @props.accessoryItems
			div {className: styles.Content}, @props.children
