exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2260iKDahRHD0SX7ZznLR2 {\n  display: flex;\n  overflow: hidden;\n  position: relative;\n  background-color: #ebecf0;\n  padding: 0 48px;\n}\n._2260iKDahRHD0SX7ZznLR2 h1,\n._2260iKDahRHD0SX7ZznLR2 h2,\n._2260iKDahRHD0SX7ZznLR2 h3 {\n  font-weight: 500;\n  margin-bottom: 0;\n  padding-left: 6px;\n}\n._2gf_N2S5YeCKY7IH6tbp7F > div > div > span {\n  background-color: transparent;\n}\n._2gf_N2S5YeCKY7IH6tbp7F div[role=tab] > span {\n  background-color: #0052cc;\n}\n._2gf_N2S5YeCKY7IH6tbp7F ._2XG_VCNnTuqTx_LJGciZEx {\n  display: inline-block;\n  margin-left: 8px;\n}\n._2gf_N2S5YeCKY7IH6tbp7F div > [role=\"tabpanel\"] {\n  padding: 0;\n}\n._3HqNdLurPWe4GEStDoZYja {\n  flex: 1;\n  padding: 0;\n  color: #0a1633;\n}\n", ""]);

// Exports
exports.locals = {
	"page": "_2260iKDahRHD0SX7ZznLR2",
	"tabs": "_2gf_N2S5YeCKY7IH6tbp7F",
	"badge": "_2XG_VCNnTuqTx_LJGciZEx",
	"tabContent": "_3HqNdLurPWe4GEStDoZYja"
};