exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3685oMm0waNkyKy0IWqb4G {\n  display: flex;\n  overflow: hidden;\n  max-width: 100%;\n  position: relative;\n  background-color: #ebecf0;\n}\n._3685oMm0waNkyKy0IWqb4G h1,\n._3685oMm0waNkyKy0IWqb4G h2,\n._3685oMm0waNkyKy0IWqb4G h3 {\n  font-weight: 500;\n  margin-bottom: 0;\n  padding-left: 6px;\n}\n._2lzfZoas07BuaC6n7LxsDu > div > div > span {\n  background-color: transparent;\n}\n._2lzfZoas07BuaC6n7LxsDu div[role=tab] > span {\n  background-color: #0052cc;\n}\n._2lzfZoas07BuaC6n7LxsDu ._1cjcsWjvyX65EIVn3h1D3f {\n  display: inline-block;\n  margin-left: 8px;\n}\n._2lzfZoas07BuaC6n7LxsDu div[role=tabpanel] {\n  padding: 0;\n}\n._1RpAeUdHNebo0VdRy38y8R {\n  flex: 1;\n  padding: 0;\n  color: #0a1633;\n}\n._35iwQxLgxXiaD1cO5m_Mqj {\n  height: 100%;\n  overflow: hidden;\n}\n._3uG_ewwtP2KoixbZFiPjKq {\n  height: 100%;\n  display: grid;\n  grid-template-rows: min-content auto;\n  grid-template-areas: \"header\" \"content\";\n}\n.i6C1QTJzBZeec8kUPpxUq {\n  grid-area: content;\n  background-color: #fff;\n  padding: 0 48px;\n  overflow-y: hidden;\n  display: flex;\n  flex-direction: column;\n}\n.ox4dCGMFv2R3Sxd8x3ruw {\n  font-size: 1.45rem;\n  padding: 20px 0 10px 0;\n}\n._39GmJXJMUKKTb4yaQnpm_R {\n  font-size: 0.82rem;\n}\n.QmZhtWNL8uF5jJ9QAaLjx {\n  overflow: auto;\n}\n.lFjl9Y4Ry_UHj7XMqNXw8 {\n  padding: 5px 0;\n  width: 100%;\n  color: #000;\n}\n.RB2lq9wIXJoAftnacG7uy {\n  cursor: pointer;\n}\n", ""]);

// Exports
exports.locals = {
	"mainPage": "_3685oMm0waNkyKy0IWqb4G",
	"tabs": "_2lzfZoas07BuaC6n7LxsDu",
	"badge": "_1cjcsWjvyX65EIVn3h1D3f",
	"tabContent": "_1RpAeUdHNebo0VdRy38y8R",
	"pageWrapper": "_35iwQxLgxXiaD1cO5m_Mqj",
	"page": "_3uG_ewwtP2KoixbZFiPjKq",
	"content": "i6C1QTJzBZeec8kUPpxUq",
	"headerText": "ox4dCGMFv2R3Sxd8x3ruw",
	"text": "_39GmJXJMUKKTb4yaQnpm_R",
	"tableWrapper": "QmZhtWNL8uF5jJ9QAaLjx",
	"editableCell": "lFjl9Y4Ry_UHj7XMqNXw8",
	"interactive": "RB2lq9wIXJoAftnacG7uy"
};