exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1x0mHxqGpBPz7PD97fG0JZ {\n  font-size: 16px;\n  padding: 0 10px;\n}\n._1NI1UmCmi2hveUjourCijF {\n  padding-bottom: 2px;\n}\n._1G4GCgs-ZOTkALqwfMWhB {\n  color: #000;\n  padding-bottom: 4px;\n}\n._3f0xYdqkIl7IOjR8HzEpgw {\n  display: flex;\n  align-items: center;\n}\n", ""]);

// Exports
exports.locals = {
	"header": "_1x0mHxqGpBPz7PD97fG0JZ",
	"icon": "_1NI1UmCmi2hveUjourCijF",
	"button": "_1G4GCgs-ZOTkALqwfMWhB",
	"container": "_3f0xYdqkIl7IOjR8HzEpgw"
};