###
Info Viewer
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import moment from 'moment-mini'
import {FormatterConsumer} from 'libs/formatter'

# Renderable
import { div } from 'react-dom-factories'

Fragment = React.createFactory React.Fragment
import { Query as _Query, Mutation as _Mutation } from 'react-apollo'
Query = React.createFactory _Query
Mutation = React.createFactory _Mutation

import {DatePicker as _DatePicker } from '@atlaskit/datetime-picker'
DatePicker = React.createFactory _DatePicker

import DropdownMenu, { DropdownItemGroup, DropdownItem} from '@atlaskit/dropdown-menu'
DropdownMenuC = React.createFactory DropdownMenu
DropdownItemGroupC = React.createFactory DropdownItemGroup
DropdownItemC = React.createFactory DropdownItem

import FieldTextArea, { FieldTextAreaStateless } from '@atlaskit/field-text-area';
FieldTextAreaC = React.createFactory FieldTextArea

# Data
import {
	getBimCompanies, getBimLocations
} from '../../data'

# Styles
import styles from './index.styl'

states = [
	'Draft'
	'WorkCompleted'
	'ReadyToInspect'
	'NotApproved'
	'InDispute'
	'Closed'
	'Open'
	'Void'
]

export default class ObservationInfo extends React.Component
	constructor: (props) ->
		super props
		@state =
			currState: ''
			currDate: ''
			currCompany: ''
			currLocation: ''
			isEditingLocationNote: false
			currLocationNote: ''
			isEditingDescription: false
			currDescription: ''


	componentDidMount: ->
		document.addEventListener('keydown', @handleEscPress, false)

	componentWillUnmount: ->
		document.removeEventListener('keydown', @handleEscPress, false)

	handleEscPress: (e) =>
		if e.key is 'Escape'
			if @state.isEditingLocationNote
				@setState isEditingLocationNote: false
			if @state.isEditingDescription
				@setState Description: false

	renderColumnContent1: () =>
		Fragment {},
			div {className: styles.header}, 'Info'
			div {className: styles.item},
				div {className: styles.title}, 'Project'
				div {className: styles.value}, @props.project.name

			div {className: styles.item},
				div {className: styles.title}, 'Status'
				DropdownMenuC
					trigger: if !_.isEmpty @state.currState then @state.currState else 'Open'
					triggerType: 'button'
					shouldFitContainer: true
				,
					_.map states, (state) =>
						DropdownItemC {
							key: state
							onClick: (e) => @setState currState: state
							}, state


			div {className: styles.item},
				div {className: styles.title}, 'Due date'
				DatePicker
					icon: null
					value: if !_.isEmpty @state.currDate then @state.currDate else moment.unix(@props.dueDate)
					onChange: (e) =>
						@setState currDate: e


			div {className: styles.item},
				div {className: styles.title}, 'Company'
				Query {query: getBimCompanies}
				, ({ loading, data, error}) =>
					if loading or error
						return div {}, 'Loading...'
					else
						DropdownMenuC
							trigger: if !_.isEmpty @state.currCompany then @state.currCompany else 'Skanska SA'
							triggerType: 'button'
							shouldFitContainer: true
						,
							_.map data.bimCompanies, (company) =>
								DropdownItemC {
									key: company.id
									onClick: (e) => @setState {currCompany: company.name}
								}, company.name


	renderColumnContent2: () =>
		Fragment {},
			div {className: styles.header}, 'Location'
			Query {query: getBimLocations}
				, ({ loading, data, error}) =>
					if loading or error
						return div {}, 'Loading...'
					else
						Fragment {},
							div {className: styles.item},
								div {className: styles.title}, 'Location'
								DropdownMenuC
									trigger:
										if !_.isEmpty @state.currLocation
											@state.currLocation
										else if @props.location?
											@props.location.level.description
										else
											'Choose location'
									triggerType: 'button'
									shouldFitContainer: true
								,
									_.map _.sortBy(data.bimLocations, 'description'), (location) =>
										DropdownItemC {
											key: location.id
											onClick: (e) => @setState {currLocation: location.description}
										}, location.description

							if @state.isEditingLocationNote
								FieldTextAreaC
									isLabelHidden: true
									autoFocus: true
									onBlur: () => @setState isEditingLocationNote: false
									onChange: (e) => @setState currLocationNote: e.target.value

							else
								div
									className: styles.note
									onClick: () => @setState isEditingLocationNote: true
								,
									if !_.isEmpty @state.currLocationNote
										@state.currLocationNote
									else if @props.location.description?
										@props.location.description
									else
										'-'


	renderColumnContent3: () =>
		Fragment {},
			div {className: styles.header}, 'Description'
			if @state.isEditingDescription
				FieldTextAreaC
					isLabelHidden: true
					autoFocus: true
					onBlur: () => @setState isEditingDescription: false
					onChange: (e) => @setState currDescription: e.target.value

			else
				div
					className: styles.note
					onClick: () => @setState isEditingDescription: true
				,
					if !_.isEmpty @state.currDescription
						@state.currDescription
					else
						@props.description

	render: ->
		div {className: styles.base},
			div {className: styles.content},
				div {className: styles.column}, @renderColumnContent1()
				div {className: styles.column}, @renderColumnContent2()
				div {className: styles.column}, @renderColumnContent3()
