###
List of all projects
TODO!: Replace custom view with LayoutList or LayoutGrid
###

# Libs
import _ from 'lodash'
import React from 'react'
# Renderable
import { div, p, ul, li } from 'react-dom-factories'

import _PageHeader from '@atlaskit/page-header'
PageHeader = React.createFactory _PageHeader

import { ButtonGroup as _ButtonGroup } from '@atlaskit/button'
ButtonGroup = React.createFactory _ButtonGroup

import { Query as _Query } from 'react-apollo'
Query = React.createFactory _Query

import _Button from '@bevy/button'
Button = React.createFactory _Button

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

import _ProjectCard from '@bevy/project-card'
ProjectCard = React.createFactory _ProjectCard

# Data
import { RootQuery } from './data'

# Styles
import styles from './index.styl'


export default class Dashboard extends React.Component
	render: ->
		div {className: @props.className},
			PageHeader {
				actions: ButtonGroup {},
					Button {appearance: 'primary'}, 'Switch view'
			}, 'Projects'
			Query
				query: RootQuery
			, ({data, loading}) =>
				if loading
					Spinner {}
				else
					div {className: styles.container},
						do =>
							_.map data.projects, (project) =>
								ProjectCard
									key: project.id
									onClick: => @props.history.push "/projects/#{project.slug}"
									thumbnail: project.photo.mini
									name: project.name
									country: project.country
									city: project.city
									# startDate: project.baseline.timeline.start
									# endDate: project.baseline.timeline.end
