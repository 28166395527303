import './disable-speedy'
import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'
import path from 'path'

import { Route, StaticRouter, matchPath } from 'react-router'
import { BrowserRouter } from 'react-router-dom'

import ReactDOMServer from 'react-dom/server'
import AppContainer from './app'

# Common configuration
element = document.getElementById 'app'
action = 'render'
if !document.querySelector('#app > #app-placeholder')? and WEBPACK_STATIC_BUILD is true
	action = 'hydrate'
console.log 'Action:', action

_Wrapper = if __WRAPPER__? then require(__WRAPPER__).default else (content) -> content()
_Wrapper ->
	ReactDOM[action](
		<BrowserRouter>
			<AppContainer/>
		</BrowserRouter>
	,
		element
	)
