export default '''
	type CostCode {
		id: ID!
		name: String!
		category: String!
		type: String!
		code: Int!
	}
	type CostVariant implements FileInterface & EditableMetainfo & BaselineFileInterface {
		id: ID!
		name: String!
		# Short document summary & FileInterface
		description: String!
		comments: [Comment!]
		annotations: [Annotation!]
		state: DocumentState!

		# Meta info
		createdAt: Int!
		createdBy: Actor
		modifiedAt: Int!
		modifiedBy: Actor

		isBaseline: Boolean!

		commited: Boolean!
		categories: [CostCategory!]
		lines: [CostLine!]
		items: [CostItem!]
		timeline: [CostTimelineMonth]!
		# bookValue: Float!
		# totalInvestment: Float!
		context: ProjectContext!
		inline: Boolean!
	}
	type CostCategory {
		name: String!
		code: Int!
		codeName: String!
		lines: [CostLine!]
		forecasted: Float!
		splitted: Float!
		unsplitted: Float!
		invoiced: Float!
		overrun: Float!
		remaining: Float!
	}
	type MonthlySplit {
		date: Int!
		forecasted: Float!
		invoiced: Float!
		# Local level overruns and remainings
		overrun: Float!
		remaining: Float!
		# childrenTotal: Float!
	}
	type CostTimelineMonth {
		date: Int!
		forecasted: Float!
		invoiced: Float!
		overrun: Float!
		remaining: Float!
	}
	type CostLine {
		id: ID!
		code: CostCode!
		split: [MonthlySplit!]
		items: [CostItem!]
		# Forecasted is not sum of splitted values
		forecasted: Float!
		splitted: Float!
		unsplitted: Float!
		invoiced: Float!
		overrun: Float!
		remaining: Float!
		# childrenTotal: Float!
	}
	type CostItem {
		id: ID!
		name: String!
		line: CostLine!
		code: CostCode!
		split: [MonthlySplit!]
		# Forecasted is not sum of splitted values
		forecasted: Float!
		splitted: Float!
		unsplitted: Float!
		invoiced: Float!
		overrun: Float!
		remaining: Float!
	}
	type Invoice {
		id: ID!
		date: Int!
		codes: [CostCode!]
		name: String!
		value: Float!
		decrees: [InvoiceDecree!]!
	}
	type InvoiceDecree {
		id: ID!
		date: Int!
		codeID: CostCode!
		invoice: Invoice!
		name: String
		value: Float
	}
'''
