exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3XhsAVGc8pJle_SuaKoMHX {\n  display: flex;\n  align-items: center;\n}\n._3XhsAVGc8pJle_SuaKoMHX._30zL668WSLrd7JlaGXzhqR {\n  padding: 5px;\n  border-radius: 5px;\n}\n._3XhsAVGc8pJle_SuaKoMHX._30zL668WSLrd7JlaGXzhqR:hover {\n  background-color: #dfe1e6;\n  cursor: pointer;\n}\n._1xXeQDReVy5MmQPCp3Hcrs {\n  text-align: center;\n  display: flex;\n  justify-content: center;\n  font-size: 10px;\n}\n._29P-gVcnqH8harcH08mWyN {\n  margin: 0 10px;\n  display: flex;\n  flex-direction: column;\n  font-size: 90%;\n  white-space: nowrap;\n}\n._29P-gVcnqH8harcH08mWyN p {\n  margin: 0;\n}\n._29P-gVcnqH8harcH08mWyN p.f8TFCEtqUhj1bOvDt_49f {\n  color: #42526e;\n  white-space: nowrap;\n}\n._29P-gVcnqH8harcH08mWyN p._3jIH7iObQBjv9Gd7z6RI1E {\n  font-size: 80%;\n  color: #6b778c;\n}\n._8ccKIEokG5On-JLm2wgyC {\n  margin-left: auto;\n}\n", ""]);

// Exports
exports.locals = {
	"projectItem": "_3XhsAVGc8pJle_SuaKoMHX",
	"interactive": "_30zL668WSLrd7JlaGXzhqR",
	"emptyList": "_1xXeQDReVy5MmQPCp3Hcrs",
	"description": "_29P-gVcnqH8harcH08mWyN",
	"text": "f8TFCEtqUhj1bOvDt_49f",
	"subText": "_3jIH7iObQBjv9Gd7z6RI1E",
	"after": "_8ccKIEokG5On-JLm2wgyC"
};