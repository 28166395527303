import gql from 'graphql-tag'

export RootQuery = gql """
	query ProjectLegalRoot(
		$projectSlug: String!,
		){
		project(slug: $projectSlug) {
				id
				name
				members {
					... on Person {
						id
						name
						email
						projectsPermissions { roles }
						groups { id, name }
					}
				}
		}
	}
"""

export UpdateUserRoles = gql """
	mutation($userID: ID!, $projectSlug: String!, $roles: [String!]!) {
		projectUsersUpdateRoles(
			userID: $userID
			projectSlug: $projectSlug
			roles: $roles
		) {
			... on Person {
				id, projectsPermissions { roles }
			}
		}
	}
"""

