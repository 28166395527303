import _ from 'lodash'

import {
	resolveMetaInfo
	alignSpace
	alignOffer
	calculateBuildingKPI
	listAllMissingVacantSpaces
	# calculateFullResults
	getProjectBaselineContext
	expandContext
} from 'data/helpers'


export default (collections) ->
	{
		projects
		offers
		opportunities
		users
		scenarios
		costsVariants
		divestments
		tenantMixes
		contexts
	} = collections
	{
		...resolveMetaInfo collections
		signedNLA: (obj, args, context, info) ->
			project = projects.findOne id: obj.projectID
			{building} = contexts.findOne id: project.contextID
			buildingKPI = calculateBuildingKPI building
			activeOffers = offers.find id: $in: obj.offersIDS
			activeOffers = _.map activeOffers, (offer) -> alignOffer offer, building
			_.sumBy activeOffers, (offer) -> _.sumBy offer.spaces, 'nla'
		signedGLA: (obj, args, context, info) ->
			project = projects.findOne id: obj.projectID
			{building} = contexts.findOne id: project.contextID
			buildingKPI = calculateBuildingKPI building
			activeOffers = offers.find id: $in: obj.offersIDS
			activeOffers = _.map activeOffers, (offer) ->
				alignOffer offer, building
			_.sumBy activeOffers, (offer) -> _.sumBy offer.spaces, 'gla'

		offers: (obj, args, context, info) ->
			project = projects.findOne id: obj.projectID
			{building} = contexts.findOne id: project.contextID
			_.map offers.find(id: $in: obj.offersIDS), (offer) ->
				alignOffer offer, building
		# result: (obj, args, context, info) ->
		# 	if args?.scenarioID?
		# 		scenario = scenarios.findOne id: args.scenarioID

		# 		calculateFullResults {
		# 			collections
		# 			projectID: obj.projectID
		# 			tenantMix: obj
		# 			divestment: divestments.findOne id: scenario.divestmentID
		# 			costsVariant: costsVariants.findOne id: scenario.costsVariantID
		# 		}

		# 	else
		# 		calculateFullResults {
		# 			collections
		# 			projectID: obj.projectID
		# 			tenantMix: obj
		# 		}
		freeSpaces: (obj, args, context, info) ->
			project = projects.findOne id: obj.projectID
			{building, freeSpaceDefinitions} = contexts.findOne id: project.contextID
			allOffersInStackingPlan = offers.find id: $in: obj.offersIDS
			allOffersInStackingPlan = _.map allOffersInStackingPlan, (offer) -> alignOffer offer, building
			spaces = _.flatten _.map allOffersInStackingPlan, 'spaces'
			spaces = _.compact _.concat spaces, _.map obj.definedVacants, (space) -> alignSpace space, building
			# Add missing vacant spaces
			missingVacants = listAllMissingVacantSpaces {
				definitions: freeSpaceDefinitions
				allSpaces: spaces
				building: building
			}

			_.map missingVacants, (space, index) ->
				{
					...alignSpace space, building
					id: "#{obj.id}-missing-#{space.floor}-#{space.type}"
				}
		vacants: (obj, args, context, info) ->
			project = projects.findOne id: obj.projectID
			_.map obj.definedVacants, (space) -> alignSpace space, building
		attachedOpportunities: (obj, args, context, info) ->
			# Select all offers beloging to tenantMix
			project = projects.findOne id: obj.projectID
			{building} = contexts.findOne id: project.contextID
			allOffers = _.map offers.find(id: $in: _.concat(obj.offersIDS, obj.attachedOffersIDS)), (offer) ->
				alignOffer offer, building
			# Group by opportunity
			allOpportunities = opportunities.find id: $in: _.map allOffers, 'opportunityID'

			allOpportunities
			# _.map allOpportunities, (opportunity) ->
			# 	{
			# 		...opportunity
			# 		# offers: _.filter allOffers, opportunityID: opportunity.id
			# 	}
		activeOffers: (obj, args, context, info) ->
			project = projects.findOne id: obj.projectID
			{building} = contexts.findOne id: project.contextID
			_.map offers.find(id: $in: obj.offersIDS), (offer) ->
				alignOffer offer, building
		attachedOffers: (obj, args, context, info) ->
			project = projects.findOne id: obj.projectID
			{building} = contexts.findOne id: project.contextID
			_.map offers.find(id: $in: obj.attachedOffersIDS), (offer) ->
				alignOffer offer, building
		isBaseline: (obj, args, context, info) ->
			obj._isBaseline is true

		snapshots: (obj, args, context, info) ->
			tenantMixes.chain().find(root: obj.id).simplesort('metaInfo.modifiedAt', true).data()
		rootTenantMix: (obj, args, context, info) ->
			tenantMixes.findOne id: obj.root
		context: (obj, args, context, info) ->
			contexts.findOne id: obj.contextID


	}
