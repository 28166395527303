exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3zYWR2BrX0_H-PcDMrwse8 {\n  display: flex;\n}\n._3zYWR2BrX0_H-PcDMrwse8 h1,\n._3zYWR2BrX0_H-PcDMrwse8 h2,\n._3zYWR2BrX0_H-PcDMrwse8 h3 {\n  font-weight: 500;\n  margin-bottom: 0;\n  padding-left: 6px;\n}\n._3_Mryw6EiniVtjTDYTTTVk {\n  text-decoration: none;\n  color: inherit;\n}\n._3DC8USUN_A8zffQdZciQ1w {\n  flex: 1;\n}\n._3LTqtHjJ0hSC6vkI4JuEZf {\n  height: 100%;\n}\n.afTHYDR4Sq9dAjLAf-gwx {\n  position: relative;\n  margin-right: 12px;\n  margin-top: 8px;\n  padding: 8px;\n  padding-bottom: 6px;\n  color: #8993a4;\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 1;\n}\n.afTHYDR4Sq9dAjLAf-gwx p {\n  margin: 0;\n  margin-bottom: 2px;\n}\n.afTHYDR4Sq9dAjLAf-gwx ._2-Xb-_BVEdrjmA0fw5P9vy {\n  font-size: 110%;\n  font-weight: 500;\n}\n.afTHYDR4Sq9dAjLAf-gwx ._2OdlhSDcsvOOms_EOienPJ {\n  font-weight: 600;\n  font-size: 180%;\n  color: #172b4d;\n}\n", ""]);

// Exports
exports.locals = {
	"page": "_3zYWR2BrX0_H-PcDMrwse8",
	"link": "_3_Mryw6EiniVtjTDYTTTVk",
	"tabContent": "_3DC8USUN_A8zffQdZciQ1w",
	"skeletonBox": "_3LTqtHjJ0hSC6vkI4JuEZf",
	"header": "afTHYDR4Sq9dAjLAf-gwx",
	"text": "_2-Xb-_BVEdrjmA0fw5P9vy",
	"subtext": "_2OdlhSDcsvOOms_EOienPJ"
};