exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._14Jb0lSzqmW0h4ATPAT-xx {\n  position: fixed;\n  bottom: 16px;\n  left: 100px;\n  z-index: 100000;\n  animation: ljan1UR1nEppN1lvoa01x 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;\n}\n._1FiIIJk5LXlzXYTQxHbT92 {\n  width: 300px;\n  margin-bottom: 20px;\n  background-color: #deebff;\n  color: #000;\n  padding: 20px;\n  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);\n  border: solid 1px #7fb1ff;\n  border-radius: 3px;\n}\n._1FiIIJk5LXlzXYTQxHbT92._1erZUvsxqK0ntuuwPBytkz {\n  background-color: #ff991f;\n  border: solid 1px #e57d00;\n  color: #fff;\n}\n._1FiIIJk5LXlzXYTQxHbT92._13L7Dvf0zc71eVadP8mfgv {\n  background-color: #0f7848;\n  border: solid 1px #0c603a;\n  color: #fff;\n}\n._1FiIIJk5LXlzXYTQxHbT92._2rje31yINWALL-JvkXwWLB {\n  background-color: #de350b;\n  border: solid 1px #b22a09;\n  color: #fff;\n}\n.J-X2KvTfUES9EjnRk9vj4 {\n  display: flex;\n  align-items: center;\n}\n.J-X2KvTfUES9EjnRk9vj4 ._1l-WbubPw4Dr4E3mHAPcO6 {\n  margin-right: 10px;\n}\n.J-X2KvTfUES9EjnRk9vj4 ._52Pm7eurhHVW-WYHI61O2 {\n  max-width: 200px;\n  flex: 1;\n  margin-right: 10px;\n}\n.J-X2KvTfUES9EjnRk9vj4 ._1OfsYtsz25gTUpNtKFUYtx {\n  margin-left: auto;\n  color: #fff !important;\n  background: rgba(9,30,66,0.25);\n}\n.J-X2KvTfUES9EjnRk9vj4 ._1OfsYtsz25gTUpNtKFUYtx:hover {\n  background: rgba(9,30,66,0.35);\n}\n._2cWKivp7BbWXgWRv9_dO-m {\n  margin: 5px 0 5px 30px;\n}\n@-moz-keyframes ljan1UR1nEppN1lvoa01x {\n  0% {\n    transform: translateX(-500px);\n  }\n  100% {\n    transform: translateX(0);\n  }\n}\n@-webkit-keyframes ljan1UR1nEppN1lvoa01x {\n  0% {\n    transform: translateX(-500px);\n  }\n  100% {\n    transform: translateX(0);\n  }\n}\n@-o-keyframes ljan1UR1nEppN1lvoa01x {\n  0% {\n    transform: translateX(-500px);\n  }\n  100% {\n    transform: translateX(0);\n  }\n}\n@keyframes ljan1UR1nEppN1lvoa01x {\n  0% {\n    transform: translateX(-500px);\n  }\n  100% {\n    transform: translateX(0);\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"notificationsContainer": "_14Jb0lSzqmW0h4ATPAT-xx",
	"slide-right": "ljan1UR1nEppN1lvoa01x",
	"toast": "_1FiIIJk5LXlzXYTQxHbT92",
	"warning": "_1erZUvsxqK0ntuuwPBytkz",
	"success": "_13L7Dvf0zc71eVadP8mfgv",
	"error": "_2rje31yINWALL-JvkXwWLB",
	"header": "J-X2KvTfUES9EjnRk9vj4",
	"icon": "_1l-WbubPw4Dr4E3mHAPcO6",
	"text": "_52Pm7eurhHVW-WYHI61O2",
	"dismissButton": "_1OfsYtsz25gTUpNtKFUYtx",
	"footer": "_2cWKivp7BbWXgWRv9_dO-m"
};