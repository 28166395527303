export Project = '''
	id
	name
	slug
	city
	country
'''

export Observation = '''
	id
	state
	type
	code
	status
	classification
	createdAt
	createdBy {
		...on Person {
			id
			name
		}
	}
	modifiedAt
	modifiedBy {
		...on Person {
			id
			name
		}
	}
	photos {
		original
	}
	comments {
		id
	}
	categories {
		name
		type
	}

'''
