import { hot } from 'react-hot-loader'
import React from 'react'
path = require 'path'
pkg = require "#{__COMPONENT__}/package.json"
_RunnerContainer = require(__PACKAGE__).default

import { div } from 'react-dom-factories'

export default hot(module) class AppContainer extends React.Component
	render: ->
		<_RunnerContainer />
