###
Editable item

	renderDisplay: ({onClick}) ->
	renderEdit: ({tempValue, onTempChange, onBlur}) ->
	initialEditValue
	onChange: (value) ->

	enableEdit: bool
	enableEnterPress: bool
	enableEscPress: bool
	enableBlur: bool
	hideButtonsWhenNoChange: bool

###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import ReactDOM from 'react-dom'

# Renderable
import { div, input } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import _Button from '@bevy/button'
Button = React.createFactory _Button

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

# Styles
import styles from './index.styl'
import {
	check
	x
} from 'react-icons-kit/feather'

export default class EditableItem extends React.Component
	@propTypes:
		position: PropTypes.oneOf [
			'bottom right'
			'bottom left'
			'top right'
			'top left'
			'middle left'
			'middle right'
		]
	@defaultProps:
		enableEdit: true
		enableEnterPress: true
		enableEscPress: true
		enableBlur: true
		isRequired: true
		position: 'bottom right'
		calculatePosition: false
		standardSize: true

	constructor: (props) ->
		super props
		@target = React.createRef()
		@state =
			isBeingEdited: false
			tempValue: null
			top: 0
			right: 0

	componentDidMount: ->
		document.addEventListener('keydown', @handleKeyPress, false)
		document.addEventListener('click', @handleClickOut)

	componentWillUnmount: ->
		document.removeEventListener('keydown', @handleKeyPress, false)
		document.removeEventListener('click', @handleClickOut)

	handleKeyPress: (e) =>
		if @state.isBeingEdited
			if e.key is 'Escape' and @props.enableEscPress
				@onCancel()
			if e.key is 'Enter' and @props.enableEnterPress
				if (@state.tempValue isnt @props.initialEditValue)
					@onConfirm()
				else
					@onCancel()

	handleClickOut: (e) =>
		if @state.isBeingEdited and !(@target.current.contains e.target)
			if @state.tempValue is @props.initialEditValue
				@onCancel()

	onConfirm: () =>
		if @props.onChange
			@props.onChange(@state.tempValue)
		@setState
			isBeingEdited: false
		, @setButtonsPosition

	onCancel: () =>
		@setState
			isBeingEdited: false
			tempValue: @props.initialEditValue
		, @setButtonsPosition

	onEditValueChange: (value) =>
		@setState
			tempValue: value
		, @setButtonsPosition
	calculatePosition: =>
		[vertical, horizontal] = @props.position.split(' ')
		if !@target.current?
			return {}
		targetRect = @target.current.firstChild.getBoundingClientRect()
		position = {
			top: null
			bottom: null
			left: null
			right: null
		}
		switch vertical
			when 'top'
				position.bottom = window.innerHeight - targetRect.top
			when 'bottom'
				position.top = targetRect.top + targetRect.height
			when 'middle'
				position.top = targetRect.top

		if horizontal is 'right'
			position.right = window.innerWidth - targetRect.right
		else if 'left'
			position.left = targetRect.left
		position
	setButtonsPosition: ->
		setTimeout =>
			@setState calculatePosition: true
		, 5
	render: ->
		div {ref: @target, className: cnames @props.className, styles.container},
			if (@props.renderEdit? and @props.enableEdit and @state.isBeingEdited) or @props.forceEdit is true
				# div {className: cnames styles.writeComponent, if @props.standardSize then styles.standardSize},
				Fragment {},

					@props.renderEdit
						tempValue: @state.tempValue
						placeholder: @props.placeholder
						onTempChange: (value) =>
							@setState
								tempValue: value
							, @setButtonsPosition
						onBlur: () => if @props.enableBlur then @onCancel()

					do => if @props.showButtonsAlways is true or (!@props.hideButtonsWhenNoChange? and (@state.tempValue isnt @props.initialEditValue))
						buttons =
							div {
								className: styles.buttonsGroup
								style: @calculatePosition()
							},
								div {className: styles.buttonWrapper}, Button
									className: styles.button
									onClick: @onConfirm
									isDisabled: (@props.isRequired is true and _.isEmpty @state.tempValue) or (@state.tempValue is @props.initialEditValue)
								, Icon {icon: check, size: 12}
								div {className: styles.buttonWrapper}, Button
									className: styles.button
									onClick: @onCancel
								, Icon {icon: x, size: 12}
						if @state.calculatePosition || @props.forceEdit is true
							ReactDOM.createPortal(buttons, document.body)

			else
				# div {className: cnames styles.readComponent, if @props.standardSize then styles.standardSize},
				@props.renderDisplay
					onClick: (event) =>

						if @props.enableEdit
							event.preventDefault()
							event.stopPropagation()
							setTimeout =>
								@setState {
									isBeingEdited: true
									calculatePosition: false
									tempValue: @props.initialEditValue
								}, @setButtonsPosition
							, 0





