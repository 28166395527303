###
Project specific links and tools
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'
import { matchPath} from 'react-router-dom'
import gql from 'graphql-tag'
# Renderable
import { div, p, span } from 'react-dom-factories'

Fragment = React.createFactory React.Fragment

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _Avatar from '@atlaskit/avatar'
Avatar = React.createFactory _Avatar

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

import _DropdownMenu, { DropdownItem as _DropdownItem} from '@atlaskit/dropdown-menu'
DropdownMenu = React.createFactory _DropdownMenu
DropdownItem = React.createFactory _DropdownItem

import _Badge from '@atlaskit/badge'
Badge = React.createFactory _Badge

import {Link as _Link} from 'react-router-dom'
Link = React.createFactory _Link

import { UserInfoConsumer } from 'libs/userInfo'

import { Query as _Query } from 'react-apollo'
Query = React.createFactory _Query

# Styles
import {
	book # summary
	briefcase # leasing
	trendingUp # modelling
	shield # safety
	users # personnel
	checkCircle # quality controll
	dollarSign # costs
	fileText # documents
	chevronDown
	chevronUp
} from 'react-icons-kit/feather'
import styles from './index.styl'

# Data
import trLegal from 'data/local/legal'
import { RootQuery } from './data'

export class ProjectItem extends React.Component
	@propTypes:
		interactive: PropTypes.bool
		after: PropTypes.func
		navigate: PropTypes.func
		project: PropTypes.shape
			photo: PropTypes.shape
				mini: PropTypes.string
			id: PropTypes.string
			slug: PropTypes.string
			name: PropTypes.string
			city: PropTypes.string
			country: PropTypes.string
	render: ->
		div {
			className: [
				styles.projectItem
				if @props.interactive then styles.interactive
			].join ' '
		},
			Avatar
				component: ({children, className}) ->
					div {
						style:
							pointerEvents: 'none'
						className: className
					}, children
				src: @props.project.photo.mini
				appearance: 'square'
			div {className: styles.description},
				p {className: styles.text}, "#{@props.project.name}"
				p {className: styles.subText}, "#{@props.project.city}, #{@props.project.country}"
			do =>
				if @props.after?
					div {className: styles.after},
						@props.after()

_ProjectItem = React.createFactory ProjectItem

export default class ProjectNavigation extends React.Component
	@propTypes:
		project: PropTypes.string

	matchPath: (path, exact = true) ->
		match = matchPath window.location.pathname,
			path: path
			exact: exact
		match?

	handleDMSCount: (project) ->
		if (project.inboxUrgentCount? and project.inboxUrgentCount isnt 0) or (project.registryUrgentCount? and project.registryUrgentCount isnt 0)
			appearance: 'important'
			count: project.inboxUrgentCount + project.registryUrgentCount
		else
			appearance: 'primary'
			count: project.inboxFreshCount + project.registryFreshCount

	renderItem: (item) ->
		Link {className: styles.itemLink, to: item.href, key: item.key, onClick: @props.onClick},
			div {className: cnames styles.item, if item.isSelected then styles.selectedItem},
				Icon {className: styles.itemIcon, icon: item.icon, size: 20}
				div {},
					item.text
					if item.description
						div {className: styles.itemDescription}, item.description
				if item.count? and item.count isnt 0
					div {className: styles.badge},
						Badge {appearance: if item.badgeAppearance? then item.badgeAppearance else 'primary'},
							item.count
	renderNavigation: ({data, loading, ability}) ->
		sections = [
				key: 'summary'
				items: [
					type: 'item'
					text: 'Summary'
					key: 'summary'
					icon: book
					isSelected: @matchPath '/projects/:project'
					href: "/projects/#{@props.project}"
				]
			,
				type: 'group'
				key: 'operations'
				text: 'Operations'
				items: _.compact [
					if !loading and ability.can 'read', data.project, 'Revenue'
						type: 'item'
						key: 'revenue'
						text: 'Revenue'
						icon: briefcase
						isSelected: @matchPath '/projects/:project/revenue', false
						href: "/projects/#{@props.project}/revenue"
				,
					if !loading and ability.can 'read', data.project, 'Costs'
						type: 'item'
						key: 'costs'
						text: 'Costs'
						icon: dollarSign
						isSelected: @matchPath '/projects/:project/costs', false
						href: "/projects/#{@props.project}/costs"
				,
					if !loading and ability.can 'read', data.project, 'WhatIf'
						type: 'item'
						key: 'modelling'
						text: 'What-if'
						icon: trendingUp
						isSelected: @matchPath '/projects/:project/scenarios', false
						href: "/projects/#{@props.project}/scenarios"
				]
			,
				type: 'group'
				key: 'supervision'
				text: 'Supervision'
				items: _.compact [
					if !loading and ability.can 'read', data.project, 'Safety'
						type: 'item'
						key: 'ehs'
						text: 'Safety'
						icon: shield
						isSelected: @matchPath '/projects/:project/safety', false
						href: "/projects/#{@props.project}/safety"
				,
					if !loading and ability.can 'read', data.project, 'TQT'
						type: 'item'
						key: 'quality'
						text: 'Technical quality'
						icon: checkCircle
						isSelected: @matchPath '/projects/:project/quality', false
						href: "/projects/#{@props.project}/quality"
				]
			,
				type: 'group'
				key: 'misc'
				text: 'Details'
				items: _.compact [
						# if !loading and ability.can 'read', data.project, 'Legal'
						if !loading
							{ count, appearance } = @handleDMSCount data.project
							type: 'item'
							key: 'legal'
							text: trLegal.global.module
							icon: fileText
							isSelected: @matchPath '/projects/:project/legal', false
							href: "/projects/#{@props.project}/legal"
							count: count
							badgeAppearance: appearance
				,
					# if !loading and ability.can 'read', data.project, 'Personel'
					if !loading
						type: 'item'
						key: 'users'
						text: 'Users'
						icon: users
						isSelected: @matchPath '/projects/:project/users', false
						href: "/projects/#{@props.project}/users"
				]
			]
		div {className: styles.sectionsWrapper},
			_.map (_.reject sections, (s) -> _.isEmpty s.items), (section) =>
				div {className: styles.section, key: section.key},
					_.map section.items, (item) =>
						@renderItem(item)
	renderSelector: ({data, loading, ability}) ->
		otherProjects = _.reject(data.projects, slug: @props.project)
		div {className: styles.ProjectSelector},
			if _.isEmpty otherProjects
				_ProjectItem {
					project: _.find data.projects, slug: @props.project
				}
			else
				DropdownMenu
					shouldFitContainer: true
					isLoading: loading
					trigger: do =>
						if !loading
							project = _.find data.projects, slug: @props.project
							_ProjectItem {
								project
								interactive: true
								after: ->
									Icon icon: chevronDown, size: 28
							}
				,
					if _.isEmpty otherProjects
						span {className: styles.emptyList}, 'no other available projects'
					else
						div {className: styles.dropdownContent},
							_.map otherProjects, (project) =>
									DropdownItem {
										key: project.id
										onClick: (event) =>
											@props.history.push "/projects/#{project.slug}"
									},
										_ProjectItem
											project: project

	render: ->
		UserInfoConsumer {}, ({ability, me}) =>
			Query
				query: RootQuery
				variables:
					projectSlug: @props.project
					inboxUrgentFilters: {isUrgent: true, hasApproval: false}
					inboxFreshFilters: {isFresh: true, hasApproval: false}
					registryUrgentFilters: {isUrgent: true, hasApproval: true}
					registryFreshFilters: {isFresh: true, hasApproval: true}
			, (queryResultat) =>
				div {className: styles.ProjectNavigation},
					if queryResultat.loading
						Spinner {}
					else
						Fragment {},
							@renderSelector {...queryResultat, ability}
							@renderNavigation {...queryResultat, ability}
