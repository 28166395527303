export default '''
	type DatesDuration {
		years: Float!
		months: Float!
	}
	type Image {
		original: String!
		mini: String!
	}
	type File {
		id: ID!
		type: FileType!
		original: String!
		mini: String!
	}
	interface EditableMetainfo {
		createdAt: Int!
		createdBy: Actor
		modifiedAt: Int!
		modifiedBy: Actor
	}

	union Actor = Person | Service

	# EntityInterface, maybe?
	interface FileInterface {
		name: String!
		description: String!
		comments: [Comment!]
		annotations: [Annotation!]
		state: DocumentState!
	}

	interface BaselineFileInterface {
		isBaseline: Boolean!
	}

	input PaginationInput {
		# Number of record in the result
		limit: Int = 100
		# Number of records to omit from the start
		skip: Int = 0
	}
'''
