"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
Object.defineProperty(exports, "ACLSchemes", {
  enumerable: true,
  get: function get() {
    return _aclSchemes.default;
  }
});

var _core = _interopRequireDefault(require("lodash/core"));

var _ability = require("@casl/ability");

var _scopes = _interopRequireDefault(require("./scopes"));

var _base = _interopRequireDefault(require("./roles/base"));

var _global = _interopRequireDefault(require("./roles/global"));

var _project = _interopRequireDefault(require("./roles/project"));

var _legalPaths = _interopRequireDefault(require("./roles/legalPaths"));

var _aclSchemes = _interopRequireDefault(require("./acl-schemes"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var unresolveUser;

unresolveUser = function unresolveUser(user) {
  return {
    _id: user.id,
    roles: user.globalRoles,
    projects: _core.default.map(user.projectsPermissions, function (permission) {
      return _objectSpread({}, permission, {
        acl: permission.access
      });
    }),
    groups: _core.default.map(user.groups, function (group) {
      return {
        _id: group.id,
        roles: group.roles
      };
    })
  };
};

function _default(user) {
  var resolved = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var structure = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  var aclAccess, fullScopes, globalRoles, group, i, len, level, levels, pathRoles, permissions, ref, roles, rules, scheme, scopes;

  if (resolved) {
    user = unresolveUser(user);
  }

  fullScopes = (0, _scopes.default)(resolved); // User permissions from roles

  roles = [];
  /* NOTE
  Right now there is assumption about where specific roles can be assigned,
  There should be one list of roles in the system that can be possibly used in any place
  */
  // base

  roles = _core.default.concat(roles, (0, _base.default)(user, resolved)); // global

  if (user.roles != null) {
    globalRoles = (0, _global.default)(user, resolved);
    roles = _core.default.concat(roles, _core.default.compact(_core.default.map(user.roles, function (role) {
      return _core.default.find(globalRoles, {
        slug: role
      });
    })));
  } // group


  if (user.groups != null) {
    pathRoles = (0, _legalPaths.default)(user, structure, resolved);

    _core.default.map(user.groups, function (group) {
      return roles = _core.default.concat(roles, _core.default.compact(_core.default.map(group.roles, function (role) {
        return _core.default.find(pathRoles, {
          slug: role
        });
      })));
    });
  } // project


  if (user.projects != null) {
    _core.default.map(user.projects, function (project) {
      var projectRoles;

      if (project.roles != null) {
        projectRoles = (0, _project.default)(user, project, resolved);
        return roles = _core.default.concat(roles, _core.default.compact(_core.default.map(project.roles, function (role) {
          return _core.default.find(projectRoles, {
            slug: role
          });
        })));
      }
    });
  } // ACL


  aclAccess = []; // Documents ACLs

  for (scheme in _aclSchemes.default) {
    levels = _aclSchemes.default[scheme];

    for (level in levels) {
      scopes = levels[level];
      aclAccess.push({
        scopes: scopes,
        conditions: {
          'acl.scheme': scheme,
          'acl.users': {
            $elemMatch: {
              uid: user._id,
              level: level
            }
          }
        }
      });
    }
  }

  if (user.groups != null) {
    ref = user.groups;

    for (i = 0, len = ref.length; i < len; i++) {
      group = ref[i];

      for (scheme in _aclSchemes.default) {
        levels = _aclSchemes.default[scheme];

        for (level in levels) {
          scopes = levels[level];
          aclAccess.push({
            scopes: scopes,
            conditions: {
              'acl.scheme': scheme,
              'acl.groups': {
                $elemMatch: {
                  guid: group._id,
                  level: level
                }
              }
            }
          });
        }
      }
    }
  } // CASL rules


  permissions = _core.default.concat(roles, aclAccess);
  rules = [];

  _core.default.map(permissions, function (permission) {
    return _core.default.map(permission.scopes, function (scope) {
      if (permission.conditions == null) {
        return rules = _core.default.concat(rules, fullScopes[scope]);
      } else {
        return rules = _core.default.concat(rules, _core.default.map(fullScopes[scope], function (item) {
          return _objectSpread({}, item, {
            conditions: _objectSpread({}, item.conditions, permission.conditions)
          });
        }));
      }
    });
  });

  if (resolved) {
    return new _ability.Ability(rules, {
      subjectName: function subjectName(subject) {
        if (_core.default.isString(subject)) {
          return subject;
        } else {
          switch (subject.__typename) {
            case 'Person':
            case 'Service':
              return 'User';

            default:
              return subject.__typename;
          }
        }
      }
    });
  } else {
    return new _ability.Ability(rules);
  }
}

;