exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2sZWoYR5XZag9YDggKXr3H {\n  position: absolute;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  z-index: 4;\n  box-sizing: border-box;\n}\n._2sZWoYR5XZag9YDggKXr3H ._39HrRbtTN7ak-YkWCxHLIe {\n  display: flex;\n  flex: 1;\n  max-width: 100%;\n  height: 100%;\n  overflow: hidden;\n}\n._2sZWoYR5XZag9YDggKXr3H .MmDffmftCbvh_Z0VHoevb {\n  position: absolute;\n  top: 16px;\n  right: 16px;\n  z-index: 2;\n}\n._3JbrK2J4BuaPx8OGvPDFlf {\n  left: -100%;\n  transition: left 0.5s;\n}\n.TiB_dglFzRiTCN0cRNhhY {\n  left: 0;\n}\n._1LVrTFhSPXyPwo1B-cthT1 {\n  right: -100%;\n  transition: right 0.5s;\n}\n._3haEyLHMUhEtW3O1Df04Zt {\n  right: 0;\n}\n._IJrdSD8HCdv_SIeFlMQV {\n  position: fixed;\n}\n", ""]);

// Exports
exports.locals = {
	"sidePanel": "_2sZWoYR5XZag9YDggKXr3H",
	"insideContent": "_39HrRbtTN7ak-YkWCxHLIe",
	"close": "MmDffmftCbvh_Z0VHoevb",
	"leftSide": "_3JbrK2J4BuaPx8OGvPDFlf",
	"leftSideActive": "TiB_dglFzRiTCN0cRNhhY",
	"rightSide": "_1LVrTFhSPXyPwo1B-cthT1",
	"rightSideActive": "_3haEyLHMUhEtW3O1Df04Zt",
	"fixed": "_IJrdSD8HCdv_SIeFlMQV"
};