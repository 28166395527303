###
List of all project observations
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import {matchPath} from 'react-router-dom'
import Loadable from 'react-loadable'
import moment from 'moment-mini'
import DataAttribute from '@bevy/data-attribute'

# Renderable
import { div, p, ul, li, small, h1, h2, h3, span, img, button } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import { Query as _Query, Mutation as _Mutation } from 'react-apollo'
Query = React.createFactory _Query
Mutation = React.createFactory _Mutation

import _Button from '@bevy/button'
Button = React.createFactory _Button

import { ButtonGroup as _ButtonGroup } from '@atlaskit/button'
ButtonGroup = React.createFactory _ButtonGroup

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _Spinner from '@bevy/spinner'
Spinner = React.createFactory _Spinner

import _HeaderBar from 'components/HeaderBar'
HeaderBar = React.createFactory _HeaderBar

import _SectionHeader from 'components/SectionHeader'
SectionHeader = React.createFactory _SectionHeader

import _ObservationPanel from 'parts/ObservationPanel'
ObservationPanel = React.createFactory _ObservationPanel

import _BIMIssue from './parts/BIMIssue'
BIMIssue = React.createFactory _BIMIssue

import { ModalTransition as _ModalTransition } from '@atlaskit/modal-dialog'
ModalTransition = React.createFactory _ModalTransition

import _Toolbox from 'components/Toolbox'
Toolbox = React.createFactory _Toolbox

import { Redirect as _Redirect} from 'react-router-dom'
Redirect = React.createFactory _Redirect

# Loadable
LoadableObservationsList = Loadable
	loader: -> `import(/* webpackChunkName: "observations-list" */'components/ObservationsList')`
	loading: -> Spinner {}

# Data
import {
	RootQuery
	LinkBIMIssue
} from './data'

# Styles
import styles from './index.styl'
import {
	filter
	search
	chevronsUp
} from 'react-icons-kit/feather'

export default class RevenueOverviewView extends React.Component
	constructor: (props) ->
		super props
		@state =
			issueLinking: null
			activePanel: null

	renderEmptyList: =>
		div {className: styles.emptyState},
			div {},
				img {src: require 'assets/empty-offer.png'}
				p {}, 'No observations in current filters'
				Button
					appearance: 'link'
					# shouldFitContainer: true
					onClick: =>
						@setState filters: emptyFilters
				,
					'Clear filters'

	renderEmptyPreview: =>
		div {className: styles.emptyState},
			div {},
				img {src: require 'assets/empty.png'}
				p {}, 'Select observation from list'

	renderHeader: ->
		div {className: styles.header},
			Toolbox
				className: styles.toolbox

				actions: [
					ButtonGroup {},
						Button
							appearance: 'subtle'
							component: (props) =>
								div {
									...props
									'data-test-id': DataAttribute 'search-button'
									className: cnames [
										props.className
										styles.activeMarkerContainer
									]
								},
									Fragment {},
										div { className: cnames [ styles.activeMarker ] }
										props.children
							# isSelected: @state.activePanel is 'search'
							# onClick: => if @state.activePanel is 'search' then @setState activePanel: '' else @setState activePanel: 'search'
							iconBefore:
								Icon
									icon: search
									size: 18
						Button
							appearance: 'subtle'
							component: (props) =>
								div {
									...props
									'data-test-id': DataAttribute 'filter-button'
									className: cnames [
										props.className
										styles.activeMarkerContainer
									]
								},
									Fragment {},
										div { className: cnames [ styles.activeMarker ] }
										props.children
							# isSelected: @state.activePanel is 'filter'
							# onClick: => if @state.activePanel is 'filter' then @setState activePanel: '' else @setState activePanel: 'filter'
							iconBefore:
								Icon
									icon: filter
									size: 18
						Button
							appearance: 'subtle'
							component: (props) =>
								div {
									...props
									'data-test-id': DataAttribute 'sort-button'
									className: cnames [
										props.className
										styles.activeMarkerContainer
									]
								},
									Fragment {},
										div { className: cnames [ styles.activeMarker ] }
										props.children
							, 'AZ'
				]

	render: ->
		Query
			query: RootQuery
			variables:
				projectSlug: @props.match.params.project
		, ({error, loading, data}) =>
			if loading
				Spinner {size: 'xlarge'}
			else if error
				Spinner {size: 'xlarge'}
			else
				{project, ehsObservations } = data

				openObservation = do =>
					match = matchPath(@props.location.pathname, {path: '/projects/:project/safety/observations/:observation', exact: false})
					if match?
						match.params.observation
					else
						null

				div {className: cnames styles.page},

					@renderHeader {}

					# LEFT
					div {className: styles.content},

						#modal - TODO: remove
						ModalTransition {}, do =>
							if @state.issueLinking?
								Mutation
									mutation: LinkBIMIssue
									awaitRefetchQueries: true
									refetchQueries: [
										'ObservarionListQuery'
									]
									onCompleted: => @setState issueLinking: null
								, (mutate, {loading}) =>
									BIMIssue {
										loading: loading
										title: 'Link observation with new BIM360 issue'
										data: @state.issueLinking
										options:
											types: data.issueTypes
											companies: data.issueCompanies
											locations: data.issueLocations
										onClose: => @setState issueLinking: null
										onSubmit: (form) =>
											mutate
												variables:
													observationID: @state.issueLinking.id
													typeID: form.type.id
													description: form.comment
													companyID: form.company.id
													dueDate: moment(form.dueDate).unix()
													locationID: form.location.id
													attachments: [
														name: 'Image'
														uri: @state.issueLinking.photo.original
													]
								}

						# list
						React.createElement LoadableObservationsList,
							className: styles.listView
							data: ehsObservations
							active: openObservation
							showStatuses: true
							observationLink: (observation) => "/projects/#{@props.match.params.project}/safety/observations/#{observation.id}"
							linkBIMIssue: (observation) =>
								@setState issueLinking: observation
							emptyState: @renderEmptyList()


					# RIGHT
					div {className: styles.details},

						# placeholder
						if !openObservation?
							if _.isEmpty ehsObservations
								@renderEmptyPreview()
							else
								Redirect to: "/projects/#{@props.match.params.project}/safety/observations/#{_.first(ehsObservations).id}"

						#observation details
						else
							ObservationPanel
								route: '/projects/:project/safety/observations/:observation'
								observation: openObservation
								linkBIMIssue: (observation) =>
									@setState issueLinking: observation
