import _ from 'lodash'
import moment from 'moment-mini'
import { alignOffer } from 'data/helpers'

export default (collections, indices) ->
	{
		offers
		opportunities
		tenantMixes
		companies
		costsVariants
		scenarios
		divestments
		documents
		opportunitiesStages
		contexts
	} = collections

	spv: (obj, args, context, info) ->
		companies.findOne id: obj.spvID
	documents: (obj, args, context, info) ->
		{filters, sortKey, sortOrder} = args
		query = {}
		# filters
		if filters?
			conditions = []
			if filters.contractors? and !_.isEmpty filters.contractors then conditions.push contractorID: $in: filters.contractors

			if filters.types? and !_.isEmpty filters.types then conditions.push type: $in: filters.types
			if filters.topicIDs? and !_.isEmpty filters.topicIDs then conditions.push topicID: $in: filters.topicIDs

			if filters.assignees? and !_.isEmpty filters.assignees then conditions.push asigneeID: $in: filters.assignees
			if filters.statuses? and !_.isEmpty filters.statuses then conditions.push status: $in: filters.statuses

			if filters.locations? and !_.isEmpty filters.locations then conditions.push location: $in: filters.locations
			if filters.vdrStatuses? and !_.isEmpty filters.vdrStatuses then conditions.push vdrStatus: $in: filters.vdrStatuses

			if conditions.length is 1
				query = _.first conditions
			else
				query = $and: conditions

		# sorting
		if sortOrder is 'desc'
			sortOrderBoolean = true
		else
			sortOrderBoolean = false

		# pagination
		pagination = _.defaultsDeep args.pagination, {skip: 0 , limit: 30}

		documents.chain()
			.find {
				...query
				companyID: obj.spvID
			}
			.simplesort(sortKey || '', sortOrderBoolean)
			.offset pagination.skip
			.limit pagination.limit
			.data()

	documentsStats: (obj, args, context, info) ->
		projectDocs = documents.find { companyID: obj.spvID }
		topicsGroups = _.groupBy projectDocs, 'topic'
		stats =
			__typename: 'DocumentsStats'
			all: _.size projectDocs
			topics: _.map topicsGroups, (topicGroup, topic) ->
				typesGroups = _.groupBy topicGroup, 'type'
				__typename: 'DocumentTopicStats'
				topic: topic
				all: _.size topicGroup
				types: _.map typesGroups, (typeGroup, type) ->
					__typename: 'DocumentTypeStats'
					type: type
					all: _.size typeGroup
		stats
	opportunities: (obj, args, context, info) ->
		query = projectID: obj.id
		if args?.filter?
			if args.filter.closed?
				operator = if args.filter.closed is true then '$in' else '$nin'

				stageQuery = type: "#{operator}": ['Closed/Lost', 'Closed/Won']

				query.stageID = $in: _.compact _.map opportunitiesStages.find(stageQuery), (stage) ->
					if (args.filter.stage and stage.id in args.filter.stage) or !args.filter.stage?
						stage.id
			else if !_.isEmpty args.filter.stage
				query.stageID = $in: args.filter.stage
			if !_.isEmpty args.filter.closingDateYear
				timeRanges = _.reduce args.filter.closingDateYear.sort(), (acc, year) ->
					currentRage = _.last acc
					if !currentRage?
						currentRage =
							from: year
							to: year + 1
						acc.push currentRage
					else if currentRage.to is year
						currentRage.to = year + 1
					else
						acc.push
							from: year
							to: year + 1
					acc
				, []
				timeRangeQuery = _.map timeRanges, (range) ->
					query.closeDate = $between: [
						moment(year: range.from).startOf('year').unix()
						moment(year: range.to).endOf('year').unix() - 1
					]
		opportunities.find query
	offers: (obj, args, context, info) ->
		filters = {}

		if args?.filter?
			# Full text search filter
			# search: String
			# Opportunity stage filter
			# stage: [OpportunityStageID!]
			# Offer state filter
			# state: [LeasingOfferState!]
			if args.filter.search? and !_.isEmpty args.filter.search
				filters.id = $in: _.map indices.offers.search(args.filter.search), 'docId'

			stageFilterActive = args.filter.stage? and !_.isEmpty args.filter.stage
			yearsFilterActive = args.filter.closingDateYear? and !_.isEmpty args.filter.closingDateYear
			if stageFilterActive or yearsFilterActive
				filters.opportunityID = $in: do ->
					timeRangeQuery = null
					if yearsFilterActive
						years = args.filter.closingDateYear.sort()
						timeRanges = _.reduce years, (acc, year) ->
							currentRage = _.last acc
							if !currentRage?
								currentRage =
									from: year
									to: year + 1
								acc.push currentRage
							else if currentRage.to is year
								currentRage.to = year + 1
							else
								acc.push
									from: year
									to: year + 1
							acc
						, []
						timeRangeQuery = _.map timeRanges, (range) ->
							closeDate: $between: [
								moment(year: range.from).startOf('year').unix()
								moment(year: range.to).endOf('year').unix() - 1
							]

					opportunitiesInQuery = opportunities.find $and: _.compact [
						if stageFilterActive then stageID: $in: args.filter.stage
						if timeRangeQuery? then $or: timeRangeQuery
					]

					_.map opportunitiesInQuery, 'id'
			if args.filter.status? and !_.isEmpty args.filter.status
				filters.status = $in: args.filter.status
			if args.filter.opportunityID?
				filters.opportunityID = args.filter.opportunityID

		project = obj
		projectOffers = offers.find {
			...filters
			projectID: obj.id
			planID: null
		}
		{building} = contexts.findOne id: project.contextID
		projectOffers = _.map projectOffers, (offer) -> alignOffer offer, building
		_.reject projectOffers, (offer) ->
			if args?.filter?
				if args.filter.floor? and !_.isEmpty args.filter.floor
					floors = _.map offer.spaces, 'floor'
					if _.isEmpty _.intersection args.filter.floor, floors
						return true
				if args.filter.type?  and !_.isEmpty args.filter.type
					types = _.map offer.spaces, 'type'
					if _.isEmpty _.intersection args.filter.type, types
						return true
				if args.filter.areas? and !_.isEmpty args.filter.areas
					validAreas = _.filter offer.spaces, (space) ->
						_.some args.filter.areas, (area) ->
							area.type is space.type and area.floor is space.floor
					if _.isEmpty validAreas
						return true
				if args.filter.volume?
					# GT
					# GTE
					# LT
					# LTE
					volume = _.sumBy offer.spaces, 'volume.base'
					switch args.filter.volume.operator
						when 'GT'
							if volume <= args.filter.volume.value
								return true
						when 'GTE'
							if volume < args.filter.volume.value
								return true
						when 'LT'
							if volume >= args.filter.volume.value
								return true
						when 'LTE'
							if volume > args.filter.volume.value
								return true
					# console.log offer.spaces.volume.base
			false
			# Filter for volume of leasing offer
			# volume: NumericFilter
			# Filter for floor of leasing offer spaces
			# floor: NumericFilter
			# Section types filter for leasing offers
			# section: [FloorSectionType!]
			# Specific areas filter
			# areas: [FloorSectionFilter!]
	numberOfOpportunities: (obj, args, context, info) ->
		opportunities.count projectID: obj.id
	tenantMixes: (obj, args, context, info) ->
		tenantMixes.find projectID: obj.id, root: $where: (value) -> !value?
	tenantMix: (obj, args, context, info) ->
		tenantMixes.findOne id: args.id
	openAccounts: (obj, args, context, info) ->
		# TODO!: Test if this filtering works
		companiesAccounts = companies.find
			id:
				$nin: _.map opportunities.find(projectID: obj.id), 'accountID'
			categories: $contains: 'Client'
		_.map companiesAccounts, (account) -> {
			...account
			__typename: 'Account'
		}
	costsTables: (obj, args, context, info) ->
		if args?.type?
			switch args.type
				when 'Draft'
					costsVariants.find projectID: obj.id, commited: false
				when 'Forecast'
					_.tail costsVariants.chain().find(projectID: obj.id, commited: true).simplesort('date', true).data()
				else
					costsVariants.find projectID: obj.id
		else
			costsVariants.find projectID: obj.id
	costsTable: (obj, args, context, info) ->
		if args?.id?
			costsVariants.findOne projectID: obj.id, id: args.id
		else
			# TODO!: Select last modified committed
			_.first costsVariants.chain().find({projectID: obj.id, _isBaseline: true}).simplesort('metaInfo.modifiedAt', true).data()
	scenarios: (obj, args, context, info) ->
		scenarios.find(projectID: obj.id)
	scenario: (obj, args, context, info) ->
		scenarios.findOne {projectID: obj.id, id: args.id}
	divestments: (obj, args, context, info) ->
		divestments.find(projectID: obj.id)
	divestment: (obj, args, context, info) ->
		divestments.findOne {projectID: obj.id, id: args.id}

	baseline: (obj, args, context, info) ->
		contexts.findOne id: obj.contextID
