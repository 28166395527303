import _ from 'lodash'

import { resolveMetaInfo } from 'data/helpers'
export default (collections) ->
	{
		projects
		users
		companies
		documentsTopics
	} = collections
	{
		...resolveMetaInfo collections
		name: (obj, args, context, info) ->
			if obj.status is 'BasicInfo'
				"doc. from #{companies.findOne(id: obj.contractorID).name}"
			else
				documentsTopics.findOne(id: obj.topicID).name
		description: (obj, args, context, info) ->
			if obj.description? then obj.description else null
		type: (obj, args, context, info) ->
			if obj.type? then obj.type else null
		topic: (obj, args, context, info) ->
			if obj.topicID?
				documentsTopics.findOne id: obj.topicID
			else
				null
		asignee: (obj, args, context, info) ->
			users.findOne id: obj.asigneeID
		company: (obj, args, context, info) ->
			companies.findOne id: obj.companyID
		contractor: (obj, args, context, info) ->
			companies.findOne id: obj.contractorID
	}
