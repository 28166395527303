import _ from 'lodash'
import {
	getSignedSpaces
} from 'data/helpers'


export default (collections) ->
	{
		projects
		offers
	} = collections

	###
	Baseline tenant mix is composition of all signed spaces
	###
	tenantMix: (obj, args, context, info) ->
		_.first collections.tenantMixes.chain().find({projectID: obj.projectID, _isBaseline: true}).simplesort('metaInfo.modifiedAt', true).data()
		# activeOffers

		# {allWonSpaces, allWonLeasingOffers} = getSignedSpaces obj.projectID, collections

		# {
		# 	__typename: 'TenantMix'
		# 	id: "#{obj.projectID}-baseline-tenant-mix"
		# 	name: 'Baseline tenant mix'
		# 	projectID: obj.projectID
		# 	offersIDS: _.map allWonLeasingOffers, 'id'
		# 	overrides: []
		# }
