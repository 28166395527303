"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;

var _core = _interopRequireDefault(require("lodash/core"));

var _slugify = _interopRequireDefault(require("slugify"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _default(user) {
  var resolved = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return _core.default.map([{
    // User self
    name: 'Self',
    scopes: ['UserRead'],
    conditions: resolved ? {
      'id': user._id
    } : {
      '_id': user._id
    }
  }, {
    // Organizations public
    name: 'Public organizations',
    scopes: ['OrganizationCreate', 'OrganizationRead']
  }, {
    // Legal Document assignee
    name: 'Legal Document assignee',
    scopes: ['LegalDocumentReadDraft', 'LegalDocumentReadCommited', 'LegalDocumentPrintQRCode', 'LegalDocumentUpdateACL', 'LegalDocumentUpdateScan', 'LegalDocumentUpdateDetails', 'LegalDocumentUpdateReview', 'LegalDocumentUpdateAssignee', 'LegalDocumentDeleteDraft'],
    conditions: resolved ? {
      // TODO global property rename
      'asignee.id': user._id
    } : {
      'assigneeData.id': user._id
    }
  }], function (item) {
    return _objectSpread({}, item, {
      slug: (0, _slugify.default)(item.name)
    });
  });
}

;