import gql from 'graphql-tag'
import {
	ProjectsList
} from './query.graphql'

export RootQuery = gql """
	query{
		projects {
			#{ProjectsList}
		}
	}
"""
