"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

/*
 Mapping between project access levels and scopes
 Serves as single point of truth for scopes attached to levels
*/
var _default = {
  // Right now we don't use project's ACL, apart from listing members on a project
  // Later on it should be used to make private projects (mainly to deny access for people with strong global roles)
  Write: []
};
exports.default = _default;