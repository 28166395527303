"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;

/*
 Scopes regarding access to legal documents
*/
function _default() {
  var resolved = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  return {
    LegalDocument: [{
      subject: 'LegalDocument',
      actions: 'crud'
    }],
    LegalDocumentCreate: [{
      subject: 'LegalDocument',
      actions: 'create'
    }],
    LegalDocumentRead: [{
      subject: 'LegalDocument',
      actions: 'read'
    }],
    LegalDocumentReadDraft: [{
      subject: 'LegalDocument',
      actions: 'read',
      conditions: {
        state: 'Draft'
      }
    }],
    LegalDocumentReadCommited: [{
      subject: 'LegalDocument',
      actions: 'read',
      conditions: {
        state: 'Commited'
      }
    }],
    LegalDocumentUpdate: [{
      subject: 'LegalDocument',
      actions: 'update'
    }],
    LegalDocumentUpdateDetails: [{
      subject: 'LegalDocument',
      actions: 'update',
      fields: 'details'
    }],
    LegalDocumentUpdateScan: [{
      subject: 'LegalDocument',
      actions: 'update',
      fields: 'scan'
    }],
    LegalDocumentUpdateAssignee: [{
      subject: 'LegalDocument',
      actions: 'update',
      fields: 'assignee'
    }],
    LegalDocumentUpdateACL: [{
      subject: 'LegalDocument',
      actions: 'update',
      fields: 'acl'
    }],
    LegalDocumentUpdateReview: [{
      subject: 'LegalDocument',
      actions: 'update',
      fields: 'review'
    }],
    LegalDocumentUpdateState: [{
      subject: 'LegalDocument',
      actions: 'update',
      fields: 'state'
    }],
    LegalDocumentDelete: [{
      subject: 'LegalDocument',
      actions: 'delete'
    }],
    LegalDocumentDeleteDraft: [{
      subject: 'LegalDocument',
      actions: 'delete',
      conditions: {
        state: 'Draft'
      }
    }],
    LegalDocumentDeleteCommited: [{
      subject: 'LegalDocument',
      actions: 'delete',
      conditions: {
        state: 'Commited'
      }
    }],
    // read content marked as external
    LegalDocumentExternal: [{
      subject: 'LegalDocument',
      actions: 'external'
    }]
  };
}

;