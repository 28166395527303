import _ from 'lodash'

export default (collections) ->
	{companies} = collections

	company: (obj, args, context, info) ->
		company = companies.findOne id: obj.companyID
		{
			...company
			__typename: do ->
				if 'Client' in company.categories
					'Account'
				else if 'Internal' in company.categories
					'Corporation'
				else if 'Office' in company.categories
					'Office'
				else if 'Partner' in company.categories
					'Contractor'
		}
