###
Display component for project card
###

# Libs
import _ from 'lodash'
import React from 'react'
import moment from 'moment-mini'
import PropTypes from 'prop-types'
# Renderable
import { div, img, span, a } from 'react-dom-factories'

Fragment = React.createFactory React.Fragment

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _Lozenge from '@atlaskit/lozenge'
Lozenge = React.createFactory _Lozenge

# Styles
import styles from './index.styl'
import {
	moreHorizontal
} from 'react-icons-kit/feather'

getProjectStatus = (timeline) ->
	if Date.now() < timeline.start * 1000
		label: 'Yet to start'
		appearance: 'default'
	else if Date.now() < timeline.end * 1000
		label: 'In progress'
		appearance: 'inprogress'
	else
		label: 'Ended'
		appearance: 'moved'

export default class ProjectCard extends React.Component
	@propTypes:
		href: PropTypes.string
		onClick: PropTypes.func
		className: PropTypes.string
		thumbnail: PropTypes.string
		startDate: PropTypes.number
		endDate: PropTypes.number
		name: PropTypes.string
		onMore: PropTypes.bool
		country: PropTypes.string
		city: PropTypes.string
	render: ->
		div {
			href: @props.href
			onClick: @props.onClick
			className: _.compact([
				styles.cardWrapper
				@props.className
				'bevy-projectcard-cardwrapper'
			]).join ' '
		},
			div {className: styles.card},
				div {},
					img
						className: styles.thumbnail
						src: @props.thumbnail
					do =>
						status = getProjectStatus {start: @props.startDate, end: @props.endDate}
						div {style: textAlign: 'center'},
							Lozenge
								appearance: status.appearance
							,
								div {style: width: 112}, status.label
				div {className: styles.cardContent},
					div {className: styles.main},
						span {className: styles.name}, @props.name
						do =>
							if @props.onMore?
								span {onClick: @props.onMore}, Icon size: 12, icon: moreHorizontal
					do =>
						items = [
								label: 'Country'
								value: @props.country
							,
								label: 'City'
								value: @props.city
						]
						if @props.startDate?
							items.push
								label: 'Start Date'
								value: moment(@props.startDate * 1000).format('DD-MM-YYYY')
						if @props.endDate?
							items.push
								label: 'End Date'
								value: moment(@props.endDate * 1000).format('DD-MM-YYYY')

						_.map items, (item) ->
							div {className: styles.item, key: item.label},
								span {className: styles.label}, item.label
								span {className: styles.value}, item.value
