exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._373nBmRDpCftzMLwHcLA5S {\n  display: flex;\n}\n._373nBmRDpCftzMLwHcLA5S h1,\n._373nBmRDpCftzMLwHcLA5S h2,\n._373nBmRDpCftzMLwHcLA5S h3 {\n  font-weight: 500;\n  margin-bottom: 0;\n  padding-left: 6px;\n}\n._1fCqKkKiedbA_C4evLSeV9 {\n  text-decoration: none;\n  color: inherit;\n}\n._1vMTa3JS-dVp1-QOx13P2T {\n  flex: 1;\n  padding: 10px 0;\n}\n.Gsq3K-0IiO_DErO2dMaWG {\n  height: 100%;\n}\n.XOWxIwX9MH1uADJ8wXslo {\n  margin-left: auto;\n  position: relative;\n  margin-top: 8px;\n  padding: 8px;\n  padding-bottom: 6px;\n}\n._2BfLaEo49r7MkffOLnaJxP {\n  position: relative;\n  margin-right: 12px;\n  margin-top: 8px;\n  padding: 8px;\n  padding-bottom: 6px;\n  color: #8993a4;\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 1;\n}\n._2BfLaEo49r7MkffOLnaJxP p {\n  margin: 0;\n  margin-bottom: 2px;\n}\n._2BfLaEo49r7MkffOLnaJxP ._1qA89I0a6wTjFcwNJK2oU4 {\n  font-size: 110%;\n  font-weight: 500;\n}\n._2BfLaEo49r7MkffOLnaJxP .WgzQVSED6fN9eheuZo6sY {\n  font-weight: 600;\n  font-size: 180%;\n  color: #172b4d;\n}\n", ""]);

// Exports
exports.locals = {
	"page": "_373nBmRDpCftzMLwHcLA5S",
	"link": "_1fCqKkKiedbA_C4evLSeV9",
	"tabContent": "_1vMTa3JS-dVp1-QOx13P2T",
	"skeletonBox": "Gsq3K-0IiO_DErO2dMaWG",
	"footer": "XOWxIwX9MH1uADJ8wXslo",
	"header": "_2BfLaEo49r7MkffOLnaJxP",
	"text": "_1qA89I0a6wTjFcwNJK2oU4",
	"subtext": "WgzQVSED6fN9eheuZo6sY"
};