import _ from 'lodash'

export default (uri, options) ->
	if options.onUploadProgress?
		uploadFetch(uri, options)
	else
		fetch(uri, options)

parseHeaders = (rawHeaders) ->
	headers = new Headers()
	preProcessedHeaders = rawHeaders.replace /\r?\n[\t ]+/g, ' '
	preProcessedHeaders.split(/\r?\n/).forEach (line) ->
		parts = line.split ':'
		key = parts.shift().trim()
		if !_.isEmpty key
			value = parts.join(':').trim()
			headers.append key, value
	headers

uploadFetch = (url, options) ->
	new Promise (resolve, reject) ->
		xhr = new XMLHttpRequest()
		xhr.onload = ->
			opts =
				status: xhr.status,
				statusText: xhr.statusText,
				headers: parseHeaders xhr.getAllResponseHeaders() || ""
				url: if xhr.responseURL? then xhr.responseURL else opts.headers.get 'X-Request-URL'
			body = if xhr.response? then xhr.response else xhr.responseText
			resolve new Response body, opts
		xhr.onerror = -> reject  new TypeError 'Network request failed'
		xhr.ontimeout = -> reject new TypeError 'Network request failed'
		xhr.open options.method, url, true
		if xhr.upload?
			xhr.upload.onprogress = (event) -> options.onUploadProgress event, options
		Object.keys(options.headers).forEach (key) ->
			xhr.setRequestHeader key, options.headers[key]
		options.onAbortPossible = xhr.abort
		xhr.send options.body
