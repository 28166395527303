export default '''

	# Building definition related
	type FloorVolume {
		planned: Float!
		measured: Float
		base: Float
	}
	type FloorSection {
		type: FloorSectionType
		serviceCharge: Float
		volume: FloorVolume!
		gla: Float!
		totalGla: Float!
		nla: Float!
	}
	type Floor {
		number: Int!
		sections: [FloorSection!]
		gla: Float!
		totalGla: Float!
		nla: Float!
	}
	type Building {
		floors: [Floor!]
		gla: Float!
		totalGla: Float!
		nla: Float!
		spaceDefinitions: [SpaceType!]!
	}
	type SpaceType {
		name: String!
		isUnit: Boolean!
		size: Float
	}
'''
