# Libs
import React from 'react'
import cnames from 'classnames'

import { div } from 'react-dom-factories'

# Styles
import styles from './index.styl'

export default class Cell extends React.Component
	constructor: (props) ->
		super props

	shouldComponentUpdate: (nextProps) ->
		!(@props.isEven is nextProps.isEven) or
		!(@props.isHovered is nextProps.isHovered) or
		!(@props.isSelected is nextProps.isSelected) or
		!(@props.children.props.row is nextProps.children.props.row) or
		!(@props.style is nextProps.style)

	render: ->
		# console.log 'render cell wrapper'

		div {
			# key: @props.key
			className: cnames _.concat(@props.className, [
					styles.cell
					@props.className
					if @props.spacing is 'compact' then styles.compact
					if @props.isEven then styles.evenRow
					if @props.isHovered then styles.hoveredRow
					if @props.isSelected then styles.selectedRow
			])
			style: @props.style
			onClick: @props.onClick
			onDoubleClick: @props.onDoubleClick
			onMouseOver: @props.onMouseOver
			},
				@props.children
