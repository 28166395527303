###
AutosizeInput in separate wrapper to be controlled component
###

# Libs
import React from 'react'

import _AutosizeInput from 'react-input-autosize'
AutosizeInput = React.createFactory _AutosizeInput

export default class TextInput extends React.Component
	constructor: (props) ->
		super props

	render: ->
		AutosizeInput {
			autoFocus: true
			value: @props.value
			onChange: (e) => @props.onPlaceholderTextChange e.target.value
			onBlur: (e) => @props.onBlur e.target.value
			onKeyPress: (e) => @props.onEnterPress e
			inputStyle: { border: '1px solid #8993a4', maxWidth: '700px', minWidth: '20px' }
		}
