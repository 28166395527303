import _ from 'lodash'
import moment from 'moment-mini'
import Chance from 'chance'
import objectid from 'bson-objectid'
chance = new Chance
import { calculateSpaceDerivedValues } from './calculations'

###
Fixtures helpers
###

# Randomly pick a person out of all fixtures
export pickPerson = ({users, companies, name, company}) ->
	if !users? or !companies?
		throw new Error 'Pick a user without fixtures'

	if name?
		_.find(users, name: name).id
	else if company?
		companyID = _.find(companies, name: company).id
		_.sample(_.filter users, (user) -> user.companyID is companyID).id
	else
		_.sample(users).id

# Generates proper document meta informations with dates, comments, annotations, ...
export createDocumentMetaInfo = (actorID, now = false, actorType = 'Person') ->
	if !actorID?
		throw new Error 'Cannot create document meta information without actor id'

	collection = if actorType is 'Person' then 'users' else 'services'
	createdAt = moment().subtract _.random(15, 42), 'days'
	modifiedAt = createdAt.add(_.random(0, 12), 'days')
	if now is true
		createdAt = modifiedAt = moment()
	else if now instanceof moment
		createdAt = modifiedAt = now

	# Generate meaningfull name
	name: chance.sentence(words: _.random 2, 5).replace /\.$/, ''
	description: chance.paragraph sentences: _.random 1, 4
	comments: []
	annotations: []
	metaInfo:
		__typename: null
		createdBy:
			__typename: null
			collection: collection
			type: actorType
			id: actorID
		createdAt: createdAt.unix()
		modifiedBy:
			__typename: null
			collection: collection
			type: actorType
			id: actorID
		modifiedAt: modifiedAt.unix()
# Resolvers for meta informations e.g. createdBy, ...
export resolveMetaInfo = (collections) ->
	createdAt: (obj, args, context, info) ->
		obj.metaInfo.createdAt
	modifiedAt: (obj, args, context, info) ->
		obj.metaInfo.modifiedAt
	createdBy: (obj, args, context, info) ->
		collections[obj.metaInfo.createdBy.collection].findOne id: obj.metaInfo.createdBy.id
	modifiedBy: (obj, args, context, info) ->
		collections[obj.metaInfo.modifiedBy.collection].findOne id: obj.metaInfo.modifiedBy.id
export metaInfoMutation = (collections, name, actorType = 'Person') ->
	if !name?
		throw new Error 'Meta info mutation must be done by enity with name'
	collection = if actorType is 'Person' then 'users' else 'services'
	actor = collections[collection].findOne name: name
	if !actor?
		throw new Error "Meta info mutation done by entity #{name} does not exists as #{actorType}"


	modifiedBy:
		__typename: null
		collection: collection
		type: actorType
		id: actor.id
	modifiedAt: moment().unix()
# Generate full __typename's for GraphQL
export addTypenamesToSpace = (space) ->
	{
		...space
		__typename: 'LeasingOfferSpace'
		incentives: {
			...space.incentives
			__typename: 'CommercialSpaceIncentives'
		}
		agentFee: {
			...space.agentFee
			__typename: 'CommercialSpaceAgentFee'
		}
		rentFree: {
			...space.rentFree
			__typename: 'CommercialSpaceRentFree'
		}
		fitout: {
			...space.fitout
			__typename: 'CommercialSpaceFitout'
		}
		volume: {
			...space.volume
			__typename: 'FloorVolume'
		}
		grossVolume: {
			...space.grossVolume
			__typename: 'FloorVolume'
		}
		grossValue: {
			...space.grossValue
			__typename: 'CommercialSpaceGrossValue'
		}
		rent: {
			...space.rent
			__typename: 'CommercialSpaceRent'
		}
		effectiveRent: {
			...space.effectiveRent
			__typename: 'CommercialSpaceRent'
		}
		shortfall: {
			...space.shortfall
			__typename: 'Commercialdefinitionshortfall'
		}
		dates: {
			...space.dates
			__typename: 'CommercialSpaceDates'
			term: {
				...space.dates.term
				__typename: 'DatesDuration'
			}
		}
	}

###
Baseline variants
###
generateBaselineDocuments = (fixtures, project) ->
	result =
		tenantMix: null
		divestment: null

	selectSignedOffers = ->
		opps = _.filter fixtures.opportunities, {
			stageID: 'ClosedWon'
			projectID: project.id
		}
		opps = _.map opps, 'id'
		_.filter fixtures.offers, (offer) -> offer.opportunityID in opps

	result.tenantMix = {
		__typename: 'TenantMix'
		...createDocumentMetaInfo _.find(fixtures.services, name: 'Helper').id, true, 'Service'
		id: "baseline-tm-#{project.name}"
		name: 'Baseline'
		projectID: project.id
		state: 'Commited'
		offersIDS: _.map selectSignedOffers(), 'id'
		attachedOffersIDS: []
		# If stacking plan contains vacants it is classified as rent roll
		definedVacants: []
		overrides: []
		_isBaseline: true
		inline: false
	}
	result.divestment = {
		__typename: 'DivestmentOption'
		...createDocumentMetaInfo _.find(fixtures.services, name: 'Helper').id, true, 'Service'
		id: "baseline-divestment-#{project.name}"
		_isBaseline: true
		name: 'Basline divestment assumptions'
		type: null
		projectID: project.id
		state: 'Commited'
		...project.baseline.divestment
		_closingDate: null
		inline: false
	}
	result

export createEmptyContext = (projectID) ->
	{
		id: objectid().toHexString()
		__typename: 'ProjectContext'
		projectID
	}
export addBaselineVariantsFixtures = (fixtures) ->

	for project in fixtures.projects
		{tenantMix, divestment} = generateBaselineDocuments fixtures, project
		fixtures.tenantMixes.push tenantMix
		fixtures.divestments.push divestment
		delete project.baseline

	# Modify each project baseline context
	map =
		costsVariantID: 'costsVariants'
		tenantMixID: 'tenantMixes'
		divestmentID: 'divestments'
	for context in fixtures.contexts
		for key, val of map
			objs = _.filter fixtures[val], {
				projectID: context.projectID
				_isBaseline: true
			}
			if !_.isEmpty objs
				context[key] = _.first(_.orderBy(objs, 'metaInfo.modifiedAt', 'desc')).id

	for key, val of map
		for obj in fixtures[val]
			context = createEmptyContext(obj.projectID)
			context[key] = obj.id
			obj.contextID = context.id
			fixtures.contexts.push context

	# Create contexts for scenarios
	for scenario in fixtures.scenarios
		context = createEmptyContext(scenario.projectID)
		context.tenantMixID = scenario.tenantMixID
		context.costsVariantID = scenario.costsVariantID
		context.divestmentID = scenario.divestmentID

		delete scenario.tenantMixID
		delete scenario.costsVariantID
		delete scenario.divestmentID

		scenario.contextID = context.id
		fixtures.contexts.push context

	# Create divestment option
	fixtures

###
Calculations
###

export alignSpace = (space, building, options) ->
	addTypenamesToSpace calculateSpaceDerivedValues space, building, options
export alignOffer = (offer, building, options) ->
	if options?
		throw new Error 'alignOffer do not support options any more'
	alignedSpaces = _.map offer.spaces, (space) -> alignSpace space, building
	{
		...offer
		spaces: alignedSpaces
	}

export getSignedSpaces = (projectID, collections) ->
	{
		opportunities
		offers
	} = collections


	# Finding all won opportunities in project
	allWonOpportunities = opportunities.find {
		projectID: projectID
		stageID: 'ClosedWon'
	}
	# Finding all leasing offers that was approved by client and are in won opportunity
	allWonLeasingOffers = offers.find {
		projectID: projectID
		opportunityID:
			$in: _.map allWonOpportunities, 'id'
		status: 'ClientApproved'
		tenantMixID: null
	}
	if _.size(allWonLeasingOffers) isnt _.size(allWonOpportunities)
		throw new Error 'Cannot have multiple final offers for single tenant!'
	{
		allWonSpaces: _.flatten _.map allWonLeasingOffers, 'spaces'
		allWonLeasingOffers
		allWonOpportunities
	}
export calculateBuildingKPI = _.memoize (building) ->
	_.reduce building.floors, (acc, floor) ->
		for section in floor.sections
			{volume, type, addonFactor} = section
			glaFactor = 1 + addonFactor || 0
			totalGlaFactor = 1 + addonFactor || 0
			nlaFactor = 1

			if type is 'Garage'
				glaFactor = 0
				totalGlaFactor = 25
				nlaFactor = 0

			acc.gla += if volume.measured? then volume.measured * glaFactor else volume.planned * glaFactor
			acc.totalGla += if volume.measured? then volume.measured * totalGlaFactor else volume.planned * totalGlaFactor
			acc.nla += if volume.measured? then volume.measured * nlaFactor else volume.planned * nlaFactor
		acc
	,
		gla: 0
		totalGla: 0
		nla: 0



	gla: do ->
		_.sumBy building.floors, (floor) ->
			_.sumBy floor.sections, ({volume, type, addonFactor}) ->
				if type is 'Garage'
					0
				else
					factor = addonFactor || 0
					factor += 1
					if volume.measured? then volume.measured * factor else volume.planned * factor
	totalGla: do ->
		_.sumBy building.floors, (floor) ->
			_.sumBy floor.sections, ({volume, type, addonFactor}) ->
				if type is 'Garage'
					factor = 25
					if volume.measured? then volume.measured * factor else volume.planned * factor
				else
					factor = addonFactor || 0
					factor += 1
					if volume.measured? then volume.measured * factor else volume.planned * factor
	nla: do ->
		_.sumBy building.floors, (floor) ->
			_.sumBy floor.sections, ({volume, type}) ->
				if type is 'Garage'
					0
				else
					if volume.measured? then volume.measured else volume.planned
export {
	listAllMissingVacantSpaces
	calculateFullResults
	calculateSpaceResult
	getProjectBaselineContext
	expandContext
	trimContext
	} from './calculations'
