import _ from 'lodash'

export {default as schema} from './graphql'
export {default as resolvers} from './resolvers'
export {default as fixtures} from './fixtures/all.json'

export indexators =
	offers: (db, index) ->
		index.addField 'name', boost: 1
		index.addField 'accountName', boost: 5
		index.addField 'stageName', boost: 2
		index.addField 'stageType', boost: 1
		index.addField 'stageCategory', boost: 1
		index.addField 'stateName', boost: 2

		_companies = db.getCollection('companies').find()
		_opportunities = db.getCollection('opportunities').find()
		_opportunitiesStages = db.getCollection('opportunitiesStages').find()
		_offers = db.getCollection('offers').find()
		for offer in _offers
			_opportunity = _.find _opportunities, id: offer.opportunityID
			_opportunityStage = _.find _opportunitiesStages, id: _opportunity.stageID
			index.add offer.id,
				name: offer.name.toString()
				accountName: _.find(_companies, id: offer.accountID).name
				stageName: _opportunityStage.name
				stageType: _opportunityStage.type
				stageCategory: _opportunityStage.category
				stateName: offer.state
