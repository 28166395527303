
###
Multiselect with search and custom display options
###

# Libs
import _ from 'lodash'
import React from 'react'
import cnames from 'classnames'
import PropTypes from 'prop-types'
# Renderable
import { div } from 'react-dom-factories'
import { components } from 'react-select'

Fragment = React.createFactory React.Fragment

import { Icon as _Icon } from 'react-icons-kit'
Icon = React.createFactory _Icon

import _Select from '@atlaskit/select'
Select = React.createFactory _Select

# Styles
import styles from './index.styl'

import { androidCheckbox } from 'react-icons-kit/ionicons/androidCheckbox'
import { androidCheckboxBlank } from 'react-icons-kit/ionicons/androidCheckboxBlank'
import { androidCheckboxOutline } from 'react-icons-kit/ionicons/androidCheckboxOutline'
import { androidCheckboxOutlineBlank } from 'react-icons-kit/ionicons/androidCheckboxOutlineBlank'
import { closeCircled } from 'react-icons-kit/ionicons/closeCircled'


import {
	chevronDown
} from 'react-icons-kit/feather'

CustomValueContainer = (props) ->
	values = props.getValue()
	inputChild = _.last props.children
	React.createElement components.ValueContainer, {...props},
		if _.isEmpty values
			props.children
		else
			Fragment {},
				div className: styles.values,
					_.map((_.sortBy values, 'value'), 'label').join ', '
				inputChild

class CheckboxOption extends React.Component
	constructor: (props) ->
		super props
		@state =
			isActive: false
	onMouseDown: =>
		@setState isActive: true
	onMouseUp: =>
		@setState isActive: false
	onMouseLeave: =>
		@setState isActive: false
	render: ->
		{
			getStyles
			isDisabled
			isFocused
			isSelected
			children
			innerProps
			setValue
			getValue
			...rest
		} = @props
		bgColor = 'transparent'
		if isFocused then bgColor = '#F4F5F7'
		if @state.isActive then bgColor = '#DEEBFF'
		React.createElement components.Option, {
			isDisabled: isDisabled
			isFocused: isFocused
			isSelected: isSelected
			getStyles: getStyles
			innerProps: {
				...innerProps
				onMouseDown: @onMouseDown
				onMouseUp: @onMouseUp
				onMouseLeave: @onMouseLeave
				style:
					alignItems: 'center'
					backgroundColor: bgColor
					color: 'inherit'
					display: 'flex '
			}
			...rest
		},
			div {className: styles.checkboxWrapper},
				div {className: cnames [
					styles.checkboxWrapperIcon
					if isSelected then styles.checkboxWrapperIconSelected
				]},
					Icon
						icon: if isSelected then androidCheckboxOutline else androidCheckboxBlank
						size: 14
				div {className: styles.checkboxWrapperText},
					children


export default class DropdownSelector extends React.Component
	@propTypes:
		simpleValueContainer: PropTypes.bool
		spacing: PropTypes.oneOf [
			'compact'
			'default'
		]
	constructor: (props) ->
		super props
		@components = {}
		if @props.simpleValueContainer? and @props.simpleValueContainer
			@components.ValueContainer = CustomValueContainer
		@components.DropdownIndicator = (props) ->
			Icon
				icon: chevronDown
				size: 20
		@components.ClearIndicator = (props) ->
			div {
				...props.innerProps
				className: styles.clearIndicator
			},
				Icon
					icon: closeCircled
					size: 12
		@components.Option = CheckboxOption
	render: ->
		Select {
			isMulti: true
			closeMenuOnSelect: false
			menuPortalTarget: document.body
			hideSelectedOptions: false
			components: @components
			...@props
			styles: do =>
				baseStyles =
					control: (base) ->
						{
							...base
							backgroundColor: '#f1f2f5'
							borderColor: '#f1f2f5'
						}
					valueContainer: (base) ->
						{
							...base
							flexWrap: 'nowrap'
						}
					menuPortal: (base) ->
						{
							...base
							zIndex: 9999
						}
				if @props.spacing is 'compact'
					baseStyles = {
						...baseStyles
						input: (styles) ->
							styles.paddingBottom = 0
							styles.paddingTop = 0
							styles
						control: (styles) ->
							styles.minHeight = 32
							styles
					}
				baseStyles

		}
