"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;

var _core = _interopRequireDefault(require("lodash/core"));

var _slugify = _interopRequireDefault(require("slugify"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _default(user, project) {
  var resolved = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  return _core.default.map([{
    name: 'Project Leader',
    // 'ProjectRead', 'ProjectUpdate', 'ProjectDelete'
    scopes: ['ProjectReadPersonel', 'ProjectReadLegal', 'LegalDocument', 'LegalDocumentExternal']
  }, {
    name: 'Project Manager',
    // 'ProjectRead', 'ProjectUpdate', 'ProjectDelete'
    scopes: ['ProjectReadPersonel', 'ProjectReadLegal', 'LegalDocument', 'LegalDocumentExternal']
  }, {
    name: 'Document Manager',
    // 'ProjectRead', 'ProjectUpdate', 'ProjectDelete'
    scopes: ['ProjectReadPersonel', 'ProjectReadLegal', 'LegalDocument', 'LegalDocumentExternal']
  }, {
    name: 'Legal User',
    scopes: ['ProjectReadPersonel', 'ProjectReadLegal', 'LegalDocumentCreate']
  }, {
    name: 'Spectator',
    scopes: ['ProjectReadPersonel', 'ProjectReadLegal']
  }, {
    name: 'Safety Tester',
    scopes: ['ProjectReadSafety']
  }, {
    name: 'Finance Tester',
    scopes: ['ProjectReadPersonel', 'ProjectReadRevenue']
  }], function (item) {
    return _objectSpread({}, item, {
      slug: (0, _slugify.default)(item.name),
      conditions: item.conditions == null ? {
        projectID: project.projectID
      } : _objectSpread({}, item.conditions, {
        projectID: project.projectID
      })
    });
  });
}

;