import _ from 'lodash'
import { ApolloLink, Observable } from 'apollo-link'

import {
	selectURI
	selectHttpOptionsAndBody
	fallbackHttpConfig
	serializeFetchParameter
	createSignalIfSupported
	parseAndCheckHttpResponse
} from 'apollo-link-http-common'
import { extractFiles, ReactNativeFile as _ReactNativeFile  } from 'extract-files'
# Re-export react native file
export ReactNativeFile = _ReactNativeFile


export default class S3UoloadLink extends ApolloLink
	constructor: (@sendFile) ->
		super()
	request: (operation, forward) ->
		new Observable (observer) =>
			{ clone, files } = extractFiles(operation.variables)
			mapOfPromises = []
			files.forEach (paths, file) =>
				mapOfPromises.push new Promise (resolve, reject) =>
					Promise.resolve @sendFile file
					.then (id) -> resolve {id, paths, file}
					.catch reject
			Promise.all mapOfPromises
			.catch observer.error.bind(observer)
			.then (results) ->
				if !_.isEmpty results
					for result in results
						for path in result.paths
							_.set operation.variables, path, {
								id: result.id
								type: result.file.type
								name: result.file.name
							}
				handle = forward(operation).subscribe
					next: observer.next.bind(observer)
					error: observer.error.bind(observer)
					complete: observer.complete.bind(observer)
			return ->
				handle.unsubscribe() if handle?
