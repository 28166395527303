import gql from 'graphql-tag'

export LoggedInUser = gql """
	query {
		me {
			id
			name
			globalRoles
			picture {
				mini
			}
			projectsPermissions {
				projectID
				roles
			}
		}
	}
"""
