###
Table View Component
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'

# Renderable
import { div, table, thead, tbody, tr, th, td } from 'react-dom-factories'

Fragment = React.createFactory React.Fragment

import _Cell from './components/Cell'
Cell = React.createFactory _Cell

import { Checkbox as _Checkbox } from '@atlaskit/checkbox'
Checkbox = React.createFactory _Checkbox

import _Spinner from '@atlaskit/spinner'
Spinner = React.createFactory _Spinner

# Styles
import styles from './index.styl'


class TableCell extends React.PureComponent
	render: ->
		@props.column.cell(@props.row)


class CheckboxCell extends React.Component
	shouldComponentUpdate: (nextProps) ->
		!(@props.isChecked is nextProps.isChecked) or !(@props.isHovered is nextProps.isHovered)

	render: ->
		div {className: cnames styles.checkbox, if !@props.isHovered and !@props.isChecked then styles.hidden},
			Checkbox
				onClick: (e) -> e.stopPropagation()
				onChange: @props.onChange
				isChecked: @props.isChecked
				isIndeterminate: false


export default class TableView extends React.Component
	@propTypes:
		head: PropTypes.shape(
			cells: PropTypes.arrayOf(PropTypes.shape
				key: PropTypes.oneOfType([
					PropTypes.number
					PropTypes.string
				])
				content: PropTypes.node
			)
		)
		rows: PropTypes.arrayOf(PropTypes.shape
			key: PropTypes.oneOfType([
				PropTypes.number
				PropTypes.string
			])
			cells: PropTypes.arrayOf(PropTypes.shape
				key: PropTypes.oneOfType([
					PropTypes.number
					PropTypes.string
				])
			)
		)

	@defaultProps:
		renderCell: React.createFactory TableCell


	constructor: (props) ->
		super props
		@state =
			hoveredRow: null
			hoverLocked: false

	handleRowSelect: (row, e) =>
		if e? and (e.ctrlKey or e.metaKey)
			if row.id in @props.selectedRows
				@props.onSelectionChange _.without(@props.selectedRows, row.id)
			else
				@props.onSelectionChange [...@props.selectedRows, row.id]
		else
			if row.id in @props.selectedRows and _.size(@props.selectedRows) is 1
				@props.onSelectionChange []
			else
				@props.onSelectionChange row.id

	handleCheckboxSelect: (row, e) =>
		e.stopPropagation()
		if row.id in @props.selectedRows
			@props.onSelectionChange _.without(@props.selectedRows, row.id)
		else
			@props.onSelectionChange [...@props.selectedRows, row.id]

	render: ->
		Fragment {},
			div
				className: cnames [
					styles.spinner
					if !@props.isLoading then styles.hidden
				]
			, Spinner {}
			div {className: styles.content},
				div
					className: cnames [
						styles.overlay
						if !@props.isLoading then styles.hidden
					]
				div {
					className: cnames [
						styles.base
						@props.className
					]
					style:
						# gridTemplateColumns: "repeat(#{_.size(@props.head.cells)}, min-content)"
						gridAutoRows: if @props.rowHeight? then @props.rowHeight
						gridTemplateColumns: do =>
							content = _.map(@props.head.cells, (cell) ->
								if cell.width?
									if _.isNumber cell.width
										"#{cell.width}px"
									else
										min = do ->
											if _.isNumber(cell.width.min)
												"#{cell.width.min}px"
											else if cell.width.min?
												cell.width.min
											else
												'auto'
										max = do ->
											if _.isNumber(cell.width.max)
												"#{cell.width.max}px"
											else if cell.width.max?
												cell.width.max
											else
												'1000px'
										"minmax(#{min}, #{max})"
								else
									'min-content'
							)
							columns = _.compact [
								if @props.renderMarker? then '30px'
							,
								'30px'
							,
								...content
							,
								if @props.renderAction? then 'min-content'
							]
							columns.join ' '
				},


					#Headers
					#marker header
					if @props.renderMarker?
						div {className: cnames styles.headerCell, styles.markerColumn}

					#checkbox header
					div {className: cnames styles.headerCell, styles.checkboxColumn, if @props.renderMarker? then styles.secondColumn}

					#content headers
					_.map @props.head.cells, (header, index) =>
						div {
							key: header.key
							className: cnames [
								styles.headerCell
								if header.className? then header.className
								if header.isSortable then styles.sortableHeader
								if @props.sortKey is header.sortKey
									reversed = header.sortReversed? and header.sortReversed
									if @props.sortOrder is 'asc'
										if reversed then styles.sortedDesc else styles.sortedAsc
									else if @props.sortOrder is 'desc'
										if reversed then styles.sortedAsc else styles.sortedDesc
							]
						},
							div {
								className: styles.headerCellContent
								onClick: () => if header.isSortable
									@props.handleSort(header.sortKey, if (@props.sortKey is header.sortKey) and (@props.sortOrder is 'asc') then 'desc' else 'asc')
							}, header.content

					#action header
					if @props.renderAction?
						div {className: cnames styles.headerCell, styles.actionColumn},
							if @props.renderActionHeader? then @props.renderActionHeader()

					#Body
					_.map @props.rows, (row, rowIndex) =>
						cellProps =
							isEven: rowIndex % 2 is 0
							isSelected: _.includes @props.selectedRows, row.id
							isHovered: @state.hoveredRow is rowIndex
							style: if @props.rowStyling? then @props.rowStyling(row)
							onClick: (e) => @handleRowSelect row, e
							onDoubleClick: () => @props.onRowDoubleClick(row)
							onMouseOver: =>
								if !@state.hoverLocked
									@setState hoveredRow: rowIndex

						Fragment {key: row.id},

							#marker cell
							if @props.renderMarker?
								Cell {
									key: 'marker' + rowIndex
									className: styles.markerColumn
									...cellProps
								},
									@props.renderMarker {row: row}

							#checkbox cell
							Cell {
								key: 'checkbox' + rowIndex
								className: cnames styles.checkboxColumn, if @props.renderMarker? then styles.secondColumn
								spacing: 'compact'
								...cellProps
							},
								React.createElement CheckboxCell, {
									row: row
									onChange: (e) => @handleCheckboxSelect row, e
									isChecked: _.includes @props.selectedRows, row.id
									isHovered: @state.hoveredRow is rowIndex
								}

							#content cells
							_.map @props.columns, (column) =>
								Cell {
									key: column.key + rowIndex
									...cellProps
								},
									@props.renderCell {key: column.key + rowIndex, column: column, row: row}

							#action cell
							if @props.renderAction?
								Cell {
									key: 'action' + rowIndex
									className: styles.actionColumn
									..._.omit cellProps, 'onClick'
								},
									if ((_.size @props.selectedRows) > 1) and (_.includes @props.selectedRows, row.id)
										div {}
									else
										@props.renderAction
											row: row
											isHovered: @state.hoveredRow is rowIndex
											onMenuOpenChange: (isOpen) =>
												@setState hoverLocked: isOpen
