###
Platform abstract grid (tiles) layout with pagination
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
# Renderable
import { div, p, ul, li, small, h1 } from 'react-dom-factories'

Fragment = React.createFactory React.Fragment

import _Pagination from '@atlaskit/pagination'
Pagination = React.createFactory _Pagination

# Styles
import styles from './index.styl'


DefaultCardContainer = (props) ->
	div
		key: props.key
		className: [props.className, styles.cardWrapper].join ' '
	, props.content

export default class LayoutGrid extends React.Component
	@propTypes =
		rowsPerPage: PropTypes.number
		defaultPage: PropTypes.number
		cardsPerRow: PropTypes.number
		cardContainer: PropTypes.element
		items: PropTypes.arrayOf PropTypes.shape
			key: PropTypes.oneOfType [PropTypes.string, PropTypes.number]
			content: PropTypes.element

	@defaultProps =
		rowsPerPage: 10
		cardsPerRow: 3
		defaultPage: 1

	constructor: (props) ->
		super props
		@state =
			cardsPage: props.defaultPage

	render: ->
		cardsPerPage = @props.cardsPerRow * @props.rowsPerPage
		Fragment {},
			div {
				className: styles.cards
				style: gridTemplateColumns: "repeat(#{@props.cardsPerRow}, auto)"
				..._.pickBy @props, (val, key) -> /^data-/.test key
			},
				do =>
					Container = if @props.cardContainer? then @props.cardContainer? else DefaultCardContainer
					_.map _.chunk(@props.items, cardsPerPage)[@state.cardsPage - 1], (item) ->
						Container {...item}
			Pagination
				total: _.ceil (@props.items.length / cardsPerPage)
				value: @state.cardsPage
				onChange: (number) =>
					@setState cardsPage: number
