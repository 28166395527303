###
Inline edit for editing inline text
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import cnames from 'classnames'

# Renderable
import { div } from 'react-dom-factories'
Fragment = React.createFactory React.Fragment

import _TextInput from '../components/TextInput'
TextInput = React.createFactory _TextInput

# Atlassian
import _Tooltip from '@atlaskit/tooltip'
Tooltip = React.createFactory _Tooltip

# Styles
import styles from './index.styl'

export default class InlineEdit extends React.Component
	@propTypes:
		className: PropTypes.string
		text: PropTypes.string
		placeholderText: PropTypes.string
		placeholderTooltipContent: PropTypes.string
		onChange: PropTypes.func
		trim: PropTypes.bool

	@defaultProps:
		placeholderText: 'Untitled'
		placeholderTooltipContent: 'Change name'
		trim: true

	constructor: (props) ->
		super props
		@state =
			isBeingEdited: false
			tempTextValue:
				if @props.searchMode?
					''
				else if !_.isEmpty @props.text
					@props.text
				else
					@props.placeholderText

	componentDidMount: ->
		document.addEventListener('keydown', @handleEscPress, false)

	componentWillUnmount: ->
		document.removeEventListener('keydown', @handleEscPress, false)

	@getDerivedStateFromProps: (props, state) =>
		if state.tempTextValue isnt props.text and state.isBeingEdited is false
			tempTextValue:
				if props.searchMode?
					''
				else if !_.isEmpty props.text
					props.text
				else
					props.placeholderText
		else
			null

	handleEnterPress: (e) =>
		if @state.isBeingEdited is true and e.key is 'Enter'
			@handleChange e.target.value
			@setState isBeingEdited: false

	handleEscPress: (e) =>
		if @state.isBeingEdited is true and e.key is 'Escape'
			@setState isBeingEdited: false

	handleChange: (input) =>
		if @props.trim
			input = _.trim input
		if _.isEmpty input
			@setState
				isBeingEdited: false
				tempTextValue: @props.placeholderText
		else
			if input isnt @props.placeholderText
				@props.onChange input
			@setState
				isBeingEdited: false

	handlePlaceholderTextChange: (value) =>
		if @props.onContinousChange?
			@props.onContinousChange value
		@setState
			tempTextValue: value

	render: ->
		div {className: cnames styles.base, @props.className },
			# edit mode
			if @state.isBeingEdited
				TextInput
					autoFocus: true
					value: @state.tempTextValue
					onPlaceholderTextChange: @handlePlaceholderTextChange
					onBlur: @handleChange
					onEnterPress: @handleEnterPress
					inputStyle: { border: '1px solid #8993a4', maxWidth: '700px' }

			# display mode
			else
				div
					onDoubleClick: =>
						console.log 'dupa'
						@setState isBeingEdited: true
					# onClick: => @setState isBeingEdited: true
				,
					if _.isEmpty @props.text
						Tooltip {position: 'mouse', content: @props.placeholderTooltipContent},
							div {className: cnames styles.placeholder}, @props.placeholderText
					else
						div {className: cnames styles.titleWrapper},
							Tooltip {position: 'mouse', content: @props.text},
								div {className: cnames styles.title}, @props.text



