import _ from 'lodash'

export default ->

	start: (obj, args, context, info) ->
		obj.landAcquisition
	end: (obj, args, context, info) ->
		obj.closing
	closingDate: (obj, args, context, info) ->
		obj.closing
