import gql from 'graphql-tag'
import {
	Project
	Observation
} from './query.graphql'

export RootQuery = gql """
	query ObservarionListQuery {
		ehsObservations {
				#{Observation}
			}
		bimIssueTypes {
			id
			name
			category
		}
		bimCompanies {
			id
			name
		}
		bimLocations {
			id
			path
			description
			level
			buildings
		}
	}
"""

export LinkBIMIssue = gql '''
	mutation createIssue(
			$observationID: ID!,
			$typeID: ID!,
			$description: String!,
			$companyID: ID,
			$dueDate: Int,
			$locationID: ID,
			$locationNotes: String,
			$attachments: [IssueAttachmentInput!]
		){
			createIssue(
				observationID: $observationID
				typeID: $typeID
				description: $description
				companyID: $companyID
				dueDate: $dueDate
				locationID: $locationID
				locationNotes: $locationNotes
				attachments: $attachments
			) {
				id
			}
	}
'''
