
###
S360 global level search with drawer panel
###

# Libs
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
# Renderable
import { h2 } from 'react-dom-factories'

# Styles
import styles from './index.styl'


export default class SearchPanel extends React.Component
	@propTypes:
		close: PropTypes.func
		isOpen: PropTypes.bool
		headerComponent: PropTypes.func
	@defaultProps:
		headerComponent: h2
	render: ->
		@props.headerComponent {className: styles.header}, 'Search'
