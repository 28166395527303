import _ from 'lodash'

export default (collections) ->
	{opportunities} = collections
	gla: (obj, args, context, info) ->
		_.sumBy obj.floors, (floor) ->
			_.sumBy floor.sections, ({volume, type, addonFactor}) ->
				if type is 'Garage'
					0
				else
					factor = addonFactor || 0
					factor += 1
					if volume.measured? then volume.measured * factor else volume.planned * factor
	totalGla: (obj, args, context, info) ->
		_.sumBy obj.floors, (floor) ->
			_.sumBy floor.sections, ({volume, type, addonFactor}) ->
				if type is 'Garage'
					factor = 25
					if volume.measured? then volume.measured * factor else volume.planned * factor
				else
					factor = addonFactor || 0
					factor += 1
					if volume.measured? then volume.measured * factor else volume.planned * factor
	nla: (obj, args, context, info) ->
		_.sumBy obj.floors, (floor) ->
			_.sumBy floor.sections, ({volume, type}) ->
				if type is 'Garage'
					0
				else
					if volume.measured? then volume.measured else volume.planned
