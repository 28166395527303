import _ from 'lodash'
import React from 'react'

context = React.createContext()
SideNavFiltersProviderFactory = React.createFactory context.Provider
export SideNavFiltersConsumer = React.createFactory context.Consumer

export default class SideNavFiltersProvider extends React.Component
	constructor: (props) ->
		super props
		@state =
			foldersListName: ''
			foldersList: []
			activeFolder: {}
			activePaths: null

	handleFoldersList: (listName, items) =>
		if (_.isEmpty @state.foldersList) or (listName isnt @state.foldersListName)
			@setState
				foldersList: items
				foldersListName: listName

	handleActiveFolder: (item) =>
		if (_.isEmpty @state.activeFolder) or (@state.activeFolder isnt item)
			@setState activeFolder: item

	handleActivePaths: (paths) =>
		if (_.isEmpty @state.activePaths) or (@state.activePaths isnt paths)
			@setState activePaths: paths

	render: ->
		SideNavFiltersProviderFactory
			value:
				foldersList: @state.foldersList
				activeFolder: @state.activeFolder
				activePaths: @state.activePaths
				addFoldersList: @handleFoldersList
				setActiveFolder: @handleActiveFolder
				setActivePaths: @handleActivePaths
		,
			@props.children
