import React from 'react'
import _ from 'lodash'

export default (wrapper, props..., children) ->
	if React.isValidElement wrapper
		React.cloneElement wrapper, _.first(props), children
	else if _.isFunction wrapper
		React.createElement wrapper, _.first(props), children
	else
		children
