
import React from 'react'
import { createContextualCan } from '@casl/react'

context = React.createContext {}

export default context
export UserInfoProvider = React.createFactory context.Provider
export UserInfoConsumer = React.createFactory context.Consumer

export Can = React.createFactory createContextualCan ({children}) ->
	UserInfoConsumer {}, ({ability}) ->
		children ability
