"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _core = _interopRequireDefault(require("lodash/core"));

var _legalDocument = _interopRequireDefault(require("./legalDocument"));

var _project = _interopRequireDefault(require("./project"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/*
 ACL levels to scopes mapping
*/
var _default = {
  LegalDocument: _legalDocument.default,
  Project: _project.default
};
exports.default = _default;