import _ from 'lodash'

import {
	resolveMetaInfo
	calculateFullResults
	getProjectBaselineContext
	expandContext
	trimContext
} from 'data/helpers'



export default (collections) ->
	{
		scenarios
		projects
		users
		tenantMixes
		costsVariants
		divestments
		contexts
	} = collections
	{
		...resolveMetaInfo collections
		context: (obj, args, context, info) ->
			ctx = contexts.findOne id: obj.contextID
			ctx
	# 	closingDate: (obj, args, context, info) ->
	# 		if obj.divestmentID?
	# 			divestment = divestments.findOne id: obj.divestmentID
	# 			if divestment?._closingDate?
	# 				return divestment._closingDate
	# 		project = projects.findOne id: obj.projectID
	# 		{timeline} = contexts.findOne id: project.contextID
	# 		timeline.closing
	# 	yield: (obj, args, context, info) ->
	# 		if obj.divestmentID?
	# 			divestment = divestments.findOne id: obj.divestmentID
	# 			if divestment?.yield?
	# 				return divestment.yield
	# 		# TODO!: Avg. yield from rent roll
	# 		# TODO!: Avg. yield from baseline space definitions

	# 		6.5
	# 	result: (obj, args, context, info) ->
	# 		project = projects.findOne id: obj.projectID
	# 		context = getProjectBaselineContext {collections, projectID: obj.projectID}


	# 		tenantMix = context.tenantMix
	# 		if obj.tenantMixID?
	# 			tenantMix = tenantMixes.findOne id: obj.tenantMixID

	# 		costsVariant = context.costsVariant
	# 		if obj.costsVariantID?
	# 			costsVariant = costsVariants.findOne id: obj.costsVariantID

	# 		divestment = context.divestment
	# 		if obj.divestmentID?
	# 			divestment = divestments.findOne id: obj.divestmentID


	# 		context = expandContext {collections, context}
	# 		calculateFullResults {
	# 			...trimContext context
	# 			collections
	# 		}
	# 		# calculateFullResults {
	# 		# 	collections
	# 		# 	projectID: obj.projectID
	# 		# 	tenantMix
	# 		# 	costsVariant
	# 		# 	divestment
	# 		# }
	# 	tenantMix: (obj, args, context, info) ->
	# 		if !obj.tenantMixID?
	# 			return _.first tenantMixes.chain().find({projectID: obj.projectID, _isBaseline: true}).simplesort('metaInfo.modifiedAt', true).data()
	# 		tenantMixes.findOne id: obj.tenantMixID
	# 	costs: (obj, args, context, info) ->
	# 		if !obj.costsVariantID?
	# 			return _.first costsVariants.chain().find({projectID: obj.projectID, _isBaseline: true}).simplesort('metaInfo.modifiedAt', true).data()
	# 		variant = costsVariants.findOne id: obj.costsVariantID
	# 		variant
	# 	divestment: (obj, args, context, info) ->
	# 		if !obj.divestmentID?
	# 			return _.first divestments.chain().find({projectID: obj.projectID, _isBaseline: true}).simplesort('metaInfo.modifiedAt', true).data()
	# 		variant = divestments.findOne id: obj.divestmentID
	# 		variant

	}
