import _ from 'lodash'
import moment from 'moment-mini'
import {
	alignOffer
} from 'data/helpers'

export default (collections) ->
	{
		projects
		offers
		users
		opportunities
		opportunitiesStages
		companies
		documents
		documentsTopics
		contexts
	} = collections

	users: (obj, args, context, info) ->
		users.find()
	accounts: (obj, args, context, info) ->
		_.map companies.find(categories: $contains: 'Client'), (company) -> {
			...company
			__typename: 'Account'
		}
	companies: (obj, args, context, info) ->
		_.map companies.find(categories: $contains: 'Internal'), (company) -> {
			...company
			__typename: 'Organization'
		}
	offices: (obj, args, context, info) ->
		_.map companies.find(categories: $contains: 'Office'), (company) -> {
			...company
			__typename: 'Organization'
		}
	projects: (obj, args, context, info) ->
		projects.find()
	project: (obj, args, context, info) ->
		projects.findOne slug: args.slug
	opportunity: (obj, args, context, info) ->
		if args?.id?
			opportunities.findOne id: args.id
		else
			null
	opportunitiesStages: ->
		opportunitiesStages.find()
	offer: (obj, args, context, info) ->
		# TODO: Test in usage
		offer = offers.findOne id: args.id
		if !offer?
			return null
		project = projects.findOne id: offer.projectID
		{building} = contexts.findOne id: project.contextID
		alignOffer offer, building
	opportunities: (obj, args, context, info) ->
		opportunities.find()
	offers: (obj, args, context, info) ->
		offers.find()
	document: (obj, args, context, info) ->
		if args?.id?
			documents.findOne id: args.id
		else
			null
	documents: (obj, args, context, info) ->
		{filters} = args
		query = {}
		if filters?
			conditions = []
			if filters.companies? and !_.isEmpty filters.companies then conditions.push companyID: $in: filters.companies
			if filters.contractors? and !_.isEmpty filters.contractors then conditions.push contractorID: $in: filters.contractors

			if filters.types? and !_.isEmpty filters.types then conditions.push type: $in: filters.types
			if filters.topicIDs? and !_.isEmpty filters.topicIDs then conditions.push topicID: $in: filters.topicIDs

			if filters.assignees? and !_.isEmpty filters.assignees then conditions.push asigneeID: $in: filters.assignees
			if filters.statuses? and !_.isEmpty filters.statuses then conditions.push status: $in: filters.statuses

			if filters.locations? and !_.isEmpty filters.locations then conditions.push location: $in: filters.locations
			if filters.vdrStatuses? and !_.isEmpty filters.vdrStatuses then conditions.push vdrStatus: $in: filters.vdrStatuses

			if conditions.length is 1
				query = _.first conditions
			else
				query = $and: conditions

		pagination = _.defaultsDeep {limit: 100, skip: 0}, args.pagination

		documents.chain()
			.find query
			.limit pagination.limit
			.offset pagination.skip
			.data()

	documentsTopics: (obj, args, context, info) ->
		# console.log documentsTopics.find()
		documentsTopics.find()
	documentsTopic: (obj, args, context, info) ->
		if args?.id?
			documentsTopics.findOne id: args.id
		else
			null
