import _ from 'lodash'
import moment from 'moment-mini'

export default (collections, {decreesService}) ->
	{costsLines, costsItems, codes, decrees, projects, invoiceItems} = collections

	code: (obj, args, context, info) ->
		codes.findOne id: obj.codeID
	items: (obj, args, context, info) ->
		console.log 'Local Items'
		_.map costsItems.find(lineID: obj.id, variantID: obj.variantID), (item) ->
			forecasted = item.value
			splitted = _.sum(item.cashflow)
			unsplitted = forecasted - splitted
			# TODO!: invoiceItems implmenetation
			# invoiced = _.sumBy invoiceItems.find(codeID: line.codeID, itemID: item.id), 'value'
			invoiced = 0
			overrun = _.clamp invoiced - forecasted, 0, Infinity
			remaining = _.clamp forecasted - invoiced, 0, Infinity
			{
				...item
				forecasted: _.round forecasted, 2
				splitted: _.round splitted, 2
				unsplitted: _.round unsplitted, 2
				invoiced: _.round invoiced, 2
				overrun: _.round overrun, 2
				remaining: _.round remaining, 2
				split: _.map item.cashflow, (month) ->
					forecasted = month
					# TODO!: invoiceItems implmenetation
					# invoiced = _.sumBy invoiceItems.find(codeID: line.codeID, itemID: item.id), 'value'
					invoiced = 0
					overrun = _.clamp invoiced - forecasted, 0, Infinity
					remaining = _.clamp forecasted - invoiced, 0, Infinity
					{
						__typename: 'MonthlySplit'
						forecasted: _.round forecasted, 2
						invoiced: _.round invoiced, 2
						overrun: _.round overrun, 2
						remaining: _.round remaining, 2
					}
			}
	split: (obj, args, context, info) ->
		console.log 'Local split'
		project = projects.findOne id: obj.projectID
		{timeline} = contexts.findOne id: project.contextID
		startDate = moment.unix timeline.landAcquisition
		decreesMatrix = decreesService obj.projectID
		_.map obj.cashflow, (value, index) ->
			currentDate = startDate.clone().add index, 'months'
			# currentDecrees = decrees.find
			# 	codeID: obj.codeID
			# 	projectID: obj.projectID
			# 	date:
			# 		$between: [
			# 			currentDate.startOf('month').unix()
			# 			currentDate.endOf('month').unix()
			# 		]
			forecasted = if _.size(obj.__items) is 0 then value else _.sumBy obj.__items, (item) -> item.cashflow[index]
			# invoiced = _.sumBy(currentDecrees, 'value')
			invoiced = decreesMatrix[obj.codeID][index]
			{
				__typename: 'MonthlySplit'
				date: currentDate.startOf('month').unix()
				forecasted: _.round forecasted, 2
				invoiced: _.round invoiced, 2
				overrun: _.round _.clamp(invoiced - forecasted, 0, Infinity), 2
				remaining: _.round _.clamp(forecasted - invoiced, 0, Infinity), 2
			}
